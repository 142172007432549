@import 'src/styles/variables';

.increaser {
  & > div > button {
    height: 40px;
    width: 40px;
    padding-top: 0;
    background-color: $grey-med-dark;
    border: none;
    font-size: 25px;
    font-weight: 300;
    &:disabled {
      cursor: default;
      background-color: $mystic; } } }

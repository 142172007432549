@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 220px) !important; }

.resetButton {
  background-color: #242E42 !important;
  opacity: 0.6 !important;
  border-radius: 11px;
  min-width: 100px;
  font-weight: 600; }
.revokeButton {
  min-width: 50px;
  border-radius: 15px;
  padding: 12px; }
.searchButton {
  border-radius: 11px;
  min-width: 100px;
  margin-right: 10px;
  font-weight: 600; }
.form {
  width: 100%;
  padding: 15px 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.dangerBg {
  background: #dc3545; }
.disabledBg {
  background: rgba(#242E42, 0.6); }
.activeFilterTab {
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1); }
.activeFilterIcon {
  & path {
    fill: rgba($general-text-color, 0.5) !important; } }
.collapseSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }
.navButton {
  border-radius: 8px !important;
  background-color: $primary-btn-color !important; }
.revokedText {
  width: 64px;
  text-align: center; }
.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }
.actions {
  & > div {
    & > span {
      transition: display 0.35s; } } }
.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); }
  &-primary {
    background: $primary-color; } }

.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }

.addBtn {
  margin-top: -50px !important;
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  min-width: fit-content;
  border-radius: 8px;
  margin-top: -57px !important; }

.activeTab {
  background: rgba($general-text-color, 0.1) !important;
  color: rgba($general-text-color, 0.6) !important; }

@media  ( max-width: 800px ) {
  .addBtn {
    margin-right: 20px !important;
    width: 45px; }
  .btnText {
    display: none !important; }
  .addSvg {
    margin-left: -6px !important; } }

.btnText {
  width: 160px;
  display: inline-block;
  font-weight: 500;
  color: $default-button-txt; }


.tabs {
  margin-bottom: 30px;
  width: fit-content !important;
  & li {
    flex-grow: 2 !important;
    width: fit-content;
    opacity: 0.65; } }

@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 16px !important; }
.form {
  padding: 10px 30px 20px; }
.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 150px;
  color: $default-button-txt;
  background: $primary-btn-color;
  height: 40px;
  font-size: 14px;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg;
    opacity: 0.6; } }

.button {
  color: $default-button-txt;
  border-radius: 8px;
  min-width: 100px;
  background: $primary-btn-color;
  &-bg-secondary {
    background: $cancel-button-bg !important;
    opacity: 0.6; } }
.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 100%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  &:focus {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; }
  &:hover {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; } }
.formLabel {
  & > div {
    margin-left: 10px;
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }
.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 102% !important;
  text-align: left !important;
  margin-left: -6px !important; }
.formInput1 {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel1 {
  & > div {
    margin-left: -10px !important;
    padding-right: 42px !important;
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }
.setTrashIcon {
  position: absolute;
  cursor: pointer;
  margin-top: -1.3rem;
  path {
    stroke: $disabled-bg-color !important; }
  &:hover {
    opacity: 1;
    path {
      stroke: rgba($red, 0.7) !important; } } }
.addButtonCustom {
  font-size: 14px;
  border-radius: 8px;
  margin-top: -1.3rem !important;
  margin-left: 1.8rem !important; }

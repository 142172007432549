@import 'src/styles/variables';

.borderPrimary {
  padding: 30px 25px 10px 25px !important;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 8px !important;
  margin: 7px !important; }

.cardTitle {
  margin-bottom: 22px !important; }
.title {
  font-size: 14px;
  font-weight: 300; }

@media (min-width: 1600px) {
  .borderPrimary {
    padding: 40px 30px 0px 30px !important; }
  .cardTitle {
    margin-bottom: 42px !important; }
  .currentValueRow {
    margin-bottom: 12px; }
  .moreRow {
    margin-top: 20px; } }

.cardTemplate {
  height: 250px;
  object-fit: contain;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 8px !important;
  margin: 7px !important;
  padding: 30px 25px 10px 18px !important;
  box-shadow: 0px 1px 2px 0px #606C801A; }

.btnCard {
  width: 45px !important;
  height: 45px !important;
  min-width: 35px;
  font-size: 1em;
  border-radius: 8px; }

.dataContainer {
  padding-top: 30px !important;
  font-size: 14px;
  font-weight: 500; }

.userContainer {
  color: #B6B6B6 !important;
  margin-top: 10px !important;
  font-weight: 550 !important; }

.userContainer1 {
  color: #B6B6B6 !important;
  margin-top: 10px !important; }

.numberContainer {
  color: #242E42 !important;
  font-weight: 550 !important;
  font-size: 24px !important;
  padding-top: 27px !important; }

.activeContainer {
  color: #B6B6B6 !important;
  padding-top: 10px !important;
  width: 11rem; }

.percentageContainer {
  color: #3A9CED !important; }

.data1Container {
  padding-top: 25px !important;
  font-size: 13px !important; }


.icon {
  width: 50px !important;
  height: 50px !important; }
.secondaryText {
  color: #242E42D9 !important;
  font-size: 14px !important;
  font-weight: 550 !important;
  margin-right: 0.4rem !important; }

.dateDropdown {
  border: 1px solid rgba($snuff, 0.27) !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  & > button:hover {
    background-color: $primary-color;
    & > span {
      color: white; } }
  & > div {
    border: 1px solid rgba($snuff, 0.27) !important;
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 30px !important;
    & > button {
      padding: 12px 12px;
      & > span {
        display: block !important;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
    & > button:hover {
      background-color: $primary-color;
      & > span {
        color: white;
        opacity: 1; } } } }

.dateDropdown1 {
  height: 30px !important; }

@import 'src/styles/variables';

.title {
  color: $page-header-txt !important;
  font-weight: 400 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem !important;
  margin-left: 9px;
  text-align: left; }


.tabs {
  margin-bottom: 20px;
  & li {
    flex-grow: 1;
    width: 10rem !important;
    text-align: center !important; } }

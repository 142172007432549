@import 'src/styles/variables';

.LocationIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  stroke: $primary-color; }

.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

@import 'src/styles/variables';

.logoLink {
  text-decoration: none !important; }
.title {
  color: $navbar-txt;
  font-size: 17px;
  line-height: 1.4; }
.whiteText {
  color: $navbar-txt !important;
  line-height: 1.4;
  font-size: 15px !important; }

.dFlex {
  display: flex; }
.dFlexColumn {
  flex-direction: column; }
.username {
  font-size: 13px;
  color: $navbar-txt; }
.currentTime {
  font-size: 12px;
  margin-top: 6px;
  color: $navbar-txt;
  opacity: 0.6;
  min-width: 175px;
  font-weight: 300;
  text-transform: uppercase; }
.arrowDownIcon path {
  fill: $default-button-txt;
  stroke: $default-button-txt; }
.logOutBtns {
  border-radius: 8px;
  min-width: 100px;
  height: 40px;
  background-color: $primary-btn-color !important;
  font-size: 14px;
  font-weight: 400;
  margin-left: 1rem;
  margin-right: 1rem;
  border: none !important;
  &:hover {
    background-color: $primary-btn-color !important; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45) !important;
    &:hover {
      background-color: #000000 !important; }
    &:active {
      transform: scale(0.95); } } }

.navbar {
  background: $navbar-bg !important;
  color: $navbar-txt !important; }

@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 16px !important; }

.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 140px;
  color: $default-button-txt;
  background: $primary-btn-color;
  margin-right: 40px;
  height: 40px;
  font-size: 14px;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg;
    opacity: 0.6; } }


.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 83%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.validCodeFormInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid red !important; }

.invalidCodeFormInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid red !important; }


.formLabel {
  & > div {
    margin-left: 10px;
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }

.body {
  padding: 10px 40px 20px;
  font-size: 13px;
  color: rgba($general-text-color, 0.7); }
.boldText {
  color: 'grey-dark';
  font-weight: bold; }
.numberLabel {
  width: 20px;
  text-align: end; }
.greyButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: rgba($general-text-color, 0.4) !important; }
.orangeButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $orange !important; }
.primaryButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $primary-btn-color !important; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }

.tabs {
  margin-bottom: 30px;
  width: 83% !important;

  & li {
    flex-grow: 2 !important;
    width: 50%;
    opacity: unset; } }


@import 'src/styles/variables';

.contentContainer {
  font-family: 'Roboto', sans-serif;
  top: 15px;
  position: relative;
  margin-left: 85px;
  flex: 1;
  height: 100%;
  overflow: auto;
  font-family: 'Roboto', sans-serif; }

.contentContainer1 {
  padding: 10px !important;
  top: 0 !important;
  background-color: #f2f3f6 !important; }

@media (min-width: 1199px) {
  .contentContainer {
    margin: 0 2.5%;
    margin-left: 270px; }

  .contentContainer1 {
    margin: 0 0 !important;
    margin-left: 250px !important;
    top: 0 !important;
    background-color: #f2f3f6 !important; } }

@import 'src/styles/variables';

.tabs {
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  list-style: none;
  background: #FFFFFF !important;
  border: 1px solid #EBEEF2 !important;
  border-radius: 8px;
  li:last-child {
    border-right: unset !important; } }
.tab {
  width: auto !important;
  height: 100%;
  padding: 10px 16px;
  opacity: 0.65; }
.activeTab {
  background: #E9EBF0D9 !important;
  border: 1px solid #E9EBF0 !important; }

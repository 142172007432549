@import 'src/styles/variables';

.title {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  text-align: center; }
.form {
  padding: 0 20px 20px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.input1 {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel1 {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: -3rem;
  margin-top: 0.7rem; }
.button {
  border-radius: 8px;
  min-width: 100px;
  margin-top: 30px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }
.pending-color {
  color: rgba($general-text-color, 0.45); }
.cancelled-color {
  color: $red; }
.approved-color {
  color: $green; }
.under_review-color {
  color: $orange; }
.rejected-color {
  color: $red; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-left: 20px; }
.imgThumb {
  border-radius: 8px !important;
  width: 230px;
  height: 140px;
  object-fit: contain;
  background: #dddddd;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.image {
  pointer-events: none;
  border: 1px solid;
  background: #fff;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.document {
  border-radius: 8px !important;
  width: 585px;
  height: 55px;
  object-fit: contain;
  background: #dddddd;
  margin-bottom: 20px;
  margin-left: 15px !important; }
.documentIcon {
  padding-top: 7px;
  padding-left: 7px !important; }
.documentContent {
  padding-top: 16px;
  font-size: 13px !important; }
.documentButton1 {
  padding-top: 10px;
  padding-left: 305px !important;
  background-color: transparent !important;
  margin-top: 5px !important; }
.documentButton2 {
  padding-top: 10px;
  padding-left: 30px !important;
  background-color: transparent !important;
  margin-top: 5px !important; }
.button_container {
  display: flex; }
.grab {
  cursor: grab !important; }
.tag-container {
    box-shadow: none !important;
    border: 1px solid rgba(216, 219, 234, 0.95) !important;
    border-radius: 8px !important;
    display: flex;
    flex-wrap: wrap;
    padding: 0.3rem;
    color: rgba(36, 46, 66, 0.45);
    font-family: 'Roboto', sans-serif;

    .tag {
      display: flex;
      align-items: center;
      padding: 5px;
      height: 25px;
      margin: 2px 5px 2px 0px;
      color: white;
      background: rgba(36, 46, 66, 0.45);
      font-size: 12px;
      border-radius: 10px;
      span {
        margin-left: 5px;
        cursor: pointer;
        & path {
          stroke: white; } } }

    input {
      border: none;
      flex: 1;
      outline: none;
      padding: 5;
      font-size: 14px;
      color: #242E42 !important;
      font-family: 'Roboto', sans-serif; }

    .tag1 {
      display: flex;
      align-items: center;
      padding: 5px;
      border: 1px solid $red !important;
      height: 25px;
      margin: 2px 5px 2px 0px;
      color: white;
      background: $red;
      font-size: 12px;
      border-radius: 10px;
      span {
        margin-left: 5px;
        cursor: pointer;
        & path {
          stroke: re; } } } }

.invalidTagContainer {
  border: 1px solid red !important; }

.validTagContainer {
  border: 1px solid #1fbd14 !important; }

.removeicon {
  height: 10px !important;
  width: 15px !important;
  margin-top: -0.1rem !important; }

.error {
  color: red;
  margin: 0;
  font-size: 12px;
  margin-right: 3rem; }

.title {
  color: $general-text-color !important;
  font-size: 16px !important;
  margin-right: 2rem !important; }


.subText {
  font-size: 14px !important;
  margin-right: 2.5rem !important;
  margin-top: 0.7rem !important; }

.subText1 {
  font-size: 12px !important;
  text-align: left !important;
  font-weight: 500 !important;
  margin-top: 1rem !important; }


.note {
  font-size: 12px !important;
  text-align: left !important;
  font-weight: 500 !important;
  margin-top: 0.3rem !important;
  margin-left: 0.3rem !important;
  color: rgba($general-text-color, 0.7); }


.button {
  border-radius: 8px;
  min-width: 140px;
  margin-right: 3.5rem !important;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  &:hover {
      background-color: #2ee421; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45);
    &:hover {
      background-color: #000000; }
    &:active {
      transform: scale(0.95); } } }


.remove {
  color: red !important; }

.line {
  width: 90%;
  margin-left: 0rem; }

.numInput {
  height: 35px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: center !important;
  width: 45% !important;
  max-width: 50%;
  font-size: 14px;
  margin-right: 13rem !important; }



.postBtn {
  background-color: #2ee421;
  font-weight: bold;
  margin-top: -7%;
  min-width: 110px;
  font-size: 14px;
  margin-left: 60% !important;
  height: 35px !important;
  border-radius: 8px !important;
  font-size: 14px;
  position: absolute !important;
  &:hover {
      background-color: #1388f5; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45);
    &:hover {
      background-color: #000000; }
    &:active {
      transform: scale(0.95); } } }


.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 85%; } } } }

.note2 {
  font-size: 12px !important;
  text-align: left !important;
  font-weight: 500;
  margin-top: 0.3rem !important;
  margin-left: 0.3rem !important;
  color: #000000; }

.note3 {
  font-size: 12px !important;
  text-align: left !important;
  font-weight: 500 !important;
  margin-top: 0.3rem !important;
  margin-left: 0.3rem !important;
  color: #000000; }
.icon {
  width: 30px;
  height: 30px;
  background: rgba($general-text-color, 0.45);
  transition: background 0.35s;
  border-radius: 50%;
  padding: 7px;
  margin-top: 5px;
  margin-left: -6px;
  cursor: pointer;
  &-danger {
    background: $red; }
  &-success {
    background: $green; } }
.cancelButton {
  background: rgba($general-text-color, 0.45);
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px; }
.errorMessage {
  height: 0px;
  margin-left: 7.4rem; }
.scheduleButton {
  background: $orange;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px;
  margin-left: 18px; }

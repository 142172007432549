@import 'src/styles/variables';

.borderPrimary {
  padding: 30px 25px 20px 25px !important;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  border-top: 2px solid $primary-color !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 0 !important; }

.cardTitle {
  margin-bottom: 22px !important; }
.title {
  font-size: 14px;
  font-weight: 300; }

.dateDropdown {
  border: 1px solid rgba($snuff, 0.27) !important;
  border-radius: 0 !important;
  & > button:hover {
    background-color: $primary-color;
    & > span {
      color: white; } } }

.currentValueRow {
  margin-bottom: 0px; }
.currentValue {
  font-size: 18px;
  word-break: break-word; }
.previousResultRow {
  margin-top: 6px;
  min-height: 16px; }
.moreRow {
  margin-top: 6px;
  & span {
    opacity: 1 !important; } }

.secondaryText {
  color: $general-text-color;
  opacity: .6;
  font-size: 13px;
  font-weight: 300; }
.less {
  color: $danger-color;
  font-size: 14px; }
.raise {
  color: green; }

.fontMd {
  font-size: $btn-font-size-md !important;
  font-size: 14px; }

@media (min-width: 1600px) {
  .borderPrimary {
    padding: 40px 30px 35px 30px !important; }
  .cardTitle {
    margin-bottom: 42px !important; }
  .currentValueRow {
    margin-bottom: 12px; }
  .moreRow {
    margin-top: 20px; } }

.container {
  margin: auto; }
.listDataCard {
  margin: -7.5px !important; }

.dataCardContainer {
  padding: 7.5px !important; }

.frameContainer {
  overflow: hidden !important;
  background-color: transparent !important; }

.cardTemplate {
  width: 180px;
  height: 200px;
  object-fit: contain;
  // margin-left: 100px
  border-radius: 10px !important;
  border-style: none;
  // width: 170% !important
  // height: 200px !important
  boxShadow: "10px 10px 17px -12px rgba(0,0,0,0.75)" !important;
  &:hover {
    border: 2px solid #3a9ced; }
  &:focus {
    border: 2px solid #3a9ced;
    outline-offset: 0px;
    outline: none; } }

.cardContainer {
  min-width: 100px !important;
  margin-left: 20px !important;
  margin-right: 20px !important; }

.btnCard {
  margin-left: -75px;
  opacity: 0.8 !important;
  margin-top: -70px;
  width: 45px !important;
  height: 45px !important;
  min-width: 35px;
  font-size: 1em;
  border-radius: 8px; }

.cnt {
  margin-top: 140px;
  position: relative; }

.cnt1 {
  margin-top: 10px; }

.cnt2 {
  margin-top: 25px;
  color: black;
  font-size: 30px; }

.cnt3 {
  margin-top: 85px;
  font-size: 15px;
  color: grey;
  margin-left: -198px;
  position: relative; }

.cnt4 {
  margin-top: 40px;
  margin-left: -100px;
  height: 10px;
  width: 60px;
  // margin-top: -42%
  position: relative; }

.cnt6 {
  margin-top: 40px;
  margin-left: 100px;
  height: 10px;
  width: 60px;
  // margin-top: -42%
  position: relative; }

.cnt5 {
  margin-top: 50%;
  margin-left: 100px;
  position: relative; }

.gap {
  width: 20px;
  background: none;
  display: inline-block; }

.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); } }

.cardbutton1 {
  // background: transparent
  width: 100%;
  margin-left: 6rem !important;
  margin-top: -3rem !important;
 }  // position: relative !important

.cardbutton2 {
  background: transparent;
  border: none !important;
  position: relative !important;
  font-size: 0;
  margin-left: -9px !important;
  margin-top: -3rem !important; }

.card-style {
  max-width: 30%; }


.imgThumb {
  border-radius: 8px !important;
  width: 230px;
  height: 140px;
  object-fit: contain;
  background: #dddddd;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.image {
  pointer-events: none;
  border: 1px solid;
  background: #fff;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }

@mixin buttonStyles($color) {
  background: $color; }

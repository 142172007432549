.google_maps_container {
  width: 100% !important;
  height: 56vh !important;
  & > div {
    position: relative !important; } }

@media  ( max-width: 480px ) {
  .google_maps_container {
    height: 100vh !important; } }

@media  ( max-width: 400px ) {
  .google_maps_container {
    height: 130vh !important; } }


@media  ( width: 1024px ) and (height: 600px) {
  .google_maps_container {
    height: 41vh !important; } }

#searchBox {
  top: 38px !important;
  left: 0px !important;
  margin-left: 30px;
  width: 55%; }

.infoWindow {
  font-size: 14px !important;
  background: #32f701 !important; }

.container {
  margin-top: 1rem !important;
  height: 370px;
  width: 100% !important;
  & > div {
    position: relative !important; } }

.nearby {
  font-size: 16px !important;
  margin-left: 1rem !important; }

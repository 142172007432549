@import 'src/styles/variables';

.container {
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  padding: 40px;
  align-items: center; }
.header {
  color: #0074E3;
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  margin-bottom: 25px; }
.contentContainer {
  width: 540px; }
.error {
  text-align: center;
  color: #242E42CC;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 25px; }
.detailHeader {
  color: #242E42CC; }

@import 'src/styles/variables';

.title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px !important;
  text-align: center; }
.title1 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px !important;
  text-align: center; }
.form {
  padding: 0 20px 20px; }
.content {
  font-size: 14px;
  line-height: 19.5px;
  text-align: center;
  padding-top: 14px;
  padding: 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.button {
  border-radius: 8px;
  min-width: 100px;
  font-size: 15px;
  &-bg-light {
    background-color: rgba($general-text-color, 0.45) !important; }
  &-bg-danger {
    background-color: $red !important; }
  &-bg-primary {
    background-color: $primary-color !important; } }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-left: 20px; }
.deleteButton {
  background-color: rgba($general-text-color, 0.45) !important;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }

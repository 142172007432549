@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important; }
.tableActive {
  margin-top: 15px; }
.dangerColor {
  color: $danger-color-deep; }

.form {
  width: 100%;
  padding: 0 10px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 10px;
  min-height: 50px; }

.btnFilter {
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }

.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important; }
.background {
  &-primary {
    background: $primary-color !important; }
  &-orange {
    background: $orange !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }

.btnSettings {
  background-color: $grey-dark;
  min-width: 43px;
  min-height: 40px;
  font-size: 16px;
  border-radius: 10px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.eyeIcon {
  margin-left: 8px;
  width: 27px;
  height: auto;
  fill: $primary-color; }

.badge {
  font-size: 12px !important;
  border-radius: 4px;
  height: 20px;
  max-width: 22px;
  min-width: 22px;
  &-primary-background {
    background: $primary-color !important; }
  &-orange-background {
    background: $orange !important; }
  & div:first-child {
    padding: 0 6px; } }
.icon {
  width: 20px;
  height: 20px;
  background: rgba($general-text-color, 0.45);
  transition: background 0.35s;
  border-radius: 50%;
  &-primary {
    background: $primary-color; }
  &-success {
    background: $green; }
  &-danger {
    background: $red; }
  &-alert {
    background: $orange; }
  &-eye {
    background: unset;
    & svg {
      transition: fill 0.35s;
      width: 27px;
      height: auto;
      fill: rgba($general-text-color, 0.45); } }
  &-eye-active {
    & svg {
      fill: $primary-color; } } }
.rejectEyeIcon {
  margin-left: 8px;
  width: 27px;
  height: auto;
  fill: $red; }

.background {
  &-primary {
    background: $primary-color !important; }
  &-orange {
    background: $orange !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }


.button {
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  min-width: fit-content;
  border-radius: 8px;
  margin-right: 1.4rem !important; }

@import 'src/styles/variables';

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  padding: 24px;
  & > div {
    display: flex;
    align-items: center; }
  & span {
    font-size: 16px;
    margin-left: 4px; } }

.hint {
  padding: 24px; }

.title {
  color: $page-header-txt !important; }

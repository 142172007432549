@import 'src/styles/variables';

.container {
  background: #fafbfd !important;
  margin: 0 !important;
  padding: 0 !important; }


.title {
  font-size: 25px !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  color: rgba($general-text-color, 0.7) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif; }

.headerCol {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
  padding: 0 !important;
  margin-left: 100px !important;
  text-align: right !important; }


@media only screen and (max-width: 1130px) {
  .headerCol {
    justify-content: flex-start !important;
    margin-left: -35px !important; }
  .title {
    font-size: 22px !important;
    min-width: 130% !important; } }

.applyBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  stroke: $default-button-txt-subscriber !important;
  width: fit-content !important; }
.toolBarBtns {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  stroke: $default-button-txt-subscriber !important;
  width: fit-content !important; }

@import 'src/styles/variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 40px;
  max-width: 40px;
  font-weight: 300;
  color: $default-button-txt;
  border-radius: 8px;
  border: none;
  outline: none !important;
  height: 40px;
  font-size: 16px;
  margin-left: 15px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.75s;
  &-primary {
    background-color: $primary-btn-color; }
  &-secondary {
    background-color: $grey-dark; }
  &-orange {
    background: $orange !important; }
  &-orange-light {
    background: $orange-light !important; }
  path {
    stroke: $default-button-txt !important; } }
.wrapper {
  position: relative;
  overflow: hidden; }
.activeButton {
  max-width: 350px !important; }
.icon {
  transition: all 0.75s;
  padding: 0 16px; }
.activeIcon {
  padding-right: 10px; }

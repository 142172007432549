@import 'src/styles/variables';

.btn {
  font-weight: inherit;
  font-size: 14px;
  min-width: 130px;
  letter-spacing: inherit;
  border-radius: 8px;
  height: 40px;
  padding: 20px;
  color: $default-button-txt;
  background: $save-button-bg;
  &-secondary {
    background: $cancel-button-bg;
    opacity: 0.45; }
  &-orange {
    background: $orange; }
  &-sm {
    min-width: fit-content; } }
.content {
  font-size: 14px;
  letter-spacing: inherit;
  line-height: inherit; }
.title {
  font-size: 18px !important; }
.formInput {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.badge {
  background: rgba($general-text-color, 0.1) !important;
  color: rgba($general-text-color, 0.6) !important;
  font-size: 13px !important;
  div {
    height: 24px; }
  div:last-child {
    width: 24px;
    background: none;
    transition: background 0.5s;
    border-radius: 50%;
    & path {
      transition: fill, 0.5s;
      fill: rgba($general-text-color, 0.6); } } }
.crossIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }

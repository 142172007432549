@import 'src/styles/variables';

.vehicleModal {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }
.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }
.modalTitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }
.backdropC {
  width: 100% !important;
  height: 100% !important; }

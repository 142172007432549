@import 'src/styles/variables';

.toggle {
  position: relative;
  width: 42px;
  height: 19px;
  border: 1px solid rgba($grey-dark, 0.34);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  & > div {
    display: flex;
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: calc(19px - 100%);
    transition: left 0.2s;
    & > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      bottom: 0;
      font-size: 10px;
      color: $grey-dark;
      user-select: none; }
    & > span:first-child {
      padding: 0 2px;
      left: 0;
      right: 50%;
      &::after {
        content: "";
        width: 15px;
        height: 15px;
        background-color: $danger-color;
        border-radius: 50%;
        transition: background-color 0.2s; } }
    & > span:nth-child(2) {
      left: 50%;
      right: 0; } }
  & + span {
    margin-left: 10px; }
  &.disabled {
    pointer-events: none; } }
.toggleOn > div {
  left: 0;
  & > span:first-child::after {
    background-color: $green; } }

@import 'src/styles/variables';

.title {
  font-size: 18px !important; }
.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & > div {
    margin-left: 10px; } }
.button {
  border-radius: 8px;
  min-width: 100px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }


.locationbtn {
  background-color: white !important;
  color: $primary-color !important;
  font-weight: bolder !important;
  margin-left: 72%;
  margin-bottom: -40px;
  text-decoration: underline; }


.fetchBtn {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  position: absolute;
  margin-left: 173% !important;
  margin-top: -18%; }


.fetchLotsBtn {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  position: absolute;
  margin-left: 73% !important;
  margin-top: -18% !important; }

.checkBox {
  margin-top: -6% !important;
  margin-bottom: 10px;
  margin-left: -10px; }

@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 220px) !important; }
.actions {
  & > div {
    & > span {
      transition: display 2.75s;
      padding: 6px 15px;
      border-radius: 15px; } } }
.background {
  &-primary {
    background: $primary-color !important; }
  &-danger {
    background: $red !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px; }
  &-success {
    background: $green; }
  &-danger {
    background: $red; } }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }

@import 'src/styles/variables';

.content {
  font-size: 14px;
  letter-spacing: inherit;
  line-height: inherit;
  text-align: left;
  padding-top: 15px; }
.form {
  padding: 25px 0; }
.formInput {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  color: $default-button-txt;
  background-color: $cancel-button-bg !important;
  opacity: 0.6; }
.submitButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  color: $default-button-txt;
  background-color: $danger-button-bg !important; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.title {
  font-size: 16px !important; }

@import 'src/styles/variables';

.heading {
  font-size: 30px;
  font-weight: 700;
  color: rgba($general-text-color, 0.7);
  text-align: center; }
.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: $general-text-color;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 75%; }
.toggle {
  width: 60px;
  height: 30px;
  background: $grey-dark;
  border-radius: 20px;
  & > div {
    left: calc(25px - 100%);
    & > span:first-child {
      &::after {
        width: 20px;
        height: 20px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.formLabel {
  display: flex;
  align-items: center;
  height: 40px;
  color: rgba($general-text-color, 0.95);
  margin: 0; }
.conditionTitle {
  color: rgba($general-text-color, 1);
  text-decoration: underline;
  text-decoration-thickness: 2px; }

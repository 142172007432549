@import 'src/styles/variables';

.title {
  font-size: 13px !important;
  text-align: left;
  font-weight: bold !important;
  margin-left: 50px !important; }
.form {
  padding: 10px 20px 20px 30px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }

.subject {
  text-align: left !important; }

.border {
  border: 1px solid #3A9CED !important; }

.content {
  font-size: 13px !important;
  text-align: left;
  margin-left: 50px !important; }

.icon {
  margin-left: 50px !important; }

.button {
  border-radius: 8px;
  width: 400px;
  min-width: 100px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.4) !important; } }
.btn {
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  min-width: unset;
  padding: 15px 30px !important;
  color: white !important;
  border-radius: 8px;
  &-cancel {
    background-color: rgba($general-text-color, 0.4) !important; } }

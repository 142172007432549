@import 'src/styles/variables';

.cancelButton {
  background: $cancel-button-bg;
  margin-right: 30px;
  color: $default-button-txt;
  font-weight: inherit;
  font-size: 16px;
  min-width: 100px;
  opacity: 0.6; }
.submitButton {
  background: $orange;
  color: $default-button-txt;
  font-weight: inherit;
  font-size: 16px;
  min-width: 100px;
  letter-spacing: inherit; }
.content {
  font-size: 14px;
  opacity: 0.7;
  letter-spacing: inherit;
  line-height: inherit; }
.title {
  font-size: 18px !important; }

@import 'src/styles/variables';

// index.module.sass
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  margin-top: -6rem !important; }

.leftSection {
  width: auto; // Adjust the width as needed
  // display: flex
  min-width: 33.9% !important;
  flex-direction: column;
  margin-right: 4rem !important;
  margin-top: 10rem !important; }

@media (max-width: 1366px) {
  .leftSection {
    width: 45% !important; } }





.paymentCard {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: border 0.3s;
  position: relative !important;

 }  // padding: 0.1rem !important


.selectedCard {
  border: 1px solid #3498db; }

.paymentOptionLabel {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer; }


.paymentOptionContent {
  margin-top: 10px; }

.inputField {
  height: 36px;
  width: 50%;
  padding: 10px;
  // margin-bottom: 10px
  margin: 1rem;
  box-sizing: border-box;
  border-radius: 3px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  font-size: 14px;
  &:focus {
    border: 1px solid #046dd6; } }




.rightSection {
  // width: 55% // Adjust the width as needed
  padding: 20px;
  border: 1px solid #030303;
  border-radius: 5px;
  margin-top: 10rem !important;
  height: 38rem !important;
  margin-left: 4rem !important;

  width: auto; // Adjust the width as needed
  // display: flex
  min-width: 55% !important;
  flex-direction: column;
  margin-right: 4rem !important;
  margin-top: 10rem !important; }





.headers {
  padding: 0.5rem !important;
  font-size: 17px !important;
  font-family: 'Roboto', sans-serif; }

.card {
  border: 1px solid #3498db !important;
  border-radius: 0px !important; }

.gPayBtn {
  // margin-left: 9.6rem !important
  margin-bottom: 1rem !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }


.svgVisa {
  position: relative !important;
  margin-left: 20rem !important;
  margin-top: -3.3rem !important; }

.svgMaster {
  position: relative !important;
  margin-left: 0.5rem !important;
  margin-top: -3.3rem !important; }


.svgMaestro {
  position: relative !important;
  margin-left: 0.5rem !important;
  margin-top: -3.3rem !important; }


.svgOthers {
  position: relative !important;
  margin-top: -3.3rem !important;
  margin-left: 33.8rem !important; }

@media (max-width: 1366px) {
  .svgOthers {
    margin-left: 20.5rem !important; } }

.notice {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px !important; }

.formLabel {
  font-size: 13px !important;
  color: $general-text-color;
  margin-left: 1rem !important;
  margin-bottom: -1rem !important; }

.formLabel2 {
  font-size: 13px !important;
  color: $general-text-color;
  margin-left: 1rem !important;
  margin-bottom: -0.3rem !important; }

.expiryMDropdown {
  height: 36px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  width: 35% !important;
  max-width: 35%;
  font-size: 14px;
  margin: 1rem !important; }

.expiryYDropdown {
  height: 36px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  width: 60% !important;
  max-width: 60%;
  font-size: 14px;
  margin-top: 0.5rem !important;
  margin-left: -5rem !important; }

@media (max-width: 1366px) {
  .expiryYDropdown {
    margin-left: -2.9rem !important; } }

.cvvC {
 }  // margin-left: -15rem !important
@media (max-width: 1366px) {
  .cvvC {
    margin-left: -2.2rem !important; } }



.customTooltip {
    position: relative !important;
    display: inline-block !important;
    margin-top: 0.5rem !important;
    margin-left: -6rem !important; }



.customTooltip .customTooltipText {
    visibility: hidden;
    width: 60px;
    background-color: gray;
    color: #fff;
    text-align: center;
    border-radius: 10px !important;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -35px;
    opacity: 0;
    transition: opacity 0.7s;
    font-size: 13px !important; }



.customTooltip .customTooltipText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -1px;
    border-width: 5px;
    border-style: solid;
    border-color: gray transparent transparent transparent; }


.customTooltip:hover .customTooltipText {
    visibility: visible;
    opacity: 1; }


.cardPreview {
  // flex: 1;
  // margin-right: 50px
  // margin-top: 65px
 }  // margin-left: -6px
.inputImg {
  height: 2.5rem !important;
  width: 2.5rem !important;
  margin-top: 0rem !important;
  margin-right: -20rem !important;
  margin-left: -4rem !important; }

.payButton {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber;
  align-items: center !important;
  margin: 1rem !important;
  min-width: 95%;
  padding: 0.7rem !important;
  position: relative !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: bold !important;
  outline: none !important;
  box-shadow: none !important;
  &:hover {
    background-color: #08a72f  !important; }
  &:active {
    background-color: #5cd179!important; } }

.paymentCards {
  display: flex !important;
  justify-content: left !important;
  align-items: center !important;
  margin-left: 7.5rem !important; }
@media (max-width: 1366px) {
  .paymentCards {
    margin-left: -6.5rem !important; } }

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center; }


.rightSectionHeader {
  text-align: center !important;
  font-size: 25px !important;
  font-family: 'Roboto', sans-serif;
  line-height: 1.1 !important;
  color: #000;
  margin-top: 1rem !important; }

.rightSectionSubHeader {
  text-align: center !important;
  font-size: 23px !important;
  font-family: 'Roboto', sans-serif;
  line-height: 1.1 !important;
  color: #000;
  margin-top: 2rem !important; }

.rightInfo {
  text-align: center !important;
  font-size: 20px !important;
  font-family: 'Roboto', sans-serif;
  line-height: 1.1 !important;
  color: #5f5a5a;
  margin-top: 2rem !important; }

.emailShow {
  // display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;
  max-height: 2.5rem !important;
  margin-top: 2rem !important;
  font-size: 15px !important;
  font-family: 'Roboto', sans-serif !important;
  margin-left: 23rem !important;
  // border: 0.15rem solid #918e8e
  border-radius: 8px !important;
  width: auto !important;
  max-width: 25% !important;
  &:focus {
    border: 1px solid #046dd6; } }

.email {
  text-align: center !important;
  margin: 1rem !important; }

.instructions {
  text-align: center !important;
  font-size: 17px !important;
  font-family: 'Roboto', sans-serif;
  line-height: 1.1 !important;
  color: #5f5a5a;
  font-weight: 400 !important;
  margin-top: 3rem !important; }

.emailField {
  height: 36px;
  width: 100% !important;
  // padding: 10px
  // margin-bottom: 10px
  box-sizing: border-box;
  border-radius: 3px !important;
  box-shadow: none !important;
  border: 0.15rem solid #918e8e;
  outline: none;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 13px;
  &:focus {
    border: 1px solid #046dd6; } }

.dateCsv {
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }

.paymentStatus {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500px;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  margin-top: 3rem !important;
  color: #040404; }

.goBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber;
  align-items: center !important;
  margin: 1rem !important;
  min-width: 10%;
  padding: 0.7rem !important;
  position: relative !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: bold !important;
  outline: none !important;
  box-shadow: none !important;
  &:hover {
      background-color: #007df1; }
  &:active {
    transform: scale(0.95); } }

@import 'src/styles/variables';

.calenderIcon {
  position: absolute;
  right: 14px;
  top: 10px;
  pointer-events: none;
  background: white;
  & path {
    fill: $primary-color; } }
.input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%; }

@import 'src/styles/variables';

.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $cancel-button-bg !important;
  opacity: 0.6; }
.submitButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $red !important; }
.content {
  text-align: left;
  font-size: 14px; }
.body {
  padding: 10px 40px 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }

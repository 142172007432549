@import 'src/styles/variables';

.card {
  width: 100%;
  height: auto !important;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 8px !important;
  margin-bottom: 0.5rem !important;
  &:hover {
    border: 1px solid $primary-color-subscriber; }
  &:focus {
    border: 1px solid $primary-color-subscriber;
    outline-offset: 0px;
    outline: none; } }

.cardfilter {
  width: 100%;
  height: auto !important;
  padding: 10px;
  border-radius: 8px !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important; }

.cardContainer {
  padding: 10px; }


.cardText {
  margin-left: 60px; }


.viewButton {
  width: 5rem;
  height: 5rem !important;
  background-color: $primary-color-subscriber !important;
  outline: none !important;
  border-style: solid !important;
  color: #000000 !important;
  border-radius: 8px !important; }

.deleteButton {
  width: 5rem;
  height: 5rem !important;
  background-color: $danger-button-bg-subscriber !important;
  outline: none !important;
  border-style: solid !important;
  border-radius: 8px !important; }

.searchButton {
  margin-top: -48px !important;
  margin-left: 280px !important;
  width: 6%;
  height: 35px !important;
  background-color: $primary-color-subscriber !important;
  outline: none !important;
  border-style: solid !important; }

.markallButton {
  border-radius: 8px !important;
  background-color: $primary-color-subscriber !important; }

.search {
  margin-top: 24px !important;
  margin-left: -40px !important; }

.searchField {
  height: 30px;
  box-shadow: none !important;
  background-color: rgba(36, 46, 66, 0.1) !important;
  border: 0.5px solid rgba(248, 249, 255, 0.95) !important;
  border-radius: 8px !important;
  margin-left: 42px;
  outline: none !important;
  padding-left: 10px !important;
  &:focus {
    border: 1px solid $primary-color-subscriber !important; } }

.form-control + .form-control {
  margin-top: 1em; }

.tab {
  width: fit-content; }

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none; }
.paginationItem {
  margin-right: 4px;
  &.active > .paginationLink {
    background-color: $primary-color-subscriber;
    color: white; }
  &.disable {
    opacity: 0.7;
    & > .paginationLink {
      cursor: default; } } }
.paginationLink {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: $grey-med-dark;
  text-decoration: none !important;
  font-weight: 300;
  &:hover {
    color: $general-text-color; } }
.itemNext > a, .itemPrev > a {
  font-weight: bold !important; }


.customCheckStyle {
  appearance: none;
  margin-top: 0.2rem;
  font: inherit;
  color: currentColor;
  min-width: 1em !important;
  height: 1em !important;
  border: 0.1px solid $general-text-color !important;
  border-radius: 0.19em !important;
  transform: translateY(-0.075em);
  cursor: pointer;

  &::before {
    content: "";
    width: 0.8em !important;
    height: 0.8em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color-subscriber;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  &:checked::before {
    transform: scale(1); } }

.trashIcon {
  cursor: pointer;
  margin-left: 24px;
  width: 25px;
  height: 25px;
  &:hover {
    opcaity: 1;
    path {
      stroke: red; } } }

.eyeIcon {
  cursor: pointer;
  margin-left: 24px;
  width: 35px;
  height: 30px;
  fill: rgba($general-text-color, 0.45);
  &:hover {
    opcaity: 1; } }

.dateTime {
  max-width: 10rem !important;
  min-width: 16rem !important;
  margin-left: 1rem !important;
  margin-top: 0.5rem !important;
  margin-right: -2rem !important; }

@media (max-width: 1100px) {
  .dateTime {
    min-width: 0px !important; } }

.iconOne {
  max-width: 4rem !important;
  margin-top: 0.5rem !important;
  line-height: 1.4 !important; }

.iconTwo {
  max-width: 5rem !important;
  margin-top: 0.5rem !important;
  margin-right: 1rem !important; }

.text1 {
  font-size: 15px !important;
  padding-top: 0.5rem !important;
  line-height: 1.4  !important; }

.text2 {
  font-size: 20px !important; }

.checkOuter {
  max-width: 3rem !important;
  margin-top: 0.3rem !important; }

.readButton1 {
  text-align: right !important;
  margin-top: 3.5rem !important; }

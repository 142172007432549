@import 'src/styles/variables';

.title {
  font-size: 16px !important; }
.form {
  padding: 0 20px 20px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  height: 40px !important;
  font-size: 13px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  & > div {
    margin-left: 10px; } }
.inputLabel1 {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  width: 7.6rem !important;
  flex: none !important;
  max-width: 8rem !important;
  & > div {
    margin-left: 10px; } }
.input1 {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  height: 40px !important;
  font-size: 13px !important;
  min-width: 29.8rem;
  margin-left: -1.7rem;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.input2 {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.input3 {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  width: 30rem !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.cancelButton {
  background-color: $cancel-button-bg !important;
  color: $default-button-txt;
  opacity: 0.4;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px; }
.submitButton {
  background: $primary-btn-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  color: $default-button-txt; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.badge {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
  font-size: 12px !important;
  &-primary-background {
    background: $primary-color !important; }
  &-secondary-background {
    background: rgba($general-text-color, 0.45) !important; }
  &-orange-background {
    background: $orange !important; } }
.tabs {
  margin-bottom: 20px;
  & li {
    flex-grow: 1;
    width: 50%;
    opacity: unset; } }
.tabBadge {
  display: inline-block;
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 20px;
  background: $orange; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }

.img {
  width: 50% !important;
  margin-left: -0.4rem !important; }

.icon {
  &-eye {
    width: 28px;
    height: 20px;
    margin-top: -5.5rem;
    margin-left: -30px;
    background: unset;
    fill: rgba($general-text-color, 0.45);
    &:hover {
      fill: rgba($primary-color, 0.45); }
    & svg {
      transition: fill 0.35s;
      width: 27px;
      height: auto;
      fill: rgba($general-text-color, 0.45); } } }

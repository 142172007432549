
@import 'src/styles/variables';

.appModal {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }

.modal {
  display: flex !important;
  justify-content: center !important; }

.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }

.title1 {
  font-family: 'Roboto', sans-serif;
  // font-weight: 600 !important
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }

.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }

.backdropC {
  width: 100% !important;
  height: 100% !important; }

.modal {
  display: flex  !important;
  justify-content: center !important; }

.form {
  padding: 20px 0px 20px 0px; }
.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 100%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.formLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-align: left !important;
  font-size: 13px !important;
  &:hover {
      border-color: none !important; }
  &:active {
    border-color: none !important; } }

.cancelButton {
  background: $cancel-button-bg-subscriber;
  color: $default-button-txt-subscriber;
  opacity: 0.45;
  margin-right: 30px;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px; }
.cancelButton1 {
  background: $save-button-bg-subscriber;
  color: $default-button-txt-subscriber;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px; }
.submitButton {
  background: $save-button-bg-subscriber;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  letter-spacing: inherit;
  color: $default-button-txt-subscriber;
  border-radius: 8px; }
.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: inherit;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }
.content1 {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: inherit;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important;
  font-weight: bold !important; }

@import 'src/styles/variables';

.title {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  text-align: center; }
.form {
  padding: 0 20px 20px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.button {
  border-radius: 8px;
  min-width: 100px;
  margin-top: 30px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }
.pending-color {
  color: rgba($general-text-color, 0.45); }
.cancelled-color {
  color: $red; }
.approved-color {
  color: $green; }
.under_review-color {
  color: $orange; }
.rejected-color {
  color: $red; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-left: 20px; }
.imgThumb {
  border-radius: 8px !important;
  width: 230px;
  height: 140px;
  object-fit: contain;
  background: #dddddd;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.image {
  pointer-events: none;
  border: 1px solid;
  background: #fff;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.document {
  border-radius: 8px !important;
  width: 585px;
  height: 55px;
  object-fit: contain;
  background: #dddddd;
  margin-bottom: 20px;
  margin-left: 15px !important; }
.documentIcon {
  padding-top: 7px;
  padding-left: 7px !important; }
.documentContent {
  padding-top: 16px;
  font-size: 13px !important; }
.documentButton1 {
  padding-top: 10px;
  padding-left: 305px !important;
  background-color: transparent !important;
  margin-top: 5px !important; }
.documentButton2 {
  padding-top: 10px;
  padding-left: 30px !important;
  background-color: transparent !important;
  margin-top: 5px !important; }
.button_container {
  display: flex; }
.grab {
  cursor: grab !important; }

@import 'src/styles/variables';

.default {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300px;
  line-height: 20px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 10px !important;
  border: '10px solid red';
  border-color: 'orange';
  display: flex;
  flex-direction: column; }

.heading {
  font-size: 1.6rem;
  font-weight: 600 !important;
  color: white !important; }

.backButton {
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 50;
  min-width: unset;
  height: 40px;
  padding: 10px;
  color: black !important; }

.map {
  border: 2px solid #fa9050;
  min-height: 300px; }
.title {
  font-size: 18px !important;
  color: #fa9050; }
.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }

.app {
  margin: 0 auto;
  width: 50%; }

.selected-date {
  margin: 0 auto; }


.times button {
  display: block;
  background-color: #6f48eb;
  color: white;
  margin-top: 5px; }


.panelHeader {
  height: 32px;
  line-height: 40px;
  margin-top: 100px;
  color: #fff;
  font-weight: bolder;
  font-family: 'Times New Roman', Times, serif;
  margin-right: 10px; }
.panelSubHeader {
  height: 32px;
  line-height: 40px;
  margin-top: -10px;
  color: #fff;
  font-weight: bolder;
  font-family: 'Times New Roman', Times, serif;
  margin-right: 10px; }

.button {
  background: #f8994b;
  font-size: 1.5rem;
  font-weight: bolder;
  font-family: 'Times New Roman', Calibri, 'Trebuchet MS', sans-serif;
  width: 370px;
  height: 100px;
  padding: 70px;
  color: white !important;
  border-radius: 70%;
  border-radius: 0%;
  margin-top: 100px;
  margin-left: 180px; }

.manageButton {
  background: #f8994b;
  font-size: 1.5rem;
  font-weight: bold;
  width: 390px;
  height: 100px;
  padding: 70px;
  color: white !important;
  border-radius: 0%;
  margin-top: 100px;
  margin-left: 50px; }


.section {
  border-radius: 8px;
  padding: 20px 0;
  width: 100%;
  &-citation-details {
    min-height: 500px; } }
.form {
  width: 15%;
  min-width: 300px; }
.btn {
  font-size: 15px;
  height: 40px;
  font-weight: 400;
  padding: 15px 30px !important;
  color: white !important;
  border-radius: 8px;
  &-process {
    background: $primary-btn-color-subscriber;
    width: 20px;
    margin-left: 20%;
    font-weight: bold; } }

.formInput {
  font-size: 14px;
  text-align: center;
  border-radius: 8px !important;
  box-shadow: #ee6211  !important;
  border: 1px solid #b7c6d6 !important;
  margin-left: -5%;
  margin-top: -7%; }
.table {
  width: 60% !important;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #ee6211 !important;
  margin-left: 5%;
  font-family: 'Times New Roman', Times, serif;
  margin-top: -10%;
  position: relative;

  & td {
    width: 10%;
    border: 1px solid #ee6211 !important;
    font-size: 16px;
    opacity: 1.0; }
  & th {
    width: 4%;
    padding: auto !important;
    border: 1px solid white !important; } }

.playWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($general-text-color, 0.45); }
.imgThumb {
  border-radius: 8px !important;
  width: 250px;
  height: 150px;
  object-fit: contain; }
.image {
  pointer-events: none; }
.text {
  width: 50%;
  min-width: 400px;
  color: rgba($general-text-color, 0.8);
  & em {
    font-style: normal;
    color: $red;
    text-style: normal; } }

.button {
  background: #f36c13;
  font-size: 1.2rem;
  font-weight: bolder;
  font-family: 'Times New Roman',Times,  sans-serif;
  width: 250px;
  height: 50px;
  padding: 25px;
  color: white !important;
  border-radius: 70%;
  border-radius: 0%;
  margin-top: 0.1px;
  margin-left: 80%;
  color: white; }

@media screen and (min-width: 415px) {
  .container {
    flex-direction: row; } }

.site-container > * {
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0; }

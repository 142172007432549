@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 220px) !important; }
.actions {
  & > div {
    & > span {
      transition: display 2.75s;
      padding: 6px 15px;
      border-radius: 15px; } } }
.background {
  &-primary {
    background: $primary-color !important; }
  &-danger {
    background: $red !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px; }
  &-success {
    background: $green; }
  &-danger {
    background: $red; } }

.title {
  color: $page-header-txt; }

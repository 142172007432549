@import 'src/styles/variables';

.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: rgba($general-text-color, 0.4) !important; }
.content {
  text-align: center;
  font-size: 14px; }
.body {
  padding: 10px 40px 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.enableButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $primary-color !important; }
.disableButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $red !important; }
.title {
  text-align: center; }

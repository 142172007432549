@import 'src/styles/variables';

.table {
  display: block; }

.subSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  padding: 20px 0;
  width: 100%; }

.documentHeader {
  text-align: center !important;
  font-size: 17px;
  color: rgba(36, 46, 66, 0.75);
  font-weight: 400;
  margin-top: 1rem; }

.uploadButton {
  background: rgba($general-text-color, 0.45);
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px;
  margin-right: 10px;
  padding: 1rem; }
.createButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  border-radius: 8px;
  margin-top: 35px;
  margin-top: 35px; }

.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em; }

.document {
  border: 1px solid #ced4da !important;
  border-radius: 8px;
  padding: 2rem;
  background-color: rgba(36, 46, 66, 0.1);
  margin-bottom: 5rem; }

.deleteButton {
  margin-left: 15px;
  font-size: 14px;
  background-color: $red;
  border-radius: 8px; }

.editButton {
  margin-left: 15px;
  font-size: 14px;
  background-color: $primary-color;
  border-radius: 8px; }

.backTab {
  color: $general-text-color !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  display: flex;
  align-items: center; }
.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em;
  color: $page-header-txt !important; }
.tab {
  height: 100%;
  padding: 10px 16px;
  opacity: 0.65; }
.activeTab {
  background: rgba($general-text-color, 0.1); }

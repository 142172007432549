@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important;
  &-active {
    margin-top: 15px; } }
.form {
  width: 125%;
  padding: 5px 0 20px 2px; }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  margin-left: 10px !important; }
.formLabel {
  color: #000000 !important;
  & div {
    margin-left: 20px;
    line-height: 1.2 !important; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em; }
.btnFilter {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); }
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.5px solid rgba($general-text-color, 0.6); }
  &-success {
    background: $green; }
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.1); } }
.btn {
  font-size: 14px;
  padding: 20px;
  font-weight: 400 !important;
  border-radius: 8px !important;
  &-secondary {
    background: rgba($general-text-color, 0.45); }
  &-refresh {
    min-width: unset;
    width: fit-content;
    font-size: 16px; } }
.actions {
  & > div {
    & > span {
      transition: display 0.75s;
      padding: 5px 10px;
      border-radius: 15px; }
    & > span:first-child {
      border-right: 1px solid rgba($general-text-color, 0.6); } } }
.background {
  &-primary {
    background: $primary-color; }
  &-secondary {
    background: rgba($general-text-color, 0.7); } }
.subSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  padding: 20px 0;
  width: 100%; }

.fieldset {
  & button {}
  &-right-label {
    & label {
      & > div {
        margin-left: 5px; } } } }

.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 70%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }

.button {
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  min-width: fit-content;
  border-radius: 8px; }


.dragDropLabel {
  background: white !important;
  border-radius: 8px !important;
  max-width: 310px !important;
  font-weight: 1000px;
  height: 170px !important;
  padding: 0 40px 0 0 !important;
  position: relative !important;
  margin-left: 9.3rem !important;
  margin-bottom: -7rem !important;
  margin-top: -0.5rem !important;
  border: 1px solid #ced4da !important; }



.formatsText {
  color: black;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }

.boldText1 {
  color: 'grey-dark';
  font-weight: 700;
  font-size: 12px; }


.fileLabel {
  margin-left: 0.2rem !important;
  font-size: 13px !important;
  color: #a2a3a5 !important;
  margin-top: 0.5rem !important;
  position: absolute !important; }

.img {
  height: 70px !important;
  width: 70px !important;
  border: none !important;
  margin-top: -1rem !important; }

.fileName {
    font-size: 13px !important; }



.detailsLabel {
  font-weight: 600 !important;
  color: #464a52 !important;
  opacity: 0.6 !important;
  font-size: 15px !important;
  margin-left: 0.2rem !important; }


.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: $general-text-color;
  margin-bottom: 10px;
  margin-top: 30px;
  width: 15%;
  margin-left: 0.4rem !important; }
.toggle {
  width: 60px;
  height: 30px;
  background: $grey-dark;
  border-radius: 20px;
  & > div {
    left: calc(25px - 100%);
    & > span:first-child {
      &::after {
        width: 20px;
        height: 20px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }

.toggleLabel {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $general-text-color !important;
  opacity: 0.5; }



.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($primary-color, 0.5);
  & > div {
    left: -2px; } }


.iconInput {
  padding-left: 30px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 10px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }


.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95); } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }

.customTooltip {
  margin-right: 1px; }


@media (min-width: 768px) and (max-width: 991px) {
  .customTooltip {
    margin-right: 50px; } }

@media (max-width: 767px) {
  .customTooltip {
    margin-right: 40px; } }

@import 'src/styles/variables';

.input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10.5px 14px 10.5px 17px;
  color: $general-text-color;
  font-size: 13px;
  font-weight: 300;
  height: auto;
  border: 1px solid rgba($snuff, 0.27);
  box-shadow: 0 0 6px rgba($azure, 0.1);
  background-color: white;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::placeholder {
    color: $snuff; }
  &:focus {
    border: 1px solid $primary-color;
    outline: 0; }
  & + div.iconWrapper {
    position: absolute;
    pointer-events: none;
    right: 8px; } }

div.input {
  height: 40px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; }
  & + div.iconWrapper {
    pointer-events: auto; } }
div.startIconWrapper {
  position: absolute;
  pointer-events: none;
  left: 8px; }

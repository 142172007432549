
@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important; }
.tableActive {
  margin-top: 24px; }
.form {
  width: 100%;
  padding: 0 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }

.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px;
  margin-bottom: 10px; }

.button {
  border-radius: 10px;
  font-size: 15px;
  font-weight: 400;
  min-width: fit-content;
  border-radius: 8px; }

.collapseSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.toolbarButton {
  margin-left: 20px;
  min-width: 43px;
  font-size: 16px;
  border-radius: 8px; }
.activeFilterTab {
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1) !important; }
.activeFilterIcon {
  & path {
    fill: rgba($general-text-color, 0.5);
    stroke: rgba(36, 46, 66, 0.5); } }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  left: 152px;
  bottom: 5px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.actions {
  & > div {
    & > span {
      transition: display 0.75s;
      padding: 5px 10px;
      border-radius: 15px; }
    & > span:first-child {
      border-right: 1px solid rgba($general-text-color, 0.6); } } }
.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }
.actions {
  & > div {
    & > span {
      transition: display 0.35s; } } }
.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); }
  &-primary {
    background: $primary-color; } }

.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }

.activeIcon {
  fill: none !important;
  & line {
    stroke: $general-text-color; }
  & circle {
    fill: rgba($general-text-color, 0.6) !important; }
  & path {
    fill: #E9EBF0  !important; } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important; }


.rewardsIcon {
  color: $primary-color; }


.toggleLabel {
  font-size: 13px;
  color: rgba($general-text-color, 0.6);
  text-align: left;
  & div {
    margin-left: 20px; } }

.toggleDiv {
    display: flex !important;
    justify-content: flex-start;
    text-align: left !important;
    position: absolute;
    left: 35.6%;
    bottom: 26%; }

@media ( max-width: 991px  ) {
  .toggleDiv {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left !important;
    position: relative !important;
    left: 5%;
    bottom: 26%; }
  .toggle {
    left: 50px;
    bottom: 5px; }
  .activeTxt {
    left: 70px !important; }
  .pauseTxt {
    left: 70px !important; } }

@media ( min-width: 992px  ) and  ( max-width: 1650px  ) {
  .toggleDiv {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left !important;
    position: absolute !important;
    left: 36.5%;
    bottom: 26%; }
  .toggle {
    left: 85px;
    bottom: 5px; }
  .activeTxt {
    left: 100px !important; }
  .pauseTxt {
    left: 100px !important; } }

.activeTxt {
  font-size: 13px !important;
  left: 170px;
  margin-bottom: -5px;
  position: relative;
  color: $green; }

.pauseTxt {
  font-size: 13px !important;
  left: 170px;
  margin-bottom: -5px;
  position: relative;
  color: $red; }

.polygonIcon {
  left: -13px;
  position: absolute !important;
  fill: #242E42 !important; }

.barIcon {
  left: -14px !important;
  position: absolute !important;
  width: 7px !important;
  height: 8px !important;
  fill: $primary-btn-color !important; }


@import 'src/styles/variables';

.formTextInput {
  box-shadow: none !important;
  border: 1px solid rgba($general-text-color, 0.4) !important;
  border-radius: 8px !important; }
.content {
  text-align: left;
  font-size: 13px; }
.body {
  padding: 10px 40px 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $cancel-button-bg !important;
  color: $default-button-txt;
  opacity: 0.4; }
.submitButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $red !important; }
.title {
  color: $general-text-color !important;
  font-size: 16px !important; }

@import 'src/styles/variables';

.title {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }
.qr_image {
  width: 100%;
  height: 100%; }
.container {
  padding-inline: 30px; }
.send-email {
  font-weight: 400 !important; }
.button {
  border-radius: 8px;
  font-size: 15px;
  color: $default-button-txt;
  &-disabled {
    background: rgba($general-text-color, 0.6); } }
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px; }

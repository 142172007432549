@import 'src/styles/variables';

.title {
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
  text-align: center !important; }
.form {
  width: 100%;
  padding: 20px 10px 20px !important;
  font-family: 'Roboto', sans-serif; }

@media (min-width: 576px) and ( max-width: 767px  ) {
  .form {
    margin-left: 2rem; } }


.fieldset {
  width: 50% !important;
  margin-left: -1rem !important;
  & button {
    border: unset !important;
    height: 40px; }
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } }
  &-dateStroke {
    width: 10px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 20px;
    left: -20px; }

  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color-subscriber;
    cursor: pointer; } }


@media ( max-width: 991px ) {
  .fieldset {
    &-dateStroke {
      width: 10px;
      border: 1.5px solid rgba($general-text-color, 0.65);
      top: 20px;
      left: 3px; } } }



.fieldset2 {
  width: 150% !important;
  & button {
    border: unset !important;
    height: 40px; }
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } }
  &-dateStroke {
    width: 10px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 20px;
    left: -5px; }

  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color-subscriber;
    cursor: pointer; } }


.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 110%;
        margin-left: 2rem !important; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 100%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.formDateTimeInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  text-align: center !important; }
.formLabel {
  & > div {
    margin-left: 10px; } }
.button {
  border-radius: 8px;
  min-width: 100px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }


.locationbtn {
  background-color: white !important;
  color: $primary-color-subscriber !important;
  font-weight: bolder !important;
  margin-left: 72%;
  margin-bottom: -40px;
  text-decoration: underline; }


.fetchBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  position: absolute;
  margin-left: 173% !important;
  margin-top: -18%; }


.fetchLotsBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  position: absolute;
  margin-left: 73% !important;
  margin-top: -18% !important; }

.checkBox {
  margin-top: -6% !important;
  margin-bottom: 10px;
  margin-left: -10px; }



.LocationIcon {
  position: absolute;
  right: 5px;
  top: 8px;
  stroke: $disabled-bg-color; }




.map {
  border: 2px solid #fa9050;
  min-height: 300px;
  min-width: 900px;
  margin-top: 100px; }


.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }


.container {
  height: 400px;
  & > div {
    position: relative !important; } }

.mapBtn {
  background-color: $primary-color-subscriber !important;
  color: $default-button-txt-subscriber;
  font-weight: 450 !important;
  min-width: 100px !important;
  width: fit-content !important;
  margin-left: 82.2% !important;
  height: auto !important;
  font-size: 14px;
  margin-bottom: 1rem !important;
  margin-top: -1.3rem !important;
  border-radius: 2px !important;
  position: relative;
  &:hover {
    background-color: 0.9 !important; }
  &:active {
    transform: scale(0.95);
    background-color:  $disabled-bg-color; } }


@media (min-width: 576px) and ( max-width: 767px  ) {
  .mapBtn {
    margin-left: 80.3%  !important; } }

.formDateInput {
  font-size: 13px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  margin-right: 2rem !important; }


.label1 {
  color: rgba($general-text-color, 0.9);
  opacity: 0.6 !important;
  font-size: 13px !important;
  text-align: left;
  margin-left: 1rem !important;
  width: 5.7rem !important; }

.label2 {
  color: rgba($general-text-color, 0.9);
  opacity: 0.6 !important;
  font-size: 13px !important;
  text-align: left;
  margin-left: 1.2rem !important;
  margin-top: 10px;
  width: 13rem !important; }

.viewBtn {
  background-color: white;
  color: #000000 !important;
  font-weight: 350 !important;
  margin-top: -37.5px;
  min-width: 20px !important;
  margin-left: 82% !important;
  height: 35px !important;
  font-size: 14px;
  position: relative !important;
  border: 1px solid grey !important;
  &:hover {
    background-color: $general-text-color;
    & path {
      stroke: white  !important; } }
  &:active {
    transform: scale(0.95); }
  & path {
    stroke: rgba(36, 46, 66, 0.5);
    width: 20px; }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    margin-left: 82% !important; } }

@media (min-width: 576px) and ( max-width: 767px ) {
  .viewBtn {
    margin-left: 78% !important; }
  .note {
    margin-left: 2rem !important; }
  .getPassBtn {
    margin-left: 4.7rem !important; } }



.note {
  font-size: 13px !important;
  margin-top: 0.5rem !important;
  text-align: center !important; }

.getPassBtn {
  border-radius: 5px;
  max-width: 70% !important;
  width: 70%;
  display: flex;
  position: relative;
  height: 40px;
  margin-left: 5.4rem;
  font-size: 17.5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500 !important;
  color: $default-button-txt-subscriber !important;
  background: $primary-btn-color-subscriber;
  &:hover {
    opacity: 0.9; }
  &:active {
    transform: scale(0.95); } }

.noteRow {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important; }

@import 'src/styles/variables';

.borderPrimary {
  padding: 30px 30px 20px 30px !important;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 8px !important;
  margin: 7px !important;
  height: 96% !important;
  box-shadow: 0px 1px 2px 0px #606C801A; }

.cardTitle {
  margin-bottom: 22px !important; }
.title {
  font-size: 16px;
  font-weight: 550;
  color: #242E42D9;
  align-content: center !important; }

.dateDropdown {
  border: 1px solid rgba($snuff, 0.27) !important;
  border-radius: 0 !important;
  & > button:hover {
    background-color: $primary-color;
    & > span {
      color: white; } } }

.currentValueRow {
  margin-bottom: 0px; }
.currentValue {
  font-size: 18px;
  word-break: break-word; }
.previousResultRow {
  margin-top: 6px;
  min-height: 16px; }
.moreRow {
  margin-top: 6px;
  & span {
    opacity: 1 !important; } }

.secondaryText {
  color: $general-text-color;
  opacity: .6;
  font-size: 13px;
  font-weight: 300; }
.less {
  color: $danger-color;
  font-size: 14px; }
.raise {
  color: green; }

.fontMd {
  font-size: $btn-font-size-md !important;
  font-size: 14px; }

.app {
  width: 50% !important;
  margin-top: -35px !important; }

.vl {
  border-left: 1px dashed #dee2e6 !important;
  height: 20px;
  position: relative;
  margin-left: 30px;
  margin-right: 30px; }

.vline {
  border-left: 1px dashed #dee2e6 !important;
  height: 200px;
  position: relative;
  top: -10px; }

.icon {
  width: 50px !important;
  height: 50px !important; }

.rightSide {
  margin-top: -20px;
  text-align: center !important; }

.number {
  font-size: 25px !important;
  font-weight: bold !important;
  margin-top: 10px;
  padding-bottom:  8px !important; }

.borderDown {
  border: 1px dashed #dee2e6 !important; }

.bottomButton {
  display: flex;
  justify-content: space-between;
  width: 700px;
  padding-left: 11%;
  padding-right: 10%;
  margin-top: -10px;
  color: $default-button-txt; }

.button {
  min-width: 0px !important;
  background: #343a40 !important;
  border-radius: 8px !important;
  color: $default-button-txt; }

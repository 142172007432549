@import 'src/styles/variables';

.title {
  font-size: 15px !important;
  opacity: 0.8 !important; }
.form {
  padding: 0 20px 20px; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.button {
  border-radius: 8px;
  min-width: 120px;
  height: 40px;
  font-size: 14px;
  color: $default-button-txt;
  background: $primary-btn-color;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg;
    opacity: 0.6; } }

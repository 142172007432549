@import 'src/styles/variables';

.title {
  font-size: 16px !important;
  text-align: center; }
.form {
  padding: 0 20px 20px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.fieldset {
  width: 50%;
  padding: 0 16px;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  max-width: 7rem !important; }
.inputSingle {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  width: 107% !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.cancelButton {
  background: rgba($general-text-color, 0.45);
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 30px; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-left: 20px;
  margin-top: 30px; }
.pending-color {
  color: rgba($general-text-color, 0.45); }
.cancelled-color {
  color: $red; }
.approved-color {
  color: $green; }
.under_review-color {
  color: $orange; }
.rejected-color {
  color: $red; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.background {
  &-danger {
    color: red  !important; }
  &-primary {
    color: blue  !important; } }
.formDropdownInput {
  width: 98.5% !important;
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  height: 40px;
  text-align: left !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.uploadField {
  margin-left: 148px; }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.toggleIcon {
  margin-left: -23px !important;
  margin-top: -23px !important; }
.toggleLable {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.toggleStatus {
  font-weight: inherit;
  margin-left: 35px !important;
  margin-top: -23px !important; }
.powerOff {
  background-color: #EA3223 !important; }
.image {
  margin-top: 2rem !important;
  justify-content: center !important; }

@import 'src/styles/variables';

.title {
  font-size: 16px !important; }
.form {
  width: 100%;
  padding: 20px 0 20px 20px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }

.detailsLabel2 {
  margin-top: -0.6rem !important;
  margin-left: 1rem !important; }



.subdetailsLabel {
  font-weight: 500 !important;
  color: $general-text-color !important;
  opacity: 0.6 !important;
  margin-left: -2px;
  font-size: 13px !important;
  margin-bottom: 0.5rem !important; }

.hourlyStaticLabel {
  font-weight: 500 !important;
  color: $general-text-color !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-top: 0.8rem !important; }

.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important;
    border: 0.05em solid rgba($general-text-color, 0.15) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.cancelButton {
  background: rgba($general-text-color, 0.45);
  font-weight: inherit;
  font-size: 14px;
  min-width: 120px;
  border-radius: 8px;
  padding: 20px !important;
  margin-top: 1rem !important; }

.pending-color {
  color: rgba($general-text-color, 0.45); }
.cancelled-color {
  color: $red; }
.approved-color {
  color: $green; }
.under_review-color {
  color: $orange; }
.rejected-color {
  color: $red; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  width: 60% !important;
  margin-bottom: -2rem !important;
  margin-top: -0.2rem !important; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 120px;
  border-radius: 8px;
  margin-left: 3rem !important;
  padding: 20px !important;
  margin-top: 1rem !important; }


.checkDetailsLink {
  font-size: 13px !important;
  margin-left: 20rem !important;
  margin-top: 3.5rem !important;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 25rem !important; } }

.tabs {
  margin-bottom: 30px;
  width: 100% !important;
  & li {
    flex-grow: 2 !important;
    width: 50%;
    opacity: unset; } }


.fieldset {
  & button {}
  &-right-label {
    & label {
      & > div {
        margin-left: 10px; } } } }

.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 72%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }

.fieldset-noCharge {
  & > div {
    & > div {
      & > div:first-child {
        width: 78%;
        margin-right: 5.3rem;
        margin-left: -6rem;
        margin-top: 5px; } } } }

.fieldset-noCharge-error {
  & > div {
    & > div {
      & > div:first-child {
        width: 78%;
        margin-top: 5px;
        margin-right: 4.7rem;
        margin-left: -6.1rem; } } } }


.fieldset-radio {
  min-width: 71.5% !important;
  margin-top: 10px !important; }

.required {
  color: $primary-color;
  margin-left: 5px !important; }



.dragDropLabel {
  background: white !important;
  border-radius: 8px !important;
  max-width: 407px !important;
  font-weight: 1000px;
  height: 120px !important;
  padding: 0 40px 0 0 !important;
  position: relative !important;
  margin-left: 9.7rem !important;
  border: 1px solid #ced4da !important;
  margin-top: -2rem !important;
  font-size: 13px !important;
  margin-bottom: 1.5rem !important; }




.formatsText {
  color: black;
  font-size: 13px;
  margin-top: 5px;
  margin-left: 20px; }

.formatsText2 {
  color: black;
  font-size: 13px;
  margin-top: 3px;
  margin-right: 0.2rem !important; }

.boldText1 {
  color: 'grey-dark';
  font-weight: 700;
  font-size: 13px; }

.boldText2 {
  color: 'grey-dark';
  font-weight: 700;
  font-size: 13px;
  margin-right: 0.2rem !important; }


.fileLabel {
  margin-left: -81% !important;
  font-size: 13px !important;
  color: rgba($general-text-color, 0.6);
  margin-top: 0.5rem !important;
  font-weight: 200 !important;
  position: relative !important; }

.img {
  height: 90px !important;
  width: 160px !important;
  border: none !important;
  margin-top: -0.3rem !important;
  margin-right: 12rem !important;
  margin-left: -0.4rem !important;
  border-radius: 5px !important; }

.fileName {
  font-size: 13px !important;
  margin-left: 3rem !important; }

.uploadText {
  margin-top: -5.8rem !important;
  font-size: 13px !important;
  margin-left: 10rem !important; }
.cloudIcon {
  margin-top: -12rem !important;
  margin-left: 9rem !important;
  height: 30px !important; }




.checkboxText {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 2em;
  margin-left: 1.5rem !important; }

.customCheckBox {
  appearance: none;
  margin-top: 0.1rem !important;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 1rem !important;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

.daysContainer {
  width: 100% !important;
  display: flex !important;
  justify-content: justify !important;
  align-items: center !important; }

.daysContainer2 {
  max-width: 100px!important;
  display: flex !important;
  margin-top: 1rem !important; }


.customTooltip {
  margin-right: 1px;
  &:hover {
    color: $general-text-color; } }

.customTooltip2 {
  margin-right: -8px;
  &:hover {
    color: $general-text-color; } }


@media (min-width: 768px) and (max-width: 991px) {
  .customTooltip {
    margin-right: 50px; } }

@media (max-width: 767px) {
  .customTooltip {
    margin-right: 40px; } }

.idInput {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  min-width: 72% !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95); } }


.radioBtns {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  min-width: 150% !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95); } }


.sundaySection {
  margin-left: -6px; }


.addButton {
  background: $primary-color;
  width: 114%;
  margin-top: 20px !important;
  font-size: 14px;
  font-weight: 500 !important;
  border-radius: 8px;
  height: 40px; }

.plusIcon {
  position: absolute;
  top: 12px;
  left: 190px;
  width: 15px !important;
  height: 15px;
  color: white; }

.fieldset-four {
  & > div {
    & > div {
      & > div:first-child {
        width: 150%; } } } }

.checkboxFree1 {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 2em;
  margin-left: 23.8rem !important;
  z-index: 1; }

.checkboxFree2 {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 2em;
  margin-left: 2.6rem !important;
  z-index: 1; }

.addSecondButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 30px;
  min-width: 30px !important;
  height: 30px !important;
  border-radius: 8px;
  padding: 10px !important;
  margin-top: 0.3rem !important; }

.removeButton {
  font-weight: inherit;
  font-size: 30px;
  min-width: 20px !important;
  height: 20px !important;
  border-radius: 8px;
  padding: 10px !important;
  margin-top: 0.3rem !important; }

.trashIconStatic {
  position: absolute;
  cursor: pointer;
  margin-top: -1rem;
  margin-left: 83%;
  path {
    stroke: rgba($disabled-bg-color, 0.9) !important; }
  &:hover {
    opacity: 1;
    path {
      stroke: rgba($red, 0.7) !important; } } }

.trashIcon {
  position: absolute;
  cursor: pointer;
  margin-top: -5rem;
  margin-left: 83%;
  path {
    stroke: $disabled-bg-color !important; }
  &:hover {
    opacity: 1;
    path {
      stroke: rgba($red, 0.7) !important; } } }



.fieldset-four {
  & > div {
    & > div {
      & > div:first-child {
        width: 160%;
        margin-right: 5px; } } } }


.addButtonCustom {
  background: $primary-color;
  font-weight: inherit;
  font-size: 30px;
  min-width: 30px !important;
  margin-right: -10px;
  height: 30px !important;
  border-radius: 8px;
  padding: 10px !important;
  margin-top: 0.3rem !important; }

.removeButtonCustom {
  background: red;
  font-weight: inherit;
  font-size: 30px;
  min-width: 30px !important;
  margin-right: -10px;
  height: 30px !important;
  border-radius: 8px;
  padding: 10px !important;
  padding-bottom: 15px !important;
  margin-top: 0.3rem !important; }


.customLabels {
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: -5 !important; }


.customLabelSection {
  font-size: 13px !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  text-align: left !important; }

.customLabels2 {
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: 15px; }


.customLabelSection2 {
  font-size: 13px !important;
  text-align: right !important; }



.customLabels3 {
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: -5px;
  min-width: 8rem !important; }


.customLabelSection3 {
  font-size: 13px !important;
  text-align: left !important; }


.customLabelRow {
  display: flex  !important;
  justify-content: center !important;
  margin-left: 150px !important;
  margin-top: 5px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important; }



.checkboxRow {
  margin-top: -13px !important;
  margin-bottom: 1px !important; }


.checkboxLabel {
  margin-top: 2px !important;
  color: rgba($general-text-color, 0.6); }

.seperator {
  margin-bottom: 10px;
  width: 92%;
  display: flex !important;
  justify-content: center !important; }

.divider {
  margin-left: 50px;
  color: $general-text-color !important; }


.setTrashIcon {
  position: absolute;
  cursor: pointer;
  margin-top: -3rem;
  margin-left: 50%;
  path {
    stroke: $disabled-bg-color !important; }
  &:hover {
    opacity: 1;
    path {
      stroke: rgba($red, 0.7) !important; } } }

.setAddIcon {
  position: absolute;
  cursor: pointer;
  margin-top: -3rem;
  margin-left: 56%;
  path {
    fill: $primary-color !important; }
  &:hover {
    opacity: 1;
    path {
      stroke: rgba($red, 0.7) !important; } } }

.fieldTrashIcon {
  position: absolute;
  cursor: pointer;
  margin-top: -1rem;
  margin-left: 80%;
  path {
    stroke: $disabled-bg-color !important; }
  &:hover {
    opacity: 1;
    path {
      stroke: rgba($red, 0.7) !important; } } }

.btnSave {
  margin: 10px 20px 0 0;
  color: $default-button-txt;
  background: $save-button-bg; }

.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-open {
    background: $green; }
  &-close {
    background: $red; } }

.availabiliy {
  display: flex !important;
  align-items: center !important;
  border: 1px solid !important;
  border-radius: 8px !important;
  padding: 6px !important; }

.availabilityStatus {
  font-size: 15px !important;
  color: #000000 !important; }

.btnTool {
  min-width: 35px;
  margin-right: 10px; }

.lotBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  color: #000000;
  width: 18.8rem;
  border-radius: 8px;
  border: 1px solid $primary-color;
  background-color: rgba($grey-med-dark, 0.58);
  margin-left: 25px !important; }

.boxText {
  color: #000000;
  font-size: 16px !important;
  font-weight: bold !important;
  padding-left: 0.1rem !important; }

.boxText1 {
  color: #000000;
  font-size: 16px !important;
  font-weight: bold !important;
  margin-top: 0.2rem !important;
  padding-left: 0.4rem !important; }

.arrowTop {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $primary-color;
  margin-left: 14px;
  margin-top: -15px; }

.dateStroke {
  width: 15px;
  border: 1.5px solid rgba($general-text-color, 0.65);
  top: 7.3rem;
  left: 15.5rem; }

.okButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 60px;
  border-radius: 8px;
  margin-left: 26.5rem !important; }

.closedMessage {
  font-size: 14px;
  color: $red; }

.radioButton {
  height: 16px;
  width: 16px;
  border: 2px solid rgba($general-text-color, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }
.active {
  border: 2px solid $primary-color;
  & div {
    height: 8px;
    width: 8px;
    border: inherit;
    border-radius: inherit; }
  & svg {
    height: 8px;
    width: 8px !important;
    color: $primary-color; } }
.inactive {
  border: 2px solid rgba($general-text-color, 0.25);
  & div {
    height: 8px;
    width: 8px;
    border: inherit;
    border-radius: inherit; }
  & svg {
    height: 8px;
    width: 8px !important;
    color: white; } }

.defaultFieldset {
  margin-left: 2px;
  & div {
    & input {
      width: 103%;
      margin-left: 5px; } } }

.defaultFineInput {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  margin-left: 10px; }

.alertMsg {
  color: #FB745B !important;
  font-size: 13px !important;
  width: 100% !important;
  margin-left: 23px;
  margin-top: -10px; }

.radioInput {
  & > div {
    & > div {
      & > div {
        & > div {
          margin-right: 4rem !important; } } } } }

@import 'src/styles/variables';

.title {
  font-family: 'Roboto', sans-serif !important;
  font-size: 13px !important;
  text-align: left;
  font-weight: bold !important;
  margin-left: 50px !important; }
.form {
  padding: 10px 20px 20px 30px; }
.detailsLabel {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.button {
  border-radius: 8px;
  min-width: 100px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }

.subject {
  text-align: left !important; }

.border {
  border: 1px solid #3A9CED !important; }

.content {
  font-size: 13px !important;
  text-align: left;
  margin-left: 50px !important; }

.icon {
  margin-left: 50px !important; }

.inputField {
  width: 70%;
  height: 35px;
  outline: none;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px;
  padding: 12px !important; }

.inputSignatureField {
  width: 121%;
  height: 35px;
  outline: none;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px;
  padding: 12px !important; }

.ul {
  font-family: 'Roboto', sans-serif !important;
  list-style: none;
  max-height: 200px;
  height: 130px;
  margin: 0;
  overflow: auto;
  padding: 0;
  text-indent: 10px; }

.li {
  font-family: 'Roboto', sans-serif !important;
  line-height: 100%;
  background: #ebebeb;
  list-style: none;
  // max-height: 200px;
  height: 30px;
  margin: 0;
  overflow: auto;
  padding: 0;
  text-indent: 10px; }
.li:active {
  background-color: $primary-color !important; }
.li.selected {
  background-color: $primary-color; }

.listContainer {
  width: 68.7%; }

.customCheckStyle {
  appearance: none;
  margin-top: 0.2rem !important;
  margin-left: 0.2rem !important;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid #ced4da;
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

.toolbarClassName {
  font-family: 'Roboto', sans-serif !important;
  background: #dfdfdf !important;
  border: 1px solid #a3a3a3 !important;
  margin-bottom: 0px !important; }

.editorClassName {
  font-family: 'Roboto', sans-serif !important;
  border: 1px solid #a3a3a3 !important;
  border-top: none !important;
  height: 200px !important;
  padding: 0 10px 0 10px !important; }

.addButton {
  border: 1px solid #a3a3a3 !important;
  padding: 10px; }

.header {
  border-bottom: none !important;
  background: #ebf8ff  !important; }

.footer {
  border-top: none !important;
  background: #ebf8ff  !important;
  font-size: 15px !important;
  line-height: 1.2; }

.container {
  font-family: 'Roboto', sans-serif !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px; }


.leftSection {
  font-family: 'Roboto', sans-serif !important;
  width: auto;
  flex-direction: column; }

.rightSection {
  font-family: 'Roboto', sans-serif !important;
  flex-direction: column; }
.event_name {
  font-family: 'Roboto', sans-serif !important;
  border: 0.8px solid;
  border-radius: 10px;
  padding: 12px;
  width: fit-content; }

.rightSectionAttr {
  font-family: 'Roboto', sans-serif !important;
  font-size: 15px;
  line-height: 1.2; }

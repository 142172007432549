@import 'src/styles/variables';

.form {
  margin-top: 20px;
  padding: 0 20px;
  background-color: #E8E8E8;
  border-radius: 15px;
  width: 110%; }

.heading {
  font-weight: 600 !important;
  color: #25272b94 !important;
  opacity: 0.6 !important;
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 7px;
  font-family: Roboto, sans-serif !important; }


.icon {
  margin-right: 8px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: inline-block;
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.3); }
  &-orange {
    background: $orange; }
  &-success {
    background: $green; }
  &-default {
    background: rgba($general-text-color, 0.7); } }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 15px;
  color: $primary-color; }

.dollarIconTo {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 15px;
  color: $primary-color; }

.iconInput {
  padding-left: 25px !important; }
.formInput {
  width: 98.5% !important;
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }

.formLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  font-family: Roboto, sans-serif !important; }


.button {
  border-radius: 8px;
  font-size: 15px;
  color: $default-button-txt;
  font-weight: 100;
  background: $primary-btn-color;
  font-family: Roboto, sans-serif !important;
  padding: 20px;
  width: 150px;
  &-disabled {
    background: $cancel-button-bg; } }


.btn {
  min-width: fit-content;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  transition: background .5s;
  color: $default-button-txt;
  & svg {
    fill: white; }
  &-primary {
    background: $primary-btn-color; }
  &-disabled {
    background: rgba($general-text-color, 0.6);
    &:hover {
      background: $danger-color; } } }

.main {
  background-color: #E8E8E8;
  border-radius: 8px;
  &.header {
    font-weight: 500 !important;
    color: #25272b94 !important;
    opacity: 0.6 !important;
    font-size: 15px;
    margin-bottom: 5px; } }

.fields {
  display: grid; }

.fieldset {
  width: 100%;
  padding: 0 16px;
  position: relative;
  &-double {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 3fr; }
  &-double1 {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr; }
  &-double2 {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr; }
  &-double3 {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 5fr; }
  &-stroke {
    width: 15px !important;
    height: 1px !important;
    right: 0.8rem;
    top: 20px;
    border: 1.5px solid rgba($general-text-color, 0.65); }
  &-stroke2 {
    width: 13px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    left: 56.5%;
    bottom: 60%; }
  &-stroke3 {
    width: 15px !important;
    height: 1px !important;
    right: 0.8rem;
    top: 20px;
    border: 1.5px solid rgba($general-text-color, 0.65); }
  &-stroke4 {
    width: 15px !important;
    height: 1px !important;
    right: 2rem;
    top: 20px;
    border: 1.5px solid rgba($general-text-color, 0.65); } }

.title {
  color: $general-text-color;
  font-size: 16px;
  justify-content: center;
  text-align: justify; }


.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }

.createAtFields {
  display: flex;
  margin-right: -1.4rem;
  margin-left: -1.8rem; }

.updatedAtFields {
  display: flex;
  margin-right: 2.2rem;
  margin-left: -2.3rem; }

.endDateFields {
  display: flex;
  margin-right: 2.8rem;
  margin-left: -2.8rem; }
.costFields {
  display: flex;
  margin-right: -1rem !important;
  margin-left: -0.4rem; }

.doubleLabel {
  display: inline-block;
  padding-top: 15px;
  font-size: 13px !important;
  margin-left: 18px; }

.doubleLabel2 {
  display: inline-block;
  padding-top: 15px;
  font-size: 13px !important;
  margin-left: 14px; }

.doubleLabel3 {
  display: inline-block;
  padding-top: 15px;
  font-size: 13px !important;
  margin-left: 6px; }
.doubleLabel4 {
  display: inline-block;
  padding-top: 15px;
  font-size: 13px !important;
  margin-left: 17px; }

.fields2 {
  display: grid;
  grid-template-columns: repeat(3, 2fr); }


@media (min-width: 992px) and  ( max-width: 1600px  ) {
  .createAtFields {
    margin-left: -1.1rem !important;
    margin-right: -0.6rem !important; }

  .updatedAtFields {
    margin-left: -1.8rem !important;
    margin-right: 0.4rem !important; }

  .fieldset {
    &-stroke {
      right: 1.7rem;
      top: 20px; }
    &-stroke3 {
      right: 1rem;
      top: 20px; }
    &-stroke4 {
      right: 1.7rem;
      top: 20px; } }

  .doubleLabel2 {
    margin-top: -10px;
    width: 80px; }
  .doubleLabel3 {
    width: 60px;
    margin-top: -10px; }


  .endDateFields {
    margin-left: -2.4rem !important;
    margin-right: 2rem !important; }
  .costFields {
    margin-left: -0.2rem !important;
    margin-right: -1rem !important; }

  .formDateInput {
    max-width: 110% !important; }
  .formToDateInput {
    margin-left: -15px !important; }

  .calenderIconFrom {
    position: absolute;
    right: 2px !important; }

  .calenderIconToStartDate {
    position: absolute;
    right: 6px !important;
    top: 10px;
    pointer-events: none;
    background: white;
    & path {
      fill: red; } }

  .calenderIconToEndDate {
    position: absolute;
    right: -17px;
    top: 10px; } }






@media  ( max-width: 991px ) {
  .fieldset {
    &-stroke {
      left: 3.5rem;
      top: 20px; }
    &-stroke3 {
      left: 3.5rem !important;
      top: 20px; }
    &-stroke4 {
      left: 2.45rem !important;
      top: 20px; }
    &-double1 {
      position: relative;
      display: table-row;
      margin-top: -10px; }
    &-double2 {
      position: relative;
      display: table-row;
      margin-top: -10px; }
    &-double3 {
      position: relative;
      display: table-row;
      margin-top: -10px; } }

  .createdAtFields {
    margin-left: 0.3rem !important;
    margin-right: -9.5rem !important; }
  .updatedAtFields {
    margin-left: 2.2rem !important;
    margin-top: 2% !important;
    margin-right: 2rem !important; }

  .formToDateInput {
    margin-left: 45% !important; }

  .endDateFields {
    margin-left: 8% !important;
    margin-top: 2% !important; }
  .costFields {
    margin-left: 11% !important;
    margin-top: 2% !important; }

  .doubleLabel {
    padding-top: 1px !important;
    margin-left: 2.2rem;
    line-height: 1.5 !important; }
  .doubleLabel2 {
    padding-top: 10px !important;
    margin-left: 2.2rem;
    line-height: 1.5 !important; }
  .fields2 {
    display: table-row; }
  .doubleLabel3 {
    padding-top: 10px !important;
    margin-left: 2.2rem;
    line-height: 1.5 !important; }
  .doubleLabel4 {
    padding-top: 10px !important;
    margin-left: 2.2rem;
    line-height: 1.5 !important; }
  .daysField {
    max-width: 150%;
    width: 120%;
    margin-top: 1.8rem;
    margin-left: -87px !important;
    flex: 1 !important; }

  .costToInput {
    margin-left: 60px;
    max-width: 88% !important; }
  .costFromInput {
    min-width: 115% !important;
    margin-left: -10px; }

  .dollarIconTo {
    position: absolute;
    top: 12px;
    left: 80px;
    height: 15px;
    color: $primary-color; }
  .dollarIcon {
    position: absolute;
    top: 12px;
    left: 10px;
    height: 15px;
    color: $primary-color; }

  .toDateInput {
    margin-left: 50% !important;
    max-width: 127.5% !important;
    min-width: 127.5% !important; }

  .calenderIconFrom {
    position: absolute;
    right: -23px !important; }

  .calenderIconToStartDate {
    position: absolute;
    right: -110px !important;
    top: 10px;
    pointer-events: none;
    background: white;
    & path {
      fill: red; } }

  .calenderIconToEndDate {
    position: absolute;
    right: -127px !important;
    top: 10px; } }


@media  ( max-width: 1450px ) {
  .fieldset {
    &-stroke {
      width: 15px;
      border: 1.5px solid rgba($general-text-color, 0.65); }
    &-stroke3 {
      right: 0.8rem !important;
      width: 15px;
      border: 1.5px solid rgba($general-text-color, 0.65); }
    &-stroke4 {
      width: 15px;
      border: 1.5px solid rgba($general-text-color, 0.65);
      right: 1.5rem !important; } } }

@media  (min-width:1601px) and (max-width: 1860px ) {
  .updatedAtFields {
    margin-left: -2rem !important;
    margin-right: 0.4rem !important; }
  .endDateFields {
    margin-left: -2.3rem !important;
    margin-right: 1rem !important; }
  .costFields {
    margin-left: -0.1rem !important;
    margin-right: -1rem !important; }
  .formDateInput {
    max-width: 110% !important; }
  .formToDateInput {
    margin-left: 1px !important; }
  .fieldset {
    &-stroke {
      right: 1.2rem;
      top: 20px; }
    &-stroke3 {
      right: 1.2rem;
      top: 20px; } }
  .doubleLabel2 {
    width: 70px;
    line-height: 1.1;
    margin-top: -10px; }
  .doubleLabel3 {
    width: 70px;
    line-height: 1.1;
    margin-top: -10px; } }

@media  (min-width: 992px) and (max-width: 1220px ) {
  .updatedAtFields {
    margin-left: -1rem !important;
    margin-right: -0.1rem !important; }
  .endDateFields {
    margin-left: -1.5rem !important;
    margin-right: 2rem !important; }
  .costFields {
    margin-left: 0.1rem !important;
    margin-right: -1rem !important; }
  .doubleLabel4 {
    width: 50% !important;
    line-height: 1.5 !important;
    margin-top: -15px !important; }
  .doubleLabel2 {
    line-height: 1.5 !important;
    margin-top: -15px !important; }
  .doubleLabel3 {
    line-height: 1.5 !important;
    margin-top: -15px !important; }
  .doubleLabel {
    line-height: 1.5 !important;
    margin-top: -15px !important; }

  .fieldset {
    &-stroke {
      right: 1.4rem;
      top: 20px; }
    &-stroke3 {
      right: 0.5rem !important;
      top: 20px; }
    &-stroke4 {
      right: 1.2rem !important; } } }

@media (min-width: 992px) and ( max-width: 1098px  ) {
  .fieldset {
    &-stroke {
      right: 1.4rem;
      top: 20px; }
    &-stroke3 {
      right: 1.4rem;
      top: 20px; }
    &-stroke4 {
      right: 1.4rem;
      top: 20px; } } }


@media  (min-width: 1221px) and (max-width: 1390px ) {
  .updatedAtFields {
    margin-left: -1.5rem !important;
    margin-right: 0.1rem !important; }
  .endDateFields {
    margin-left: -1.8rem !important;
    margin-right: -0.05rem !important; }
  .costFields {
    margin-left: -0.1rem !important;
    margin-right: -2rem !important; }
  .doubleLabel4 {
    width: 50% !important;
    line-height: 1.1 !important;
    margin-top: -5px !important; }
  .fieldset {
    &-stroke {
      right: 1.55rem;
      top: 20px; }
    &-stroke3 {
      right: 1.55rem;
      top: 20px; }
    &-stroke4 {
      width: 10px !important; } } }


@media  ( max-width: 694px ) {
  .daysField {
    max-width: 106.5% !important; } }

@media (min-width: 980px) and ( max-width: 991px ) {
  .daysField {
    min-width: 165% !important; } }

@media (min-width: 960px) and ( max-width: 979px ) {
  .daysField {
    max-width: 165% !important;
    min-width: 165% !important;
    width: auto !important; } }

@media (min-width: 930px) and ( max-width: 960px ) {
  .daysField {
    max-width: 162.5% !important;
    min-width: 161.5% !important;
    width: auto !important; } }

@media (min-width: 900px) and ( max-width: 929px ) {
  .daysField {
    max-width: 156.5% !important;
    min-width: 155.5% !important;
    width: auto !important; } }

@media (width: 712px) {
  .daysField {
    max-width: 112.5% !important;
    width: auto !important; } }

@media (width: 800px) {
  .daysField {
    min-width: 130.2% !important;
    max-width: 130.2% !important; } }

@media (width: 768px) {
  .daysField {
    min-width: 123.8% !important;
    max-width: 123.8% !important; } }

@media (width: 820px) {
  .daysField {
    min-width: 134.5% !important;
    max-width: 134.5% !important; } }

@media (width: 912px) {
  .daysField {
    min-width: 153% !important;
    max-width: 153% !important; } }

@media (width: 853px) {
  .daysField {
    min-width: 141% !important;
    max-width: 141% !important; } }
.daysField {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  margin-left: 12px;
  width: 125.4% !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }


.formDropdownInput {
  width: 98.5% !important;
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  height: 40px;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }


.formDateInput {
  width: 122% !important;
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }


.formToDateInput {
  width: 122% !important;
  box-shadow: none !important;
  margin-left: 10px;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }

.costFromInput {
  width: 98.5% !important;
  text-align: center !important;
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }

.costToInput {
  width: 98.5% !important;
  text-align: center !important;
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }

.toDateInput {
  width: 122% !important;
  box-shadow: none !important;
  margin-left: 10px;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }

.calenderIconFrom {
  position: absolute;
  right: -7px;
  top: 10px;
  pointer-events: none;
  background: white;
  & path {
    fill: $primary-color; } }

.calenderIconToStartDate {
  position: absolute;
  right: -17px;
  top: 10px;
  pointer-events: none;
  background: white;
  & path {
    fill: $primary-color; } }

.calenderIconToEndDate {
  position: absolute;
  right: -17px;
  top: 10px;
  pointer-events: none;
  background: white;
  & path {
    fill: $primary-color; } }

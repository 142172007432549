@import 'src/styles/variables';

.content {
  font-size: 14px;
  line-height: 19.5px;
  text-align: center;
  padding-top: 14px;
  padding: 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.button {
  border-radius: 8px;
  min-width: 100px;
  font-size: 15px;
  &-bg-light {
    background-color: rgba($general-text-color, 0.45) !important; }
  &-bg-danger {
    background-color: $red !important; }
  &-bg-primary {
    background-color: $primary-color !important; } }
.title {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  text-align: center !important;
  justify-content: center !important; }
.img {
  height: 22.5rem !important;
  width: 35srem !important;
  border: none !important; }

.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; }

.modalContent {
  width: 26.5%;
  min-height: 200px;
  display: flex !important;
  justify-content: center !important;
  margin-left: -0.8rem !important; }

@import 'src/styles/variables';

.borderPrimary {
  padding: 30px 25px 15px 25px !important;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 8px !important;
  margin: 7px !important;
  height: 96% !important;
  box-shadow: 0px 1px 2px 0px #606C801A; }

.cardTitle {
  font-size: 14px !important;
  font-weight: 550 !important;
  color: rgba(36, 46, 66, 0.85) !important;
  margin-top: -15px !important;
  margin-bottom: 0px !important; }
.title {
  font-size: 16px;
  font-weight: 550;
  color: #242E42D9;
  align-content: center !important; }

.bottomButton {
  display: flex;
  justify-content: space-between;
  padding-left: 8% !important;
  padding-right: 5%;
  color: $default-button-txt; }

.button {
  min-width: 0px !important;
  background: #242E42D9 !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #FFFFFF !important; }

.secondaryText {
  color: #242E42D9 !important;
  font-size: 14px !important;
  font-weight: 550 !important;
  margin-right: 0.4rem !important; }

.className {
  border: 0px solid rgba(216, 219, 234, 0.27) !important;
  text-align: right !important;
  & > button {
    background-color: white !important;
    border: 1px solid rgba($snuff, 0.27);
    box-shadow: none !important;
    height: 40px;
    &:disabled {
      background-color: #e9ecef !important;
      opacity: 1; }
    & > span {
      opacity: 0.8 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }
  & > div {
    border: 1px solid rgba($snuff, 0.27) !important;
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 190px !important;
    left: -8px !important;
    & > button {
      padding: 12px 12px;
      & > span {
        display: block !important;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
    & > button:hover {
      background-color: $primary-color;
      & > span {
        color: white;
        opacity: 1; } } }
  &-sm {
    & > button {
      padding: 7.5px 12px;
      & > span {
        font-size: 12px; } }
    & > div > button > span {
      font-size: 12px; } }
  &-md {
    & > button {
      padding: 10px 14px;
      & > span {
        font-size: 13px; } }
    & > div > button > span {
      font-size: 13px; } } }

.lotContainer {
  margin-left: 35px !important;
  font-size: 14px !important; }

.lotContainer1 {
  color: #242E42D9 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 0.4rem !important; }

.lotContainer2 {
  color: #3A9CED !important;
  font-weight: 600 !important; }

.dateDropdown {
  border: 1px solid rgba($snuff, 0.27) !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  & > button:hover {
    background-color: $primary-color;
    & > span {
      color: white; } }
  & > div {
    border: 1px solid rgba($snuff, 0.27) !important;
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 30px !important;
    & > button {
      padding: 12px 12px;
      & > span {
        display: block !important;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
    & > button:hover {
      background-color: $primary-color;
      & > span {
        color: white;
        opacity: 1; } } } }

.dateDropdown1 {
  height: 30px !important; }

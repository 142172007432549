.google_maps_container {
  width: 100%;
  height: 500px;
  & > div {
    position: relative !important; } }

#searchBox {
  top: 38px !important;
  left: 0px !important;
  margin-left: 30px;
  width: 55%; }

@import 'src/styles/variables';

.featureBtn {
  width: 240px !important;
  font-size: 10px !important;
  background-color: white !important;
  color: black !important;
  margin-bottom: 10% !important;
  margin-left: 5% !important;
  height: 38px !important;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  transition: background 0.9s ease-in-out; }

.closeButton {
  width: '100% !important';
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #303030  !important;
  border-radius: 25px  !important;
  text-align: center;
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 12px !important;
  text-transform: capitalize;
  font-size: 12px !important;
  background-color: #F5F5F5  !important;
  border-color: #F5F5F5  !important;
  &:hover {
    background-color: #F5F5F5;
    color: #303030; } }

.title {
  position: sticky;
  top: 0px !important;
  padding-top: 10px; }

.subtitle {
  position: sticky;
  top: 0px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 50px;

  text-align: center;
  letter-spacing: 1.2px;

  color: #303030; }

@media (max-width: 726px) {
  .subtitle {
    font-size: 30px; } }
@media (max-width: 426px) {
  .subtitle {
    font-size: 24px; } }

.text {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;

  text-align: center;

  color: grey; }


.container {
  margin-top: -100px; }

.backdropC {
  width: 100% !important;
  height: 100% !important; }

.appModal {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: left;
  color: $general-text-color;
  border-radius: 8px !important; }

@media (max-width: 575px) {
  .modal {
    display: flex !important;
    justify-content: center !important; } }

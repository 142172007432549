@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important;
  &-active {
    margin-top: 15px; } }
.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em; }
.btnFilter {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px;
  color: $default-button-txt; }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); }
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.5px solid rgba($general-text-color, 0.6); }
  &-success {
    background: $green; }
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.1); } }
.badge {
  font-size: 12px !important;
  border-radius: 4px;
  height: 19px;
  max-width: 18px;
  min-width: 20px;
  background-color: #369636;
  &-primary-background {
    background: $green !important; }
  &-orange-background {
    background: $orange !important; }
  & div:first-child {
    padding: 0 6px; } }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }

.button {
  border-radius: 8px;
  min-width: 40px;
  min-height: 30px;
  color: $default-button-txt;
  height: 40px;
  background: $primary-btn-color;
  font-size: 15px;
  margin-left: 15px;
  font-weight: bolder;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg;
    opacity: 0.6; } }


.pageHeader {
  color: $page-header-txt; }

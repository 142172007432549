@import 'src/styles/variables';

.calenderIcon {
  position: absolute;
  right: 10px;
  z-index: 1;
  pointer-events: none;
  & path {
    fill: $primary-color; } }
.input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: absolute;
  width: 100%; }

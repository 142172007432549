@import 'src/styles/variables';

.colorPickerPage {
    text-align: center; }

.title {
  font-size: 24px;
  margin-top: 25px;
  color: $page-header-txt; }

.colorStrip {
    margin-bottom: 10px;
    margin-top: 20px;
    border-radius: 5px;
    border: none !important;
    cursor: pointer;
    transition: background-color 0.1s ease; }

.picker {
  border: none !important;
  border-radius: 1px;
  margin: 10px !important;
  .color-block-inner {
    border: none !important; } }

.pickerBtn {
  border: 1px solid rgba($general-text-color, 0.1) !important;
  border-radius: 3px !important;
  outline: none !important;
  display: flex;
  text-align: left !important;
  width: 100% !important;
  transition: 0.3s ease !important; }

.label {
  font-size: 13px;
  color: rgba($general-text-color, 0.7);
  line-height: 1.3;
  font-weight: 500 !important;
  margin-top: 10px;
  display: flex !important;
  justify-content: flex-start !important; }


.header {
  display: flex;
  align-items: center;
  height: 75px;
  padding: 20px; }

.input {
  max-width: 100% !important;
  height: 35px; }

.required {
 color: $danger-color;
 margin-left: 5px !important; }

.note {
  font-size: 14px;
  margin: 0 !important;
  padding: 0 !important;
  color: rgba($general-text-color, 0.7) !important; }


.button {
  border-radius: 8px;
  font-size: 15px;
  color: $default-button-txt;
  background: $save-button-bg;
  font-weight: 600 !important;
  font-family: Roboto, sans-serif !important;
  padding: 20px;
  width: 80px;
  &-disabled {
    background: rgba($general-text-color, 0.5); } }

.questionMark {
  height: 13px !important;
  width: 13px !important;
  path {
    fill: #3a7ca5 !important; } }


.nextArrow {
  height: 14px !important;
  width: 14px !important;
  margin-bottom: 5px;
  margin-left: -2px;
  path {
    stroke: black !important; } }


.modal {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }

.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }

.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }


.description {
  font-size: 13px;
  font-family: Roboto, sans-serif !important;
  margin-top: 25px; }



.themeErrorWrapper {
  font-size: 13px !important; }

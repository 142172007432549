@import 'src/styles/variables';

.title {
  font-size: 16px !important;
  text-align: center; }
.form {
  padding: 0 20px 20px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  height: 40px !important;
  text-align: left !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.input1 {
  box-shadow: none !important;
  border-radius: 8px !important;
  height: 40px !important;
  text-align: left !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.input2 {
  box-shadow: none !important;
  border: 1px solid #D8DBEA;
  border-radius: 8px !important;
  text-align: left !important;
  &:focus {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; }
  &:hover {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.inputLabel1 {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-right: 1rem !important; }
.inputLabel2 {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-right: 23px !important; }
.cancelButton {
  background-color: $cancel-button-bg !important;
  color: $default-button-txt;
  opacity: 0.4;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px; }
.submitButton {
  background: $primary-btn-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px; }
.pending-color {
  color: rgba($general-text-color, 0.45); }
.cancelled-color {
  color: $red; }
.approved-color {
  color: $green; }
.under_review-color {
  color: $orange; }
.rejected-color {
  color: $red; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.submitButton {
  background: $save-button-bg;
  color: $default-button-txt;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-left: 20px; }
.imgThumb {
  border-radius: 8px !important;
  width: 230px;
  height: 140px;
  object-fit: contain;
  background: #dddddd;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.image {
  pointer-events: none;
  border: 1px solid;
  background: #fff;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.dragDropLabel {
  border-radius: 8px !important;
  max-width: 79.5% !important;
  font-weight: 1000px;
  height: 120px !important;
  position: relative !important;
  border: 1px solid #ced4da !important;
  margin-top: -2rem !important;
  font-size: 13px !important;
  margin-bottom: 1.5rem !important; }
.uploadField {
  margin-left: 148px; }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.toggleIcon {
  margin-left: 14px !important;
  margin-top: -23px !important; }
.toggleLable {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.toggleStatus {
  font-weight: inherit;
  margin-left: 80px !important;
  margin-top: -23px !important; }
.img {
  height: 90px !important;
  width: 160px !important;
  border: none !important;
  margin-top: -0.6rem !important;
  margin-right: 18rem !important;
  margin-left: -0.4rem !important;
  border-radius: 5px !important; }

.fileName {
  font-size: 13px !important;
  margin-left: 3rem !important; }

.uploadText {
  margin-top: -5.8rem !important;
  font-size: 13px !important;
  margin-left: 10rem !important; }
.cloudIcon {
  margin-top: -12rem !important;
  margin-left: 9rem !important;
  height: 30px !important; }
.background {
  &-danger {
    color: red  !important; }
  &-primary {
    color: blue  !important; } }

.iconInput {
  width: 75% !important;
  margin-bottom: -2rem !important;
  margin-top: -0.2rem !important;
  padding-left: 16px !important; }

.checkDetailsLink {
  font-size: 13px !important;
  margin-left: 20rem !important;
  margin-top: 3.5rem !important;
  color: #3A9CED !important; }

.checkDetailsLink1 {
  font-size: 13px !important;
  margin-left: 20rem !important;
  margin-top: 3.5rem !important;
  color: #3A9CED !important; }

.fieldset {
  width: 50%;
  // padding: 0 16px
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }

.doubleFileds {
  margin-left: 0px !important;
  margin-right: 0px !important; }

@media (max-width: 991px) {
  .doubleFileds {
    padding: 0 16px !important;
    margin-left: -15px !important;
    margin-right: -15px !important; }
  .fieldset {
    padding-right: 10px; } }

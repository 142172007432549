@import 'src/styles/variables';

.title {
  font-size: 13px !important;
  text-align: left;
  font-weight: bold !important;
  margin-left: 50px !important; }
.form {
  padding: 10px 20px 20px 30px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.button {
  border-radius: 8px;
  min-width: 50px;
  cursor: pointer;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }

.subject {
  text-align: left !important; }

.border {
  border: 1px solid #3A9CED !important; }

.content {
  font-size: 13px !important;
  text-align: left;
  margin-left: 50px !important; }

.icon {
  margin-left: 50px !important; }

.inputField {
  width: 70%;
  height: 30px; }

.inputSignatureField {
  width: 121%; }

.ul {
  list-style: none;
  max-height: 200px;
  height: 130px;
  margin: 0;
  overflow: auto;
  padding: 0;
  text-indent: 10px; }

.li {
  line-height: 100%;
  background: #dfdfdf;
  list-style: none;
  height: 30px;
  margin: 0;
  overflow: auto;
  padding: 0;
  text-indent: 10px; }
.li:active {
  background-color: #3A9CED !important; }
.li.selected {
  background-color: $primary-color; }

.listContainer {
  width: 80%; }

.toolbarClassName {
  background: #dfdfdf !important;
  border: 1px solid #a3a3a3 !important;
  margin-bottom: 0px !important; }

.editorClassName {
  border: 1px solid #a3a3a3 !important;
  border-top: none !important;
  min-height: 20rem !important;
  max-height: 80rem !important;
  padding: 1rem !important; }

.addButton {
  border: 1px solid #a3a3a3 !important;
  padding: 10px; }

.header {
  border-bottom: none !important;
  background: #ebf8ff  !important; }

.footer {
  border-top: none !important;
  background: #ebf8ff  !important;
  font-size: 15px !important; }

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px; }


.leftSection {
  width: auto;
  flex-direction: column; }

.rightSection {
  flex-direction: column; }
.event_name {
  border: 0.8px solid;
  border-radius: 10px;
  padding: 8px;
  width: fit-content; }

.optionStyle {
  z-index: 100;
  overflow-y: scroll; }

.buttonComplete {
  border-radius: 8px;
  min-width: 100px;
  background-color: green !important;
  &-bg-secondary {
    background-color: green !important; } }

.layoutSelectedOption {
  background-color: rgba($grey-dark , 0.5) !important;
  cursor: auto !important;
  &:hover > span {
    color: $general-text-color !important;
    opacity: 0.6 !important; } }

.dropdown {
  & > button {
    background-color: white !important;
    border: none !important;
    box-shadow: none !important;
    height: 25px;
    margin-top: 6px;
    width: 120px !important;
    &:disabled {
      background-color: #e9ecef !important;
      opacity: 1; }
    & > span {
      opacity: 1 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }
  & > div {
    border: 1px solid rgba($snuff, 0.27) !important;
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 7.5rem !important;
    & > button {
      padding: 8px 12px;
      height: 30px;
      & > span {
        display: block !important;
        font-weight: 300;
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
    & > button:hover {
      background-color: #ededed;
      & > span {
        color: black;
        opacity: 1; } } }
  &-sm {
    & > button {
      padding: 7.5px 12px;
      & > span {
        font-size: 12px; } }
    & > div > button > span {
      font-size: 12px; } }
  &-md {
    & > button {
      padding: 10px 14px;
      & > span {
        font-size: 13px; } }
    & > div > button > span {
      font-size: 13px; } } }

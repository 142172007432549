@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 220px) !important; }
.section {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  width: 100%;
  height: 190px;
  font-size: 17px;
  color: rgba($general-text-color, 0.75);
  & button {
    font-size: 16px !important; } }
.button {
  border-radius: 8px;
  padding: 10px;
  color: $default-button-txt;
  background: $primary-btn-color; }
.background {
  &-primary {
    background: $primary-color !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); }
  &-danger {
    background: $red; }
  &-disabled {
    background: rgba(#242E42, 0.6); } }
.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }
.revokedText {
  width: 64px;
  text-align: center; }
.actions {
  & > div {
    & > span {
      transition: display 0.35s; } } }
.revokeButton {
  min-width: fit-content;
  border-radius: 15px;
  padding: 12px; }

.title {
  color: $page-header-txt; }

@import 'src/styles/variables';

.title {
  font-size: 18px !important; }
.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & > div {
    margin-left: 10px; } }
.button {
  border-radius: 8px;
  min-width: 100px;
  color: $default-button-txt;
  background: $save-button-bg;
  &-bg-secondary {
    background-color: $cancel-button-bg !important;
    opacity: 0.6; } }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 220px) !important;
  width: 100% !important; }
.tableActive {
  margin-top: 15px;
  margin-right: 0 !important; }

.resetButton {
  background-color: #242E42 !important;
  opacity: 0.6 !important;
  border-radius: 11px;
  min-width: 100px;
  font-weight: 600; }
.applyButton {
  display: flex !important;
  justify-content: center !important;
  min-width: 95px !important;
  height: 30px !important;
  border-radius: 15px;
  padding: 12px; }
.searchButton {
  border-radius: 11px;
  min-width: 100px;
  margin-right: 10px;
  font-weight: 600; }
.form {
  width: 100%;
  padding: 15px 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.appliedBg {
  background: $disabled-bg-color; }
.primaryBg {
  background: $primary-btn-color; }
.activeFilterTab {
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1); }
.activeFilterIcon {
  & path {
    fill: rgba($general-text-color, 0.5) !important; } }
.collapseSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }
.navButton {
  border-radius: 8px !important;
  background-color: $primary-btn-color !important; }
.revokedText {
  width: 64px;
  text-align: center; }
.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }
.actions {
  & > div {
    & > span {
      transition: display 0.35s; } } }
.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); }
  &-primary {
    background: $primary-color; } }


.approveIcon {
  width: 14px;
  height: 14px;
  path {
    stroke: $green !important; } }

.btnAppliedText {
  font-size: 14px !important;
  color: $default-button-txt;
  margin-left: 5px; }

.btnApplyText {
  font-size: 14px !important;
  color: $default-button-txt; }

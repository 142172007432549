@import 'src/styles/variables';

.datePicker {
  & > div:first-child {
    display: none !important; }
  & > div:nth-child(2) {
    & > div:first-child {
      display: none !important; } } }

.dateModal {
  max-width: none !important;
  justify-content: center;
  & > div:first-child {
    width: auto;
    border: 8px;
    border-top: 1px solid rgba($snuff, 0.27);
    box-shadow: 0 0 6px rgba($azure, 0.1); } }

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  font-size: 18px; }

.closeBtn {
  position: absolute;
  right: 40px;
  cursor: pointer; }

.displayDateWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px; }
.displayDate {
  font-weight: 300;
  font-size: 14px;
  opacity: 1; }

.dateModalContent {
  border-top: 1px solid rgba($snuff, 0.66);
  border-bottom: 1px solid rgba($snuff, 0.66); }

.rangeBtnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px !important;
  padding: 10px 0 !important;
  border-right: 1px solid rgba($snuff, 0.66); }
.rangeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  margin-bottom: 10px;
  padding: 10px 0;
  border-radius: 4px;
  border: 1px solid rgba($snuff, 0.66);
  outline: 0 !important;
  background-color: white;
  & span {
    padding: 0;
    text-align: center;
    opacity: .7; }
  &.selected {
    background-color: $primary-color;
    & span {
      color: white !important;
      opacity: 1; } }
  &.disabled {
    cursor: default; } }

.datePickerWrapper {
  padding: 30px 23px 30px 23px !important;
  border-top: 1px solid rgba($snuff, 0.66); }

.btnWrapper {
  padding: 15px 55px 25px 40px !important;
  & > button {
    min-width: 160px; }
  & > button:first-child {
    margin-right: 10px; } }

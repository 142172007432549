@import 'src/styles/variables';
.button {
  font-weight: 600;
  width: 240px !important;
  font-size: 15px !important;
  background-color: white !important;
  color: black !important;
  margin-bottom: 10% !important;
  margin-left: 10% !important;
  position: inherit;
  display: flex;
  border: 2px solid #777 !important;
  font-weight: 500; }

.orContainer {
  display: flex;
  align-items: center;
  color: #777; }  // Adjust the color as needed


.orLine {
  flex: 1;
  height: 1px;
  background-color: #000000; }  // Adjust the color as needed


.orText {
  padding: 0 10px;
  font-size: 10px;
  font-weight: 550;
  color: black; }

.btn {
  font-size: 10px !important;
  font-weight: 500px !important; }


.backdropC {
  width: 100% !important;
  height: 100% !important; }

.appModal {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }
.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }


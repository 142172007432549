@import 'src/styles/variables';

.cancelButton {
  background: rgba($general-text-color, 0.45);
  margin-right: 30px;
  font-weight: inherit;
  font-size: 16px;
  min-width: 100px; }
.submitButton {
  background: $red;
  font-weight: inherit;
  font-size: 16px;
  min-width: 100px;
  letter-spacing: inherit; }
.content {
  font-size: 15px;
  letter-spacing: inherit !important;
  line-height: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  justify-content: center !important;
  text-align: justify !important; }

.title {
  font-size: 18px !important; }

.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }

@import 'src/styles/variables';

.configLabelWrapper {
  margin-bottom: 10px; }
.configLabel {
  margin-right: 6px;
  font-weight: 300; }
.configDatePicker {
  width: 100%;
  height: 35px !important;
  padding: 8px 14px 8px 17px;
  box-shadow: none; }
.configNote {
  margin-top: 8px;
  font-size: 10px !important; }
.configSplitter {
  margin: 0 5px; }


@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important; }
.tableActive {
  margin-top: 24px; }
.formInput {
  border-radius: 8px !important;
  height: 40px;
  &:focus {
    box-shadow: none;
    border: 1px solid #ced4da; }
  & > button {
    border: unset !important; } }
.formLabel {
  font-weight: 600;
  margin-right: 10px; }
.searchForm {
  border: 1px solid $primary-color;
  border-radius: 8px;
  > input {
    border: unset;
    border-radius: 8px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    padding-left: 32px; } }
button.headerButton {
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  min-width: fit-content; }
.collapseSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.toolbarButton {
  margin-left: 20px;
  min-width: 43px;
  font-size: 16px;
  border-radius: 8px; }
.activeFilterTab {
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1) !important; }
.activeFilterIcon {
  & path {
    fill: rgba($general-text-color, 0.5);
    stroke: rgba(36, 46, 66, 0.5); } }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.actions {
  & > div {
    & > span {
      transition: display 0.75s;
      padding: 5px 10px;
      border-radius: 15px; } } }
.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); } }

@import 'src/styles/variables';

.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $cancel-button-bg !important;
  color: $default-button-txt;
  opacity: 0.4; }
.submitButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $red !important; }
.content {
  text-align: left;
  font-size: 14px; }
.body {
  padding: 10px 40px 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.button {
  border-radius: 8px;
  min-width: 100px;
  font-size: 15px;
  color: $default-button-txt;
  &-bg-light {
    background-color: $cancel-button-bg !important;
    opacity: 0.6; }
  &-bg-danger {
    background-color: $danger-button-bg !important; }
  &-bg-primary {
    background-color: $primary-btn-color !important; } }

@import 'src/styles/variables';

.modalTitle {
  font-size: 18px !important; }
.violationModal {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }
.modalContent {
  color: rgba($general-text-color, 0.9);
  margin: auto; }
.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }
.btn {
  border-radius: 8px;
  font-size: 16px;
  color: $default-button-txt;
  &-primary {
    background: $primary-btn-color; }
  &-light {
    background: $cancel-button-bg;
    opacity: 0.6; } }


@import 'src/styles/variables';

.toastHeader {
  button {
    position: absolute;
    top: 7px;
    right: 15px; } }
.toast-notice {
  background: $orange;
  color: white; }
.toast-danger {
  background: $red;
  color: white; }
.toast-primary {
  background: $primary-color;
  color: white; }

@import 'src/styles/variables';

.featureBtn {
  font-weight: 600;
  width: 240px !important;
  font-size: 15px !important;
  background-color: white !important;
  color: black !important;
  margin-bottom: 10% !important;
  margin-left: 5% !important;
  border: 2px solid #777 !important;
  height: 38px !important;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  transition: background 0.9s ease-in-out; }

@media (min-width: 1920px ) {
  .featureBtn {
    margin-top: -25% !important; } }

@media (min-width: 1440px) and (max-width: 1600px) {
  .featureBtn {
    margin-top: -30% !important; } }

@media (max-width: 1280px ) {
  .featureBtn {
    margin-top: -25% !important; } }

.featureBtn:hover {
  background: $primary-color-subscriber !important; /* Change this to the desired hover background color */; }

.searchIcon {
  margin-left: -38%; }

.orContainer {
  display: flex;
  align-items: center;
  color: #777; }  // Adjust the color as needed


.orLine {
  flex: 1;
  height: 1px;
  background-color: #000000; }  // Adjust the color as needed


.orText {
  padding: 0 10px;
  font-size: 10px;
  font-weight: 550;
  color: black; }

.btn {
  font-size: 10px !important;
  font-weight: 500px !important; }


@import 'src/styles/variables';

.appModal {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }
.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }

.horizontalLine {
  border: 1px solid;
  display: flex;
  height: 0rem;
  margin-top: 0.4rem; }

.horizontalLine1 {
  border: 1px solid;
  width: 100%;
  margin-top: 2rem;
  opacity: 0.5; }

.name1 {
  font-size: 13px;
  font-weight: bold; }

.name2 {
  font-size: 13px;
  font-weight: bold; }

@media (min-width: 992px) and (max-width: 1255px) {
  .name1 {
    margin-top: 0.3rem !important;
    align-content: center !important;
    padding-left: 1.8rem !important; } }

@media (min-width: 992px) and (max-width: 1255px) {
  .name2 {
    margin-top: 0.3rem !important;
    align-content: center !important;
    padding-left: 3rem !important; } }


.passwordField {
  border-radius: 0.3rem !important; }

.googleSignInDiv {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important; }

.googleSignInBtn {
  border-radius: 5px;
  height: fit-content !important;
  border: none;
  border: 1px solid rgba(grey, 0.1) !important;
  margin-left: 10px;

  &:hover {
    border: 1px solid rgba($primary-color, 1) !important; } }

.fbBtn {
  background: #2374E1;
  border-radius: 5px !important;
  height: 40px !important;
  min-width: 40px !important;
  margin-left: 10px;
  display: block;
  justify-content: center !important;
  outline: none !important; }

.fbBtnSection {
  display: flex;
  justify-content: flex-start !important;
  align-items: center  !important;
  margin: 0 !important;
  padding: 0 !important; }

.fbIcon {
  color: $primary-color;
  height: 30px;
  width: 40px; }

.registerSection {
  display: flex !important;
  margin-top: 10px; }

.registerQ {
  font-size: 13px !important; }

.registerLink {
  font-size: 13px !important;
  color: $primary-color !important;
  text-decoration: underline !important;
  font-weight: 600 !important; }

.socialBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important; }

.validInputs {
  color: $default-button-txt !important;
  background-color: $primary-btn-color-subscriber !important; }

.invalidInputs {
  color: white !important;
  background-color: $disabled-bg-color !important; }

.button {
  color: $default-button-txt-subscriber;
  background: $primary-btn-color-subscriber !important; }
.disabledBg {
  background: $cancel-button-bg-subscriber !important;
  opacity: 0.45 !important;
  color: $default-button-txt-subscriber !important; }

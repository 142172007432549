@import 'src/styles/variables';

.title {
  font-weight: 600 !important;
  color: $default-text-subscriber !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }
.form {
  padding: 10px 50px 20px; }
.fieldset {
  width: 50%;
  padding: 0 16px;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.category {
  font-size: 15px; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; }
  & button {
    height: 40px;
    border: unset; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.primary-color {
  color: $primary-color; }
.cancelled-color {
  color: rgba($general-text-color, 0.45); }
.success-color {
  color: $green; }
.revoked-color {
  color: $red; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  path {
    fill: $primary-color-subscriber !important; } }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  color: $default-button-txt-subscriber !important;
  &-sm {
    min-width: 120px; }
  &-bg-orange {
    background: $orange; }
  &-bg-secondary {
    background: $cancel-button-bg-subscriber !important;
    opacity: 0.45 !important; } }

.subdetailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 13.6px !important; }

.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 10%; }




.cardPreview {
  flex: 1;
  margin-right: 50px;
  margin-top: -1rem;
  margin-left: 4.6rem;
  width: 100px !important; }

.cardInputs {
  flex: 1;
  margin-left: 4rem; /* Adjust spacing between card preview and inputs */;
  margin-right: 2rem;
  margin-top: 5%;
  max-width: 100% !important; }


.cardLabels {
  display: block;
  margin-bottom: 0.4rem !important;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: center !important; }

.cardInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 40px;
  width: 100%;
  max-width: 100%;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 14px;
  &:focus {
    border: 1px solid #046dd6; } }

.expiryDropdown {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  width: 300% !important;
  font-size: 14px; }

.expiryDropdownLabel {
  margin-bottom: 4px;
  margin-left: 0.5rem;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  // margin-left: 11px
  justify-content: center !important;
  text-align: center !important; }

.cardLabels2 {
  margin-bottom: 4px;
  margin-left: 0.5rem;
  font-weight: 100;
  font-size: 13px;
  // margin-top: px !important
  justify-content: center !important;
  text-align: center !important; }

.cvcLabel {
  display: block;
  margin-bottom: 0.4rem !important;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  justify-content: center !important;
  text-align: justify !important;
  justify-content: center !important;
  text-align: center !important; }

.gpayBtn {
  margin-left: 1.8rem;
  margin-top: 1rem; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
 }  // margin-right: 10% !important

.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  margin-left: 1.6rem; }



.calendarIcon {
  path {
    fill: $primary-color-subscriber !important; } }

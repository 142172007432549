@import 'src/styles/variables';

.PhoneInput {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0 !important;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 8px !important;
  line-height: 25px !important;
  height: 39px !important;
  width: 100% !important;
  outline: none !important; }

.dropdown {
  font-size: 13px !important;
  max-width: 740% !important;
  margin: 0 !important;
  margin-top: 3px !important;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  text-align: justify !important;
  color: $general-text-color !important; }

.search {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;

  margin-top: 0 !important;
  margin-bottom: 0 !important; }
//   padding-left: 48px !important
//margin-left: 0 !important
//background: #FFFFFF
//border: 1px solid #CACACA
//border-radius: 8px !important
//line-height: 25px !important
//outline: none !important

.buttonC {
  border: 1px solid #CACACA;
  border-radius: 10px;
  color: $default-button-txt; }


@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important;
  &-active {
    margin-top: 15px; } }
.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em; }
.btnFilter {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: blue;
  border-radius: 8px; }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); }
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.5px solid rgba($general-text-color, 0.6); }
  &-success {
    background: $green; }
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.1); } }
.badge {
  font-size: 12px !important;
  border-radius: 4px;
  height: 19px;
  max-width: 18px;
  min-width: 20px;
  background-color: #369636;
  &-primary-background {
    background: $green !important; }
  &-orange-background {
    background: $orange !important; }
  & div:first-child {
    padding: 0 6px; } }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }

.button {
  border-radius: 8px;
  min-width: 40px;
  min-height: 30px;
  height: 40px;
  font-size: 15px;
  margin-left: 15px;
  font-weight: bolder;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45); } }

.form {
  width: 100%;
  padding: 0 10px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formInputTo {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 2rem !important; }
.formInputAmount {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 9rem !important; }
.formLabel {
  & div {
    margin-left: 20px; } }

.fieldset {
  padding: 0 40px 0 0 !important;
  &-dateStroke {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    left: -20px;
    margin-top: 1.3rem;
    justify-content: center !important; }
  &-dateStroke1 {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 5rem;
    left: -20px; }
  &-dateStroke2 {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 2.3rem;
    right: 13rem; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; }
  &-firstField {
    margin-right: 6rem !important; } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  &-secondary {
    background: rgba($general-text-color, 0.45); }
  &-refresh {
    min-width: unset;
    width: fit-content;
    font-size: 16px; } }

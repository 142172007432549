@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }


.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.locationIcon {
  position: absolute;
  left: 50px;
  top: 8px;
  stroke: $disabled-bg-color; }

@media ( max-width: 991px  ) {
  .locationIcon {
    left: 12px; } }

.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-right: 28px !important; }

.calInput {
  display: none !important; }
.button {
  border-radius: 8px;
  min-width: 140px;
  height: 40px;
  font-size: 14px;
  &:hover {
      background: rgba(#0091e6, 1);
      transition: 0.1; }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45);
    margin-left: 10px;
    &:hover {
      background: rgba($general-text-color, 0.8);
      transition: 0.1; } }
  &-bg-raise {
    background: rgba($red, 0.8);
    margin-left: 25px;
    &:hover {
      background: $red;
      transition: 0.1; } } }


.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.form {
  width: 100%;
  padding: 0 10px 30px 0 !important; }

.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 110% !important;
  text-align: center !important; }

.addressInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  margin-left: 2.7rem;
  width: 99% !important;
  text-align: center !important; }

@media ( max-width: 991px ) {
  .addressInput {
    width: 110% !important;
    margin-left: 0rem; } }




.formLabel {
  & > div {
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }

.body {
  padding: 10px 40px 20px;
  font-size: 13px;
  color: rgba($general-text-color, 0.7); }
.boldText {
  color: 'grey-dark';
  font-weight: bold; }
.numberLabel {
  width: 20px;
  text-align: end; }
.greyButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: rgba($general-text-color, 0.4) !important; }
.orangeButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $orange !important; }
.primaryButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $primary-color !important; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }



.locationbtn {
  background-color: white !important;
  color: $primary-color !important;
  font-weight: bolder !important;
  margin-left: 72%;
  margin-top: -22px;
  text-decoration: underline; }

.fetching {
  background-color: white !important;
  color: $primary-color !important;
  font-weight: bolder !important;
  margin-left: 65%;
  margin-top: -22px;
  text-decoration: underline; }

.detailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 14px !important;
  margin-left: -5% !important;
  margin-bottom: 5% !important; }


.customLabel {
  font-weight: 1000 !important; }

.ownerDetailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-left: -6%; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  margin-left: 120%;
  // max-width: 350px !important
  font-weight: 1000px;
  margin-top: -15%;
  position: relative; }


.boldText1 {
  color: 'grey-dark';
  font-weight: 700;
  font-size: 15px; }

.dragDropLabel2 {
  border-radius: 8px;
  max-width: 300px;
  margin-right: 40%;
  margin-top: 8%; }

.formatsText {
  color: black;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }


.boldText2 {
  color: 'black';
  font-weight: 500;
  font-size: 13px; }

.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }


.transitionContainer {
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out, overflow 0.5s ease-in-out; }

.fadeOut {
  opacity: 0;
  height: 0;
  overflow: hidden; }

.fadeIn {
  opacity: 1;
  height: auto;
  overflow: visibl; }

.fetchBtn {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-top: -60px;
  position: relative; }

.fetchBtnErrorStyle {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-top: -72px;
  position: relative; }

.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: 20px !important;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }


.confirmBtn {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: bold; }


.editBtn {
  background-color: $general-text-color !important;
  color: #fffefe !important;
  font-weight: 350 !important;
  min-width: 53px;
  margin-left: 3% !important;
  height: 25px;
  font-size: 13px;
  position: absolute !important;
  border-radius: 20px !important;
  margin-top: -11px;
  position: relative; }

.formDropdownLabel {
  margin-left: -14% !important;
  color: #413e3e;
  font-weight: 100;
  font-size: 13px; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
 }  // margin-right: 10% !important

.noteLabel {
  margin-left: -0.5rem;
  color: #000000;
  font-weight: 500;
  font-size: 13px; }

.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 10%; }




.cardPreview {
  flex: 1;
  margin-right: 50px;
  margin-top: 65px;
  margin-left: -6px; }

.cardInputs {
  flex: 1;
  margin-left: 20px; /* Adjust spacing between card preview and inputs */;
  margin-right: 140px;
  margin-top: -5%; }


.cardLabels {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: justify !important; }

.cardInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 40px;
  width: 135%;
  max-width: 135%;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 14px;
  &:focus {
    border: 1px solid #046dd6; } }

.expiryDropdown {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  width: 325% !important;
  max-width: 325%;
  font-size: 14px; }

.expiryDropdownLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #000000;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  justify-content: center !important;
  text-align: justify !important; }

.cardLabels2 {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #5e5858;
  font-weight: 100;
  font-size: 13px;
  margin-top: 5px !important;
  justify-content: center !important;
  text-align: justify !important; }


.commuterLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: justify !important; }



.commuterTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 15px !important;
  // text-align: justify !important
  margin-left: 9rem !important; }


.addVehicleBtn {
  background-color: transparent !important;
  color: $primary-color  !important;
  font-weight: 650 !important;
  margin-top: 1px;
  min-width: 40px;
  margin-left: 0.5% !important;
  height: 40px;
  font-size: 14px;
  // position: absolute !important
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  // border: 0.1px solid #24acc4 !important
  border-radius: 8px !important; }

.formGroupLabel {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }

.subHeading {
  color: rgba($general-text-color, 0.5) !important;
  font-weight: 550 !important;
  font-size: 15px !important;
  margin-top: 20px;
  justify-content: center !important;
  text-align: center !important;
  font-family: 'Roboto', sans-serif !important; }


.durationText {
  color: $general-text-color !important;
  font-weight: 550 !important;
  font-size: 20px !important;
  margin-top: 20px;
  justify-content: center !important;
  text-align: center !important;
  font-family: 'Roboto', sans-serif !important; }


.paidInputClass {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 110% !important;
  color: green !important;
  text-align: center !important; }


.unpaidInputClass {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 110% !important;
  color: red !important;
  text-align: center !important; }

@import 'src/styles/variables';

.container {
  font-family: Roboto, sans-serif !important; }
.logoLink {
  text-decoration: none !important; }
.title {
  color: white;
  font-size: 14px; }
.whiteText {
  color: white !important; }
.dFlex {
  display: flex; }
.dFlexColumn {
  flex-direction: column; }
.username {
  font-size: 13px;
  color: #FFFFFF; }
.currentTime {
  font-size: 12px;
  margin-top: 6px;
  color: #FFFFFF !important;
  opacity: 0.6;
  min-width: 175px;
  font-weight: 300;
  text-transform: uppercase; }
.arrowDownIcon path {
  fill: #FFFFFF;
  stroke: #FFFFFF; }

.logOutBtns {
  border-radius: 8px;
  min-width: 100px;
  height: 40px;
  background-color: #3A9CED !important;
  font-size: 14px;
  font-weight: 400;
  margin-left: 1rem;
  margin-right: 1rem;
  border: none !important;
  background-color: #3A9CED !important;
  color: #FFFFFF;
  &:hover {
    opacity: 0.8; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba(#242E42, 0.6 ) !important;
    opacity: 0.45;
    &:hover {
      opacity: 6 !important; }
    &:active {
      transform: scale(0.95); } } }

.navLinks {
  color: $default-text-subscriber; }

.logInBtn {
  background-color: #3A9CED !important;
  color: #FFFFFF !important;
  font-size: 16px !important;
  width: 100px !important;
  border: 1px solid white !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  &:hover {
    background-color: white !important;
    color: #3A9CED !important;
    transition: 0.1s !important;
    font-weight: 600 !important; } }

.registerBtn {
  background-color: #3A9CED !important;
  color: #FFFFFF;
  font-size: 16px !important;
  border: 1px solid white !important;
  width: 100px !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  margin-right: 10px;
  &:hover {
    background-color: white !important;
    color: #3A9CED !important;
    transition: 0.1s !important;
    font-weight: 600 !important; } }

.regCol {
  display: flex !important;
  justify-content: center !important; }

.backdropC {
  width: 100% !important;
  height: 100% !important; }

.navbar {
  background: #3A9CED !important;
  color: #FFFFFF !important; }
.translatorIcon {
  height: 22px !important;
  path {
    stroke: #fff !important; } }

.reportButton {
  background-color: #3A9CED !important;
  border: none !important;
  position: fixed !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  top: 45% !important;
  left: -3.8rem !important;
  z-index: 1 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  outline: none !important;
  transition: opacity 0.1s ease !important;
  color: #ffffff;
  transform: rotate(270deg);
  padding: 10px !important;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: bold; }

.notificationButton {
  text-align: center !important;
  width: 150px;
  height: 40px;
  border-radius: 8px;
  font-weight: 400;
  background-color: #3A9CED!important;
  font-size: 16px !important;
  cursor: pointer;
  border: 1px solid white !important;
  margin-right: 2rem;
  &:hover {
    background-color: white !important;
    color: #3A9CED !important;
    transition: 0.1s !important;
    font-weight: 600 !important; } }
.notificationCount {
  font-size: 13px !important;
  margin-right: -160px !important;
  margin-top: -30px !important;
  background-color: #e20039 !important;
  padding: 0.25em 0.4em;
  border-radius: 12px !important;
  z-index: 1;
  color: white !important; }

@import 'src/styles/variables';

.default {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.015em;
  text-align: center;
  display: flex;
  justify-content: center !important;
  color: $general-text-color;
  border-radius: 10px;
  position: relative;
  font-size: 1.6rem;
  color: #ffffff; }

.backButton {
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 50;
  min-width: unset;
  height: 40px;
  padding: 10px;
  color: black !important; }

.map {
  border: 2px solid #fa9050;
  min-height: 300px; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.app {
  margin: 0 auto;
  width: 100%; }

.button {
  border-radius: 8px;
  min-width: 60px;
  background: transparent;
  color: #000000;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }

.btn {
  font-weight: inherit;
  font-size: 14px;
  min-width: 380px;
  min-height: 150px;
  letter-spacing: inherit;
  padding: 70px;
  background: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber;
  border-radius: 1px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 100px; }

.title {
  font-size: 24.9px;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  color: $page-header-txt-subscriber !important;
  opacity: 0.5;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif;
  margin-top: 25px; }

.tabs {
  margin-top: 20px;
  width: 97%;  // Adjusted to take full width
  border: none !important;
  justify-content: center; }




.cardCol {
  margin-top: 20px;
  margin-bottom: 2%; }

.card {
  height: 420px;  // Adjusted to take dynamic height
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center !important;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); } }


@media (min-width: 768px) and (max-width: 992px) {
  .cardCol {
    max-width: 50% !important; } }

@media screen and (min-width: 280px) and ( max-width: 450px ) {
  .cardCol {
    font-size: 19px !important; }
  .cardTitle {
    font-size: 22px !important; }
  .getPassBtn {
    font-size: 22px !important; }
  .centerLink {
    font-size: 19px !important;
    margin-bottom: 1rem !important; }
  .title {
    font-size: 40px !important; }
  .default {
    font-size: 50px !important; }
  .tabsTitle {
    font-size: 25px !important; } }

@media screen and (min-width: 100px) and ( max-width: 279px ) {
  .cardCol {
    font-size: 22px !important; }
  .cardTitle {
    font-size: 25px !important; }
  .getPassBtn {
    font-size: 25px !important; }
  .centerLink {
    font-size: 22px !important;
    margin-bottom: 1rem !important; }
  .title {
    font-size: 40px !important; }
  .default {
    font-size: 20px !important; }
  .tabsTitle {
    font-size: 30px !important; } }





.squareButton {
  min-width: 5px !important;
  height: 35px;
  position: absolute;
  top: 8px;
  right: 5px;
  color: #000000 !important;
  background: transparent;
  border: 2px solid #000000 !important;
  border-radius: 50px !important;
  &:hover {
    background-color: #000000;
    fill: white  !important; }
  &:active {
    transform: scale(0.95); } }

.centerLink {
  color: $primary-color-subscriber !important;
  background: transparent !important;
  margin-top: 0.5rem !important;
  font-weight: 500;
  font-family: Roboto, sans-serif; }

.detailsIcon {
  height: 5rem !important;
  width: 20px; }

.tabs2 {
  width: 100%;  // Adjusted to take full width
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.loader {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  align-self: center !important;
  margin-top: 10% !important;
  color: $primary-color-subscriber !important;
  @if $primary-color-subscriber == null {
    color: #3A9CED !important; }
  @else {
    color: $primary-color-subscriber !important; } }


.btnFilter {
  margin-left: 15px;
  margin-right: 15px;
  min-width: 43px;
  font-size: 16px;
  color: $default-button-txt;
  background-color: $grey-dark;
  border-radius: 8px; }
.detailsSection {
  align-items: flex-start !important;
  line-height: 1.2;
  margin-bottom: 1rem !important; }

.location {
  font-weight: 600 !important; }

.LocationIcon {
  position: relative;
  top: -10%!important;
  right: 0.5rem !important;
  stroke: $disabled-bg-color; }

.getPassBtn {
  border-radius: 5px;
  width: 100% !important;
  display: flex;
  position: relative;
  min-height: 40px !important;
  height: fit-content !important;
  font-size: 17.5px;
  font-weight: 500  !important;
  color: $default-button-txt-subscriber !important;
  background: $primary-btn-color-subscriber !important;
  &:hover {
    opacity: 0.9; }
  &:active {
    transform: scale(0.95); } }

.cardTitle {
  font-weight: bold !important;
  font-size: 17px; }

.cardHeader {
  font-size: 17px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 45px !important;
  outline: none !important;
  border: none !important;
  margin-top: -1rem !important; }
.cardFooter {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 3.5rem !important;
  width: 100%;
  justify-content: justify;
  outline: none !important;
  border: none !important;
  position: relative !important; }
.purchaseBtn {
  border-radius: 8px;
  min-width: 150px !important;
  height: 40px;
  padding: 20px;
  font-size: 14px;
  font-weight: 600 !important;
  color: $default-button-txt-subscriber !important;
  background: $primary-btn-color-subscriber !important;
  &:hover {
    opacity: 0.9; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg;
    0.6 {}
    &:hover {
      opacity: 0.9; }
    &:active {
      transform: scale(0.95); } } }

.soldOut {
  color: white !important;
  height: 40px !important;
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.06em;
  background-color: rgba($red, 0.8);
  height: 25px;
  padding: 0.3em 1em 0.4em 1em;
  font-family: Roboto, sans-serif !important;
  font-weight: 1000;
  font-size: 18px;
  -webkit-text-shadow: 0 0 0.75em #444;
  z-index: 10; }

.filters {
  background: white !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 50px;
  padding: 0 !important; }

.collapse {
  margin-top: -1rem !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }


.searchButton {
  border: 1.5px solid #ced4da !important;
  &:hover {
    stroke: $primary-color-subscriber;
    border: 1px solid $primary-color-subscriber !important; }
  &:active {
    stroke: $primary-color-subscriber;
    border: 1px solid $primary-color-subscriber !important; }
  &:focus {
    border: 1px solid $primary-color-subscriber !important; } }

.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }


.searchEvent {
  width: 15rem;
  margin-left: 1rem; }

.eventFieldLabel {
  font-size: 15px !important;
  font-family: Roboto, sans-serif !important;
  color: #000000 !important;
  margin-top: 4px;
  margin-left: 25px; }

.checkTxt {
  font-size: 35px;
  color: #000000;
  text-align: center !important;
  margin-top: 10px; }

.expiryTxt {
  font-size: 14px;
  color: rgba($general-text-color, 0.7);
  text-align: center !important; }

.verficationCodeFields {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  width: 30% !important;
  margin-left: 35.5% !important;
  margin-top: 2%; }

@media  ( max-width: 1000px  ) {
  .verficationCodeFields {
    width: 70% !important;
    margin-left: 15% !important; } }

.verifyBtn {
  min-width: 150px !important;
  background-color: $primary-btn-color-subscriber;
  font-size: 18px;
  height: 40px;
  margin-top: 4%;
  border-radius: 5px;
  color: $default-button-txt-subscriber !important;
  &:hover {
    background-color: #1388f5; }
  &:active {
    transform: scale(0.95); } }


.resendBtn {
  min-width: 100px !important;
  background-color: $primary-btn-color-subscriber;
  display: flex;
  justify-content: center;
  justify-self: flex-end !important;
  font-size: 18px;
  height: 40px;
  margin-top: 2%;
  border-radius: 5px;
  color: $default-button-txt-subscriber !important;
  &:hover {
    background-color: #1388f5; }
  &:active {
    transform: scale(0.95); }
  &:disabled {
    background-color: rgba($general-text-color, 0.3) !important;
    cursor: not-allowed !important;
    &:active {
      transform: none; } } }

.resendbtnCol1 {
  display: flex;
  justify-content: flex-end !important;
  margin-top: 15px !important; }

.resendbtnCol2 {
  display: flex;
  justify-content: center !important;
  margin-top: 15px !important; }

.btnCol2 {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  margin-top: 33px !important; }

.timer {
  font-size: 14px;
  font-weight: bold !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px; }

.instruction {
  font-size: 14px;
  color: rgba($general-text-color, 0.9);
  text-align: center !important;
  margin-top: 30px; }


.spaceStyle {
  width: 40%;
  text-align: center; }

@media ( max-width: 1000px  ) {
  .spaceStyle {
    width: 60%; } }

@media ( max-width: 751px  ) {
  .spaceStyle {
    width: 95%; } }


.errorMsg {
  background-color: rgba(#ff4d4f, 0.9); }

.successMsg {
  background-color: rgba(#b7eb8f, 0.8); }

.searchField {
  margin-top: 15px; }

.resendbtnCol1Hi {
  display: flex;
  justify-content: flex-start !important;
  margin-top: 15px !important; }

.resendbtnCol2Hi {
  display: flex;
  justify-content: center !important;
  margin-top: 15px !important; }

.btnCol2Hi {
  display: flex;
  justify-content: flex-end !important;
  align-items: center !important;
  margin-top: 33px !important; }

.resultText {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  margin-top: 200px !important;
  color: rgba($general-text-color, 0.8);
  font-size: 18px !important;
  font-family: Roboto, sans-serif !important; }

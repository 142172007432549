@import 'src/styles/variables';

.container {
  font-family: Roboto, sans-serif !important;
  padding-top: 5px !important;
  padding-right: 25px !important;
  width: 100% !important;
  background: transparent !important;
  border: none !important; }

.mainCard {
  border: none;
  padding: 0 !important;
  margin: 0 !important; }


.card {
  margin-bottom: 20px !important;
  padding: 0 !important;
  position: relative;
  height: auto !important;
  min-width: 100% !important;
  &::before {
    content: '' !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: transparent !important;
    backdrop-filter: blur(5px)  !important;
    transition: backdrop-filter 0.3s !important;
    z-index: -1 !important; }
  &:hover {
    background-color: rgba($primary-color, 0.05);
    cursor: pointer;
    .viewButton {
      background-color: transparent !important; }

    .icon-eye {
      fill: white !important; } }
  &:active {
    background-color: rgba($primary-color-subscriber, 0.05); }
  &:focus {
    background-color: rgba($primary-color-subscriber, 0.05); }
  .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    opacity: 0;
    transition: opacity 0.3s ease; }

  &:hover .hoverText {
    opacity: 1;
    filter: blur(0px); } }

.label {
  font-weight: 600;
  font-size: 13px;
  color: rgba($general-text-color, 0.5);
  margin-bottom: 5px;
  font-family: Roboto, sans-serif !important; }

.rowContent {
  display: flex; }

.detail {
  margin-bottom: 10px;
  font-weight: 500;
  font-family: Roboto, sans-serif !important; }


.green {
  color: green; }

.yellow {
  color: yellow; }

.red {
  color: red; }

.viewButton {
  margin-top: 10px;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }
.btnCol {
  display: flex !important;
  justify-content: left !important; }

.statusDiv {
  justify-content: center !important;
  text-align: left !important; }
.dateTime {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45); }

.hideOnMobile {
  @media only screen and (max-width: 767px) {
    display: none; } }




@media only screen and (min-width: 768px) {
  .dateTime {
    display: none; } }
@media only screen and (max-width: 767px) {
  .actionsCol {
    margin-left: 50%;
    display: table-column; }
  .pdfIcon {
    left: 256px; } }



.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }


.btnFilter {
  margin-left: 20px !important;
  min-width: 43px !important;
  font-size: 16px !important;
  background-color: $grey-dark !important;
  border-radius: 8px !important; }

.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }

.status {
  &-success {
    color: green; }
  &-inProgress {
    color: $orange; }
  &-failed {
    color: red; } }



.filters {
  background: rgba($general-text-color, 0.1) !important;
  border-radius: 8px !important;
  min-height: 100px !important;
  margin-bottom: 10px !important; }


.activeTab {
  background: rgba($general-text-color, 0.1) !important;
  color: rgba($general-text-color, 0.6) !important; }
.activeIcon {
  & line {
    stroke: $general-text-color !important; }
  & path {
    stroke: $general-text-color !important; } }


.title {
  font-size: 25px !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  color: rgba($general-text-color, 0.7) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif; }

.cancel {
  border-radius: 10px !important;
  min-width: 75px !important;
  font-size: 14px !important;
  background-color: $orange !important;
  color: $default-button-txt-subscriber !important;
  &-disabled-bg {
      background: ($general-text-color, 0.5) !important; } }
.renewBtn {
  border-radius: 10px !important;
  min-width: 75px !important;
  font-size: 14px !important;
  background-color: $danger-button-bg-subscriber !important;
  color: $default-button-txt-subscriber !important;
  margin-left: 10px;
  color: white;
  z-index: 100;
  &-disabled-bg {
    background: ($general-text-color, 0.5) !important; } }

.extendBtn {
  border-radius: 10px !important;
  min-width: 75px !important;
  font-size: 14px !important;
  background-color: $red !important;
  margin-left: 10px;
  color: $default-button-txt-subscriber !important;
  &-disabled-bg {
    background: ($general-text-color, 0.5) !important; } }

.actions {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important; }

.filterInput {
  font-size: 1rem !important;
  width: 100%  !important;
  border: 1px solid #ddd;
  border-radius: 8px !important;
  transition: all 0.3s ease-in-out !important;
  height: 40px !important; }

.filterInput:focus {
  outline: none !important;
  border-color: #3e8bf8 !important;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5) !important; }

@media screen and (max-width: 1150px) {
  .filterInput {
    width: 60% !important; } }

.pdfIcon {
  height: 35px;
  width: 35px;
  left: 265px;
  position: absolute; }




.actionsLabel {
  font-weight: 600;
  font-size: 13px;
  color: rgba($general-text-color, 0.5);
  margin-bottom: 5px;
  font-family: Roboto, sans-serif !important; }

@media (min-width: 991px) and (max-width: 1225px) {
  .actionsCol {
    margin-left: -8%;
    display: table-column; } }


.button {
  &-bg-secondary {
    background: rgba($general-text-color, 0.45) !important;
    cursor: not-allowed !important;
    &:hover {
      background-color: #000000; }
    &:active {
      transform: scale(0.95); } } }



@import 'src/styles/variables';

.modalTitle {
  font-size: 18px !important; }
.violationModal {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }
.modalContent {
  color: rgba($general-text-color, 0.9);
  margin: auto; }
.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }
.btn {
  border-radius: 8px;
  font-size: 16px;
  color: $default-button-txt;
  &-primary {
    background: $primary-btn-color; }
  &-light {
    background: $cancel-button-bg;
    opacity: 0.1;
    color: $default-button-txt; }
  &-danger {
    background: $danger-button-bg !important; }
  &-save {
    background: $save-button-bg; } }
.boldText {
  color: $general-text-color;
  font-weight: 600;
  font-style: normal;
  margin: 0 4px; }
.lot {
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  height: 40px !important;
  font-size: 13px !important;
  background: rgba($general-text-color, 0.1); }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-success {
    background: $green; }
  &-danger {
    background: $red; } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

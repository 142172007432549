@import 'src/styles/variables';

.content {
  font-size: 14px;
  letter-spacing: inherit;
  line-height: inherit;
  text-align: left;
  padding-top: 15px; }
.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $cancel-button-bg !important;
  opacity: 0.4;
  color: $default-button-txt; }
.submitButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $red !important;
  color: $default-button-txt; }

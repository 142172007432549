@import 'src/styles/variables';

.title {
  font-family: 'Roboto', sans-serif;
  color: $general-text-color !important;
  font-size: 16px !important; }
.subTitle {
  font-family: 'Roboto', sans-serif;
  color: $general-text-color !important;
  font-size: 14px !important; }
.form {
  padding: 10px 30px 20px; }
.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45); } }

.button {
  border-radius: 8px;
  min-width: 100px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }
.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 100%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.error {
  margin-left: -30px;
  margin-top: 4px;
  margin-bottom: -10px;
  color: #FB745B;
  font-size: 12px; }

.subTitle1 {
  font-family: 'Roboto', sans-serif;
  color: #FB745B !important;
  font-size: 12px !important; }

.customCheckStyle {
  appearance: none;
  margin-top: 0.1rem !important;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 1rem !important;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

.customTooltip {
  right: -7%;
  // margin-right: 1px
  &:hover {
    color: $general-text-color; } }


.fieldset {
  & button {}
  &-right-label {
    & label {
      & > div {
        margin-left: 10px; } } } }

.fieldset-four {
  & > div {
    & > div {
      & > div:first-child {
        width: 160%;
        margin-right: 5px; } } } }

.addButtonCustom {
  background: $primary-color;
  font-weight: inherit;
  font-size: 30px;
  min-width: 30px !important;
  margin-right: -10px;
  height: 35px !important;
  border-radius: 8px;
  padding: 10px !important;
  padding-top: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
 }  // margin-top: 0.3rem !important

.customLabels {
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: -5 !important; }


.customLabelSection {
  font-size: 13px !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  text-align: left !important; }

.customLabels2 {
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: 15px; }


.customLabelSection2 {
  font-size: 13px !important;
  text-align: right !important; }



.customLabels3 {
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: -10px;
  min-width: 8rem !important; }


.customLabelSection3 {
  font-size: 13px !important;
  text-align: left !important; }


.customLabelRow {
  display: flex  !important;
  justify-content: center !important;
  margin-left: 19rem !important;
  margin-top: 10px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important; }

.customLabelRow1 {
  display: flex  !important;
  justify-content: center !important;
  margin-left: 4rem !important;
  margin-top: 10px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important; }



.checkboxRow {
  margin-top: -13px !important;
  margin-bottom: 1px !important; }

.checkboxRow1 {
  margin-top: -13px !important;
  margin-bottom: 1px !important; }


.checkboxLabel {
  margin-top: 2px !important;
  color: rgba($general-text-color, 0.6); }

.seperator {
  margin-bottom: 10px;
  width: 92%;
  display: flex !important;
  justify-content: center !important; }

.divider {
  margin-left: 50px;
  color: $general-text-color !important; }

.checkboxFree1 {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 2em;
  margin-left: 22rem !important;
  z-index: 1; }

.checkboxFree2 {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 2em;
  margin-top: 0.6rem !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1; }

.checkboxFree3 {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 2em;
  margin-left: 21.3rem !important; }

.customCheckBox {
  appearance: none;
  margin-top: 0.1rem !important;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 1rem !important;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

.customCheckBox1 {
  appearance: none;
  margin-top: 0.1rem !important;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 1rem !important;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

.trashIconStatic {
  cursor: pointer;
  margin-top: 0.6rem;
  width: 40px;
  path {
    stroke: rgba($disabled-bg-color, 0.9) !important; }
  &:hover {
    opacity: 1;
    path {
      stroke: rgba($red, 0.7) !important; } } }


.customLabels4 {
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }

.customLabels5 {
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: 35px;
  min-width: 9rem !important; }

.customLabelSection4 {
  font-size: 13px !important;
  text-align: left !important; }
.subdetailsLabel {
  font-weight: 500 !important;
  color: $general-text-color !important;
  opacity: 0.6 !important;
  margin-left: -2px;
  font-size: 13px !important;
  margin-bottom: 0.5rem !important; }

.disableButtonCustom {
  background: white !important;
  font-weight: inherit;
  font-size: 30px;
  min-width: 30px !important;
  margin-right: -10px;
  height: 30px !important;
  border-radius: 8px;
  padding: 10px !important;
  margin-top: 0.3rem !important; }

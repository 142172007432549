@import 'src/styles/variables';

.formRow {
  & input, select, textarea {
    height: 40px;
    color: $general-text-color;
    font-size: 13px;
    opacity: 0.9;
    border: 1px solid rgba($snuff, 0.95); } }
.label {
  display: flex;
  align-items: center;
  height: 40px;
  padding-right: 5px !important;
  padding-top: 11px !important;
  font-size: 13px !important;
  font-weight: 300; }
.tooltipEnd {
  position: absolute;
  right: -20%;
  top: 20%;
  z-index: 10; }

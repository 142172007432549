@import 'src/styles/variables';

.form {
  width: 100%;
  padding-right: 20px; }
.fieldset {
  padding: 0 40px 0 0 !important; }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.subSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  padding: 20px 0;
  width: 100%; }
.violationButton {
  font-size: 16px;
  background: $primary-btn-color;
  border-radius: 8px; }
.btnFilter {
  margin-right: 20px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px;
  color: $default-button-txt; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color;
    fill: $general-text-color; } }
.icon {
  & path {
    fill: white;
    stroke: white; }
  &-fade {
    opacity: 0.85; } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  color: $default-button-txt;
  background: $save-button-bg; }
.background {
  &-primary {
    background: $primary-color !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }
.playWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($general-text-color, 0.45); }
.imgThumb {
  border-radius: 8px !important;
  width: 250px;
  height: 150px;
  object-fit: contain; }
.image {
  pointer-events: none; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }

.errorStyle {
  color: rgba(36, 46, 66, 0.4);
  font-size: 17px;
  font-weight: 600;
  line-height: 19.92px;
  letter-spacing: 0.015em;
  text-align: center; }

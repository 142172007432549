@import 'src/styles/variables';

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  &:focus {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; }
  &:hover {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; } }
.formInput2 {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  &:focus {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; }
  &:hover {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; } }
.formLabel {
  & div {
    margin-left: 10px;
    text-align: left; } }
.cancelButton {
  background-color: $cancel-button-bg !important;
  color: $default-button-txt;
  opacity: 0.4;
  opacity: 0.6 !important;
  border-radius: 8px;
  min-width: 100px; }
.submitButton {
  border-radius: 8px;
  min-width: 100px; }
.formHourInput {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.formHourLabel {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  padding: 0 8px 0 0 !important;
  & div {
    margin-left: auto !important; } }
.formGroupLabel {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 13px !important; }
.formTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        text-align: left; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%;
        &:focus {
          box-shadow: none;
          border: 1px solid rgba(216, 219, 234, 0.95) !important; } } } } }
.formInput1 {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
 }  // width: 85% !important
.okBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -60px;
  min-width: 40px;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: red; }
  border: 0.1px solid rgba($primary-btn-color-subscriber, 0.8) !important;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.okBtnErrorStyle {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -10.3%;
  min-width: 40px;
  margin-left: 86.4% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: red; }
  border: 0.1px solid rgba($primary-btn-color-subscriber, 0.8) !important;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.formDropdownLabel {
  margin-left: -14% !important;
  color: #413e3e;
  font-weight: 100;
  font-size: 13px; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important; }
.fetchBtn {
  background-color: $primary-btn-color !important;
  color: $default-button-txt !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: -61px;
  position: relative; }

.fetchBtnErrorStyle {
  background-color: $primary-btn-color !important;
  color: $default-button-txt !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 85.3% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: -101px;
  position: relative; }

.fetchBtn1 {
  background-color: $primary-btn-color !important;
  color: $default-button-txt !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 79.3% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: -60px;
  position: relative; }

.fetchBtnErrorStyle1 {
  background-color: $primary-btn-color !important;
  color: $default-button-txt !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 85.3% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: -101px;
  position: relative; }

.commuterFormInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.commuterLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  justify-content: left !important;
  text-align: left !important; }

.collapsable {
  margin-left: 10px;
  min-width: 690px !important;
  max-width: 690px !important; }


.formDropdownInput1 {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important; }

@media (min-width: 767px) and (max-width: 991px) {
  .headerClass {
    margin-left: -1px !important;
    min-width: 720px !important; }
  .collapsable {
    margin-left: -55px !important;
    min-width: 720px !important; } }


@media (min-width: 726px) and (max-width: 766px) {
  .headerClass {
    margin-left: -1px !important;
    min-width: 710px !important; }

  .collapsable {
    margin-left: -65px !important;
    min-width: 710px !important; } }

@media (min-width: 719px) and (max-width: 726px) {
  .headerClass {
    margin-left: -1px !important;
    min-width: 695px !important;
    max-width: 695px !important; }

  .collapsable {
    margin-left: -63px !important;
    min-width: 695px !important;
    max-width: 695px !important; } }


@media (max-width: 718px) {
  .headerClass {
    margin-left: -1px !important;
    min-width: 650px !important;
    max-width: 650px !important; }

  .collapsable {
    margin-left: -45px !important;
    min-width: 650px !important;
    max-width: 650px !important; } }

.commuterTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 15px !important;
  text-align: center !important;
  margin-right: 5.3rem !important; }

.warning {
  font-size: 15px !important;
  text-align: center !important;
  margin-left: 5.3rem !important;
  background-color: $danger-color;
  padding: 0.5rem;
  color: white;
  border-radius: 8px; }

.addVehicleBtn {
  background-color: transparent !important;
  color: $primary-color  !important;
  font-weight: 650 !important;
  margin-top: 1px;
  min-width: 40px;
  margin-left: 0.7% !important;
  height: 40px;
  font-size: 13px;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border-radius: 8px !important; }

.customMultiDateInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  outline: none !important;
  padding: .75rem !important;
  min-width: 33rem !important;
  padding-right: 2rem !important; }

.dayPassCheckbox {
  margin-top: 0.2rem !important;
  font: inherit;
  color: currentColor;
  width: 0.9em !important;
  height: 0.9em !important;
  border: 1px solid $general-text-color !important;
  border-radius: 0.19em !important;
  transform: translateY(-0.075em);

  .dayPassCheckbox + .form-control {
    margin-top: 1em; }

  .dayPassCheckbox::before {
    content: "";
    width: 0.55em !important;
    height: 0.55em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color !important;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  .dayPassCheckbox:checked::before {
    transform: scale(1); } }

.dayPassCheckboxSection {
  display: flex;
  margin-left: 33%;
  width: 100%;
  margin-bottom: 20px;
  margin-top: -1%; }

.dayPassCheckTxt {
  color: $primary-color;
  margin-left: 10px;
  font-size: 13px;
  margin-top: -1px;
  font-weight: 600 !important; }

@media (max-width: 991px) {
  .dayPassCheckboxSection {
    margin-left: 0.1%; } }

.dayPassTooltip {
  width: 25px !important;
  height: 20px !important;
  margin-left: 10px; }

.selectedDatesRow {
  display: flex;
  width: 95.7%;
  margin-bottom: 40px !important; }

.selectedDaysLabel {
  font-size: 13px;
  color: rgba($general-text-color, 0.6);
  margin-top: 10px;
  margin-left: -4px; }
.selectedDaysLabelSection {
  text-align: left;
  max-width: 40%; }

.selectedDaysFieldSection {
  background: rgba($general-text-color, 0.1);
  border: 1px solid #ced4da;
  max-width: 100%;
  margin-left: -8.4% !important;
  border-radius: 8px;
  text-align: left;
  font-size: 13px; }

.dateContent {
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -20px; }

.iconSection {
  display: flex;
  justify-content: flex-start;
  align-items: center; }


.dayFieldContent {
  width: 50% !important;
  display: flex;
  margin: 10px;
  margin-left: -10px !important; }

@import 'src/styles/variables';

.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: rgba($general-text-color, 0.4) !important; }
.pauseButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $danger-button-bg !important; }
.resumeButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $primary-btn-color !important; }
.content {
  text-align: left;
  font-size: 14px;
  line-height: 1.4; }
.body {
  padding: 10px 40px 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  text-align: center; }
.valueText {
  color: $primary-btn-color !important;
  font-weight: 600; }
.bulletContent {
  display: flex;
  line-height: 2; }
.bulletContent1 {
  line-height: 2; }
.greenText {
  color: $green !important;
  font-weight: 600; }
.redText {
  color: $red !important;
  font-weight: 600; }

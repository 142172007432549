@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 16px !important; }
.form {
  padding: 10px 50px 20px; }
.fieldset {
  width: 50%;
  padding: 0 16px;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  & button {
    height: 40px;
    border: unset !important; }
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.primary-color {
  color: $primary-color; }
.cancelled-color {
  color: rgba($general-text-color, 0.45); }
.success-color {
  color: $green; }
.revoked-color {
  color: $red; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px; }
.button {
  border-radius: 8px;
  min-width: 150px;
  color: $default-button-txt;
  background: $save-button-bg;
  height: 40px;
  font-size: 14px;
  &-bg-secondary {
    background-color: $cancel-button-bg;
    opacity: 0.6; } }
.iconInput {
  padding-left: 28px !important; }

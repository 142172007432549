@import 'src/styles/variables';
.buttonGroup {
  padding-top: 11px;
  font-size: 13px; }
.radioButton {
  height: 16px;
  width: 16px;
  border: 2px solid rgba($general-text-color, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }
.active {
  border: 2px solid #3A9CED;
  & div {
    height: 8px;
    width: 8px;
    border: inherit;
    border-radius: inherit; }
  & svg {
    height: 8px;
    width: 8px !important;
    color: #3A9CED; } }
.buttonWrapper {
  min-width: 70px; }

@import 'src/styles/variables';

.content {
  font-size: 14px;
  line-height: 19.5px;
  text-align: center;
  padding-top: 14px;
  padding: 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.button {
  border-radius: 8px;
  min-width: 100px;
  font-size: 15px;
  color: $default-button-txt;
  &-bg-light {
    background-color: $cancel-button-bg !important;
    opacity: 0.6; }
  &-bg-danger {
    background-color: $danger-button-bg !important; }
  &-bg-primary {
    background-color: $primary-btn-color !important; } }
.title {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  text-align: center !important;
  justify-content: center !important; }

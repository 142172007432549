.react-markdown h1 {
  font-size: 5rem;
  color: #000000;
  margin-bottom: 1rem;
  font-family: Roboto, sans-serif !important;
}

.react-markdown h2 {
  font-size: 1.8rem;
  color: #000000;
  margin-bottom: 0.875rem;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700;
}

.react-markdown p {
  font-size: 1rem;
  color: #242E42;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-family: 'Roboto', sans-serif !important;
}

.react-markdown ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  font-family: 'Roboto', sans-serif !important;
}

.react-markdown li {
  font-size: 1rem;
  color: #242E42;
  opacity: 0.9;
  margin-bottom: 0.5rem;
}

.ant-modal-content {
  min-height: 650px;
}

.header {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
 }  // margin: 15px

.title {
  font-size: 28px;
  font-family: Roboto, sans-serif !important;
  line-height: 1.2;
  font-weight: 700;
  color: #242E42; }

.acceptBtn {
  height: 45px;
  width: 120px;
  font-size: 18px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  background-color: #3A9CED; }

.declineBtn {
  height: 45px;
  width: 120px;
  font-size: 18px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  margin-right: 30px;
  color: #242E42;
  &:hover {
    color: #3A9CED !important;
    border: 1px solid #3A9CED !important; } }

@import 'src/styles/variables';

.container {
  font-family: Roboto, sans-serif !important;
  padding-top: 5px !important;
  padding-right: 25px !important;
  width: 100% !important;
  background: #fff !important;
  border: none !important; }

.mainCard {
  border: none;
  padding: 0 !important;
  margin: 0 !important; }


.card {
  margin-bottom: 20px !important;
  position: relative;
  height: auto !important;
  text-align: left !important;
  &::before {
    content: '' !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: transparent !important;
    backdrop-filter: blur(5px)  !important;
    transition: backdrop-filter 0.3s !important;
    z-index: -1 !important; }
  &:hover {
    background-color: rgba(#3A9CED, 0.05) !important;
    cursor: pointer;
    .viewButton {
      background-color: transparent !important; }

    .icon-eye {
      fill: white !important; } }
  &:active {
    background-color: $primary-color-subscriber;
    opacity: 1; }
  &:focus {
    background-color: $primary-color-subscriber;
    opacity: 1; }

  .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    opacity: 0;
    transition: opacity 0.3s ease; }

  &:hover .hoverText {
    opacity: 1;
    filter: blur(0px); } }

.label {
  font-weight: 600;
  font-size: 13px;
  color: $default-text-subscriber;
  opacity: 0.5;
  margin-bottom: 5px;
  text-align: left;
  font-family: Roboto, sans-serif !important; }
.rowContent {
  display: flex; }

.detail {
  margin-bottom: 10px;
  font-weight: 500;
  text-align: left;
  font-family: Roboto, sans-serif !important; }


.green {
  color: green; }

.yellow {
  color: yellow; }

.red {
  color: red; }

.viewButton {
  margin-top: 10px;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }
.btnCol {
  display: flex !important;
  justify-content: left !important; }

.statusDiv {
  justify-content: center !important;
  text-align: left !important; }
.dateTime {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45); }

.hideOnMobile {
  @media only screen and (max-width: 767px) {
    display: none; } }



@media only screen and (min-width: 768px) {
  .dateTime {
    display: none; } }

.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }


.btnFilter {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }

.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }

.status {
  &-success {
    color: green; }
  &-inProgress {
    color: $orange; }
  &-failed {
    color: red; } }

.title {
  font-size: 25px !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  color: rgba($general-text-color, 0.7) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif;
  margin-right: 25px;
  margin-bottom: 25px; }

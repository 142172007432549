@import 'src/styles/variables';

.modalForm {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  margin: 0 auto; }

.formGroup {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 20px; }

.label {
  font-size: 13px !important;
  font-weight: 300 !important;
  color: $general-text-color !important;
  opacity: 0.6 !important;
  line-height: 1.5 !important;
  margin-top: 10px;
  & div {
    margin-left: 20px !important; } }

.dropdown {
  flex: 1;
  position: relative; }

.dropdownHeader {
  display: flex !important;
  justify-content: space-between !important;
  color: $general-text-color;
  align-items: center !important;
  background-color: #e9ecef !important;
  padding: 10px !important;
  border: 1px solid #ced4da !important;
  font-size: 13px !important;
  border-radius: 8px !important;
  height: 40px;
  cursor: pointer !important;
  width: 100% !important;
  position: relative !important;
  outline: none !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  &:hover {
    background-color: $primary-color !important;
    color: white !important;
    path {
      stroke-width: 1px !important;
      stroke: white !important; } }
  &:active {
    background-color: $primary-color !important;
    color: white !important;
    outline: none !important; }
  &-danger {
    color: $red !important; }
  &-success {
    color: $green !important; }
  &-open {
    background-color: $primary-color !important;
    color: white !important;
    path {
      stroke-width: 1px !important;
      stroke: white !important; } } }

.collapse {
  transition: height 0.3s ease, margin-top 0.3s ease;
  width: 100%; }

.scrollableContent {
  max-height: 240px;
  overflow-y: auto;
  padding: 10px;
  padding-left: 0 !important;
  background-color: transparent;
  border: none !important;
  margin-top: 10px;


  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; }

  .form-group label {
    font-weight: normal; }

  .form-group input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px !important;
    border: 1px solid #ccc;
    padding: 5px; } }



.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  display: flex;
  font-size: 13px;
  height: calc( 2em + 0.75rem + 2px);
  margin-bottom: 20px;
  background: #e9ecef;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 20px; }

.scrollableContent::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 8px !important; }

.scrollableContent::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px !important; }


.scrollableContent::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px !important; }


.scrollableContent::-webkit-scrollbar-thumb:hover {
  background: #555; }


.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  &-secondary {
    background: rgba($general-text-color, 0.45); }
  &-refresh {
    min-width: unset;
    width: fit-content;
    font-size: 16px; } }

.createdText {
  font-size: 13px;
  margin-left: -8px !important; }

.colStyle {
  padding-right: 0 !important; }

.colStyle2 {
  padding-right: 10px !important;
  padding-left: 0 !important; }

.stroke {
  color: rgba($general-text-color, 0.2);
  font-weight: 100 !important;
  margin-left: 10px;
  margin-right: -20px !important; }

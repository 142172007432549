@import 'src/styles/variables';

.card {
  background: #ffffff  !important;
  height: 150px;
  margin: 1rem !important;
  border-radius: 8px !important;
  line-height: 1.2 !important;
  &-cardbodyicon {
    text-align: end !important;
    font-size: 16px !important;
    border: none !important;
    border-radius: 8px !important;
    height: 50px;
    background: white !important; }
  &::before {
    content: '' !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: transparent !important;
    backdrop-filter: blur(5px)  !important;
    transition: backdrop-filter 0.3s !important;
    z-index: -1 !important; }
  &:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgba(0,0,0,0.11),0 24px 46px var(--box-shadow-color);
    box-shadow: 2px 2px rgba(36, 46, 66, 0.5);
    // background-color: $primary-color  !important
    background-color: rgba($primary-color, 0.05);
    cursor: pointer;
    .viewButton {
      background-color: transparent !important; }
    &-cardbodyicon {
      text-align: end !important;
      font-size: 16px !important;
      border: none !important;
      background: white;
      border-radius: 8px !important;
      height: 50px; }
    .icon-eye {
      fill: white !important; } }
  &:active {
    background-color: rgba($primary-color, 0.05); }
  &:focus {
    background-color: rgba($primary-color, 0.05); } }

.cardCol {
  width: 100% !important;
  max-width: 22rem !important; }


@media screen and ( max-width: 703px  ) {
  .cardCol {
    max-width: 21rem !important; } }


.cardbodyicon {
  text-align: end !important;
  font-size: 16px !important;
  border: none !important;
  border-radius: 8px !important;
  height: 50px;
  background: white !important; }

.cardbody {
  font-size: 20px !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  color: rgba($general-text-color, 0.5) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif; }

.icon {
  width: 20px !important;
  height: 20px !important; }

.helpText {
  font-size: 35px !important;
  font-weight: bold !important;
  color: grey !important; }

.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }

.valetStatus {
  margin-left: -30px;
  align-content: center;
  color: rgba($general-text-color, 0.5) !important; }

.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em;
  color: #242E42;
  opacity: .9;
  margin-left: 23px;
  margin-top: 12px; }

.card {
  width: 98%;
  height: 5rem !important;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 8px !important;
  margin-bottom: 0.8rem !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer; } }

.cardfilter {
  width: 100%;
  height: auto !important;
  padding: 10px;
  border-radius: 8px !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important; }

.cardContainer {
  padding: 10px; }


.cardText {
  margin-left: 60px; }


.viewButton {
  width: 5rem;
  height: 5rem !important;
  background-color: #3A9CED !important;
  outline: none !important;
  border-style: solid !important;
  color: #000000 !important;
  border-radius: 8px !important; }

.deleteButton {
  width: 5rem;
  height: 5rem !important;
  background-color: $danger-color !important;
  outline: none !important;
  border-style: solid !important;
  border-radius: 8px !important; }

.searchButton {
  margin-top: -48px !important;
  margin-left: 280px !important;
  width: 6%;
  height: 35px !important;
  background-color: $primary-color !important;
  outline: none !important;
  border-style: solid !important; }

.markallButton {
  background-color: $primary-color !important; }

.search {
  margin-top: 24px !important;
  margin-left: -40px !important; }

.searchField {
  height: 30px;
  box-shadow: none !important;
  background-color: rgba(36, 46, 66, 0.1) !important;
  border: 0.5px solid rgba(248, 249, 255, 0.95) !important;
  border-radius: 8px !important;
  margin-left: 42px;
  outline: none !important;
  padding-left: 10px !important;
  &:focus {
    border: 1px solid #046dd6 !important; } }

.form-control + .form-control {
  margin-top: 1em; }

.tab {
  width: fit-content; }

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none; }
.paginationItem {
  margin-right: 4px;
  &.active > .paginationLink {
    background-color: $primary-color;
    color: white; }
  &.disable {
    opacity: 0.7;
    & > .paginationLink {
      cursor: default; } } }
.paginationLink {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: $grey-med-dark;
  text-decoration: none !important;
  font-weight: 300;
  &:hover {
    color: $general-text-color; } }
.itemNext > a, .itemPrev > a {
  font-weight: bold !important; }


.customCheckStyle {
  appearance: none;
  margin-top: 0.2rem;
  font: inherit;
  color: currentColor;
  min-width: 1em !important;
  height: 1em !important;
  border: 0.1px solid $general-text-color !important;
  border-radius: 0.19em !important;
  transform: translateY(-0.075em);

  &::before {
    content: "";
    width: 0.8em !important;
    height: 0.8em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  &:checked::before {
    transform: scale(1); } }

.sendIcon {
  cursor: pointer;
  margin-left: 24px;
  width: 30px;
  height: 30px;
  &:hover {
    opcaity: 1;
    path {
      stroke: $primary-color; } } }

.eyeIcon {
  cursor: pointer;
  margin-left: 24px;
  width: 35px;
  height: 30px;
  fill: rgba($general-text-color, 0.45);
  &:hover {
    opcaity: 1; } }

.dateTime {
  max-width: 10rem !important;
  min-width: 16rem !important;
  margin-left: 1rem !important;
  margin-top: 0.5rem !important;
  margin-right: -2rem !important; }

@media (max-width: 1100px) {
  .dateTime {
    min-width: 0px !important; } }

.iconOne {
  max-width: 4rem !important;
  margin-top: 0.5rem !important;
  line-height: 1.4 !important; }

.iconTwo {
  max-width: 5rem !important;
  align-content: center !important;
  margin-right: 1rem !important; }

.text1 {
  font-size: 20px !important;
  display: flex;
  text-align: center !important;
  align-items: center !important;
  line-height: 1.4  !important;
  margin-left: 10px !important;
  color: rgba($general-text-color, 0.5) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif; }

.text2 {
  font-size: 13px !important;
  margin-left: 0px !important;
  color: rgba($general-text-color, 0.7) !important; }

.checkOuter {
  max-width: 3rem !important;
  margin-top: 0.3rem !important; }

.readButton1 {
  text-align: right !important;
  margin-top: 3.5rem !important; }

.table {
  display: block;
  max-height: calc(100vh - 210px) !important;
  &-active {
    margin-top: 15px; } }
.form {
  width: 100%;
  padding: 15px 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.title {
  color: #212529 !important;
  font-weight: 400 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem !important;
  margin-left: 9px;
  text-align: left; }

.btnFilter {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); }
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.5px solid rgba($general-text-color, 0.6); }
  &-success {
    background: $green; }
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.1); } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  &-secondary {
    background: rgba($general-text-color, 0.45); }
  &-refresh {
    min-width: unset;
    width: fit-content;
    font-size: 16px; } }
.actions {
  & > div {
    & > span {
      transition: display 0.75s;
      padding: 5px 10px;
      border-radius: 15px; }
    & > span:first-child {
      border-right: 1px solid rgba($general-text-color, 0.6); } } }
.background {
  &-primary {
    background: $primary-color; }
  &-secondary {
    background: rgba($general-text-color, 0.7); } }
.subSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  padding: 20px 0;
  width: 100%; }
.form {
  width: 100%;
  padding-right: 20px; }
.fieldset {
  padding: 0 40px 0 0 !important;
  &-dateStroke {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 20px;
    left: -20px; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; } }

.btnMessage {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: transparent !important;
  border-radius: 8px; }

.tableContainer {
  padding-top: 0.5rem !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important; }

.checkboxDescription {
  margin-top: 0.3rem !important;
  font-size: 14px !important;
  margin-left: 0.5rem !important; }

.checkboxDescription1 {
  margin-top: 0.3rem !important;
  font-size: 14px !important;
  margin-left: 0.2rem !important; }

.innerTab {
  width: max-content !important; }

.activeSettingTab {
  border-radius: 8px;
  background-color: $primary-color !important; }

.inActiveSettingTab {
  border-radius: 8px;
  background-color: #495057 !important; }

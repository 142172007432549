@import 'src/styles/variables';

.body {
  padding: 10px 40px 20px;
  font-size: 13px;
  color: rgba($general-text-color, 0.7); }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 500; }
.numberLabel {
  width: 20px;
  text-align: end; }
.greyButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: rgba($general-text-color, 0.4) !important; }
.downloadButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $primary-color !important;
  margin-left: 10px; }
.primaryButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $primary-color !important; }
.title {
  color: $general-text-color !important;
  font-size: 16px !important; }
.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }
.radioBtns {
  width: 40%;
  margin-top: 0.6rem !important; }
.radioBtns1 {
  font-size: 13px !important; }
.downloadIcon {
  opacity: 2 !important;
  height: 1.5rem;
  color: white !important;
  margin-left: -0.4rem;
  margin-top: -0.2rem;
  & path {
    stroke: white;
    fill: white !important; }
  &:hover {
    opacity: 1 !important; } }


.download {
  font-size: 14px !important;
  margin-left: 0.2rem; }

@import 'src/styles/variables';

.Card {
  border: none !important;
  .CardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border: none !important;
    border-radius: 0 !important;
    background: $grey-dark !important;
    color: white !important;
    font-size: 16px;
    font-weight: 300;
    .addButton {
      background-color: white;
      color: $default-button-txt;
      padding: 9px;
      margin-right: 20px;
      border-radius: 3px; } } }

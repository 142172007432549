@import 'src/styles/variables';

.dangerColor {
  color: $danger-color-deep; }
.closeButton {
  background: $cancel-button-bg;
  margin-right: 30px;
  color: $default-button-txt;
  font-weight: inherit;
  font-size: 16px;
  min-width: 100px;
  border-radius: 8px;
  opacity: 0.6; }
.body {
  text-align: left;
  font-size: 14px;
  letter-spacing: inherit;
  line-height: inherit;
  padding: 0 15px 15px; }
.title {
  font-size: 16px !important; }

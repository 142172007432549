@import 'src/styles/variables';

.tableContainer {
  overflow-x: none !important;
  width: 99% !important;
  justify-content: center !important;
  border: none !important;
  font-family: Roboto, sans-serif !important;
  background-color: transparent !important;
  margin-bottom: 2% !important;
  font-size: 14px;
  margin-top: 7rem !important; }


.customTable {
  width: 100% !important;
  border-collapse: collapse !important;
  display: flexbox;
  border-spacing: 0 !important;
  border: none  !important;
  border-top: 1px solid $disabled-bg-color  !important;
  background-color: transparent !important; }



.tableRow {
  border: none !important;
  height: 3rem !important;
  padding: 0.4rem !important;

  margin-top: 1rem !important;
  text-align: left !important;
  border-top: 1px solid $disabled-bg-color  !important;
  border-bottom: 1px solid $disabled-bg-color  !important; }

.tableBody {
  background-color: transparent !important; }


.tableData {
  height: 3rem !important;
  padding: 0.5rem !important;
  text-align: left !important;
  border-top: 1px solid $disabled-bg-color  !important;
  border-bottom: 1px solid $disabled-bg-color  !important;
  margin-top: 1rem !important; }



.tableHead {
  background: #495057 !important;
  color: #ffffff; }

.tableHeader {
  position: relative !important;
  padding: 0.5rem !important;
  span {
    position: absolute !important;
    right: 1rem !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    transition: transform 0.2s ease !important;

    &:hover {
      cursor: pointer !important;
      transform: translateY(-50%) scale(1.2) !important; } } }


.filterContainer {
  position: relative !important;
  margin-bottom: 1rem !important; }

.filterInput {
  padding: 0.5rem !important;
  font-size: 1rem !important;
  width: 100%  !important;
  border: 1px solid #ddd;
  border-radius: 4px !important;
  transition: all 0.3s ease-in-out !important;
  padding: 0.8rem !important;
  height: 2.5rem !important; }

.filterInput:focus {
  outline: none !important;
  border-color: #3e8bf8 !important;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5) !important; }

.filterIcon {
  position: absolute !important;
  top: 50% !important;
  right: 1% !important;
  transform: translateY(-50%) !important;
  color: #888 !important;
  transition: all 0.3s ease-in-out !important; }

.filterInput:focus + .filterIcon {
  color: #3e8bf8 !important; }

.sortIcon {
  margin-left: 5px;
  font-size: 12px;
  color: #555;
  transition: all 0.3s ease-in-out; }

.sortIcon:hover {
  color: #4CAF50 !important; }

@media screen and (max-width: 650px) {
  .filterInput {
    width: calc(100% - 40px); }

  .tableContainer {
    width: 50%; }

  tr {
    margin-top: 1rem !important;
    border: 1px solid $disabled-bg-color  !important;
    border-radius: 6px; }
  td {
    font-size: 14px; }

  .tableData {
    height: auto !important; } }

.actionButton {
  background: transparent !important;
  border: none !important;
  margin-left: -10px;

  &:hover {
    color: $primary-btn-color-subscriber; } }

@media screen and (max-width: 650px) {
  .actionRenewButton {
    margin-left: 40px !important; } }

.actionButton, {
  position: relative;

  &:active {
    border: none !important; }
  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed; } }

.actionRenewButton {
  position: relative;
  margin-left: 10px;
  background-color: #0099ff;
  &:active {
    border: none !important; } }

.actionButton:hover::before {
  content: 'View';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  background: $primary-color;
  border-radius: 8px;
  padding: 5px;
  height: 20px; }

.actionRenewButton:hover::before {
  content: 'Download';
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  background: $green;
  border-radius: 8px;
  padding: 5px;
  height: 20px; }

.actionButton:hover {
  color: transparent; }

.actionRenewButton:hover {
  color: transparent; }



.actionRenewButton {
  background: transparent !important;
  border: none !important;
  stroke: #bdc3c7 !important;
  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed; } }

.actionButton {
  background: transparent !important;
  border: none !important;

  &:hover {
    color: $primary-color-subscriber; } }

.backdropC {
  width: 100% !important;
  height: 100% !important; }

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 8px 10px;
    margin: 0 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 9px;
    &:hover {
      background-color: #2980b9;
      border: 1px solid $general-text-color; }
    &:active {
      border: 1px solid $general-text-color; }
    &:focus {
      border: 1px solid $general-text-color; }


    &:disabled {
      background-color: #bdc3c7;
      cursor: not-allowed; } }

  span {
    margin: 0 10px; }

  input {
    padding: 6px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid $general-text-color; }


  select {
    margin-left: 10px;
    padding: 6px;
    appearance: none;
    border: 1px solid #3498db;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: border 0.3s ease;

    &:focus {
      outline: none;
      border: 1px solid #2980b9; } } }


.icon {
  background: $general-text-color;
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); }
  &-cross {
    width: 18px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }
.rejectEyeIcon {
  margin-left: 8px;
  width: 27px;
  height: auto;
  fill: $red; }

.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); }
  &-primary {
    background: $primary-color-subscriber; } }

.cardfilter {
  width: 99%;
  height: 120px !important;
  padding: 13px 26px 30px 42px !important;
  border-radius: 8px !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important; }

.tab {
  width: fit-content; }


.searchField {
  height: 30px;
  box-shadow: none !important;
  background-color: rgba(36, 46, 66, 0.1) !important;
  border: 0.5px solid rgba(248, 249, 255, 0.95) !important;
  border-radius: 8px !important;
  outline: none !important;
  padding-left: 10px !important;
  &:focus {
    border: 1px solid #046dd6 !important; } }

.searchButton {
  margin-left: 1rem !important;
  width: 9%;
  height: 1.8rem !important;
  background-color: $primary-btn-color-subscriber !important;
  outline: none !important;
  border-style: solid !important;
  padding: 0.1rem !important; }

.markButton {
  width: 10rem !important;
  height: 35px !important;
  position: absolute !important;
  right: 0 !important;
  margin-right: 1rem !important;
  background-color: $primary-btn-color-subscriber !important; }

.noData {
  font-size: 1rem !important;
  text-align: center;
  margin-top: 8rem !important; }

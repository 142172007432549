.toast-container {
  position: fixed;
  right: 15px;
  bottom: 15px; }
.toast-container-center {
  width: fit-content;
  position: fixed;
  left: 55%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 80px; }
.toast-container-bottom-right {
  position: fixed;
  width: fit-content;
  max-width: width;
  bottom: 80px;
  right: 10px; }

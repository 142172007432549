@import 'src/styles/variables';

.default {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 300px;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }

.section {
  background: white;
  border-radius: 8px;
  padding: 20px 0;
  width: 100%;
  &-citation-details {
    min-height: 500px; } }

.heading {
  background: $primary-color-subscriber;
  font-size: 1.6rem;
  font-weight: 600; }

.header {
  text-align: center !important;
  justify-content: center !important;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: -0.4rem  !important;
  margin-left: 0.7rem  !important;
  margin-top: 2rem  !important; }




.backButton {
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 50;
  min-width: unset;
  height: 40px;
  padding: 10px;
  color: black !important; }

.map {
  border: 2px solid $primary-color-subscriber;
  min-height: 300px; }
.title {
  font-size: 24.9px !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  color: rgba($general-text-color, 0.5) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif;
  margin-top: 25px; }
.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }



.app {
  /* text-align: center; */
  margin: 0 auto;
  width: 50%; }


.selected-date {
  margin: 0 auto; }


.times button {
  display: block;
  background-color: #6f48eb;
  color: white;
  margin-top: 5px; }




.panelHeader {
  height: 32px;
  line-height: 40px;
  margin-top: 100px;
  color: #fff;
  font-weight: bolder;
  font-family: 'Times New Roman', Times, serif;
  margin-right: 10px; }
.panelSubHeader {
  height: 32px;
  line-height: 40px;
  margin-top: -10px;
  color: #fff;
  font-weight: bolder;
  font-family: 'Times New Roman', Times, serif;
  margin-right: 10px; }





.manageButton {
  background: $primary-btn-color-subscriber;
  font-size: 1.5rem;
  font-weight: bold;
  width: 390px;
  height: 100px;
  padding: 70px;
  color: white !important;
  border-radius: 0%;
  margin-top: 100px;
  margin-left: 50px; }




.btn {
  font-size: 15px;
  height: 40px;
  font-weight: bold;
  min-width: 199px !important;
  border-radius: 8px;
  outline: none !important;
  margin-left: 5px;
  margin-right: 20px;
  &-txt {
    color: rgba($general-text-color, 0.8) !important;

    &:hover {
      background: none !important;
      cursor: vertical-text !important; } }
  &-process {
    background: $primary-btn-color-subscriber !important;
    width: 1.5rem  !important;
    margin-left: -1.4rem !important;
    font-weight: bold;
    margin-top: -1rem !important;
    position: inherit; } }


.formInput {
  font-size: 14px;
  text-align: center;
  border-radius: 8px !important;
  box-shadow: #fd5353  !important;
  border: 1px solid #b7c6d6 !important;
  margin-left: -5%;
  margin-top: -7%; }
.table {
  width: 65% !important;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid $primary-color-subscriber !important;
  margin-left: 4%;
  font-family: 'Roboto',  sans-serif;
  position: relative;
  align-items: left !important;
  & td {
    width: 10%;
    border: 1px solid $primary-color-subscriber !important;
    font-size: 15px;
    opacity: 1.0; }
  & th {
    width: 4%;
    padding: auto !important;
    border: 1px solid white;
    background-color: $primary-color-subscriber !important;
    color: white !important;
    border-right: 1px solid $primary-color-subscriber !important;
    border-left: 1px solid $primary-color-subscriber !important; } }



.firstheader {
  border-top: 1px solid $primary-color-subscriber !important; }


.lastheader {
  border-bottom: 1px solid $primary-color-subscriber !important; }

.playWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($general-text-color, 0.45); }
.imgThumb {
  border-radius: 8px !important;
  width: 250px;
  height: 150px;
  object-fit: contain; }
.image {
  pointer-events: none; }
.text {
  width: 50%;
  min-width: 400px;
  color: rgba($general-text-color, 0.8);
  & em {
    font-style: normal;
    color: $red;
    text-style: normal; } }



.button {
  background: $primary-btn-color-subscriber;
  font-size: 18px;
  font-weight: bold !important;
  font-family: 'Roboto',  sans-serif;
  width: 240px;
  min-height: 70px !important;
  color: $default-button-txt-subscriber !important;
  margin-top: 0.1px;
  border-radius: 0 !important;
  margin-left: 80%; }

.editBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber  !important;
  font-weight: 650 !important;
  min-width: 35px;
  height: 35px;
  font-size: 14px;
  border: 1px solid $primary-color !important;
  outline: none !important;
  border-top-right-radius: 1px;
  &:hover {
    background-color: $primary-btn-color-subscriber  !important; } }

.editCol {
  display: flex !important;
  justify-content: right !important;
  margin-left: 1.7rem !important; }

.spacesTag {
  background-color: #24f181 !important;
  border-radius: 10px !important;
  justify-content: justify !important;
  margin-left: 1.2rem !important; }


.detailsRow {
  min-width: 100% !important;
  display: flex !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding: 0 !important; }

.description {
  min-width: 100% !important; }

.colBtn {
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-start !important; }


@import 'src/styles/variables';

.container {
    width: 100%;
    display: flex;
    justify-content: center; }

.cell {
    background-color: #F5F5F5;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    width: 15%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    position: relative;
    cursor: text;
    border: 1px solid #ced4da;
    border-radius: 8px; }

.form {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 3%;
    width: 100%; }

.shadows {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 2px solid $primary-color !important;
    border-radius: 8px; }


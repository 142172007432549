.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: inherit;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }

.confirmBtn {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: bold; }


.primary-bg-color {
  background: $primary-bg-color !important; }
.disabled-bg-color {
  background: $disabled-bg-color !important; }
.bg-grey-med-dark {
  background: $grey-med-dark !important; }
.bg-grey-light {
  background: $grey-light !important; }
.bg-grey-dark {
  background: $grey-dark !important; }
.bg-green {
  background: $green !important; }
.bg-red {
  background: $danger-color !important; }

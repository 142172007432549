@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 16px !important; }

.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 100px;
  height: 40px;
  font-size: 14px;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45); } }

.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }


.form {
  width: 100%;
  padding: 20px 10px 20px 30px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & > div {
    margin-left: 10px; } }

@import 'src/styles/variables';

.clockIcon {
  position: absolute;
  right: 10px;
  z-index: 1;
  pointer-events: none; }
.defaultItem {
  color: rgba($general-text-color, 0.8);
  width: 60px;
  height: 42px;
  border: unset !important;
  border-radius: 0 !important;
  cursor: pointer;
  &:focus {
    outline: none; } }
.activeItem {
  z-index: 2;
  color: #fff;
  background-color: #007bff !important; }
.list {
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center; }
.collapseCard {
  font-size: 13px;
  position: absolute;
  top: 50px;
  z-index: 10; }


@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important; }
.tableActive {
  margin-top: 24px; }
.formInput {
  border-radius: 8px !important;
  height: 40px;
  &:focus {
    box-shadow: none;
    border: 1px solid #ced4da; }
  & > button {
    border: unset !important; } }
.formLabel {
  font-weight: 600;
  margin-right: 10px; }

.button {
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  min-width: fit-content;
  border-radius: 8px;
  color: $default-button-txt;
  background: $primary-btn-color; }

.collapseSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.toolbarButton {
  margin-left: 20px;
  min-width: 43px;
  font-size: 16px;
  border-radius: 8px; }
.activeFilterTab {
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1) !important; }
.activeFilterIcon {
  & path {
    fill: rgba($general-text-color, 0.5);
    stroke: rgba(36, 46, 66, 0.5); } }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.actions {
  & > div {
    & > span {
      transition: display 0.75s;
      padding: 5px 10px;
      border-radius: 15px; }
    & > span:first-child {
      border-right: 1px solid rgba($general-text-color, 0.6); } } }
.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }
.actions {
  & > div {
    & > span {
      transition: display 0.35s; } } }
.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); }
  &-primary {
    background: $primary-color; } }

.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  color: $default-button-txt;
  background: $primary-btn-color; }

.polygonIcon {
  left: -13px;
  position: absolute !important;
  fill: #242E42 !important; }

.barIcon {
  left: -14px !important;
  position: absolute !important;
  width: 7px !important;
  height: 8px !important;
  fill: $primary-btn-color !important; }

.title {
  color: $page-header-txt; }


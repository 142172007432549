$primary-color: var(--basic_theme);
$danger-color: #FB745B;
$danger-color-deep: #c42525;
$grey-light: #fafbfd;
$grey-med-dark: #eff0f5;
$grey-dark: #495057;
$green: #27CEBB;
$snuff: #D8DBEA;
$azure: #3662A2;
$loblolly: #B7BDC8;
$athens-grey: #EEF2F4;
$steel-blue: #528EBA;
$aqua-haze: #F8FAFB;
$whisper: #ECEEF5;
$mystic: #E4E6F0;
$side-navigation-hover-color: #F8F9FA;
$orange: #F0874D;
$orange-light: #FEB95A;

$red: #EA3223;
$green: #18A164;

$general-text-color: #242E42;
$general-text-font-size: 12px;

$primary-bg-color: $primary-color;
$primary-text-color: #ffffff;

$disabled-bg-color: #C8C8C8;
$disabled-text-color: #ffffff;
$dashboard-login-button: #3A9CED;

$btn-font-size-sm: 12px;
$btn-font-size-md: 13px;
$btn-font-size-lg: 15px;

$dashboard-btn-color: #3A9CED;


// Newly Defined Variables for Applying Theme Colors for Admin Dashboard
$primary-btn-color: var(--primary_button_bg);
$default-text: var(--default_text);
$navbar-bg: var(--navbar);
$navbar-txt: var(--navbar_text);
$table-header: var(--table_header);
$table-header-txt: var(--table_header_text);
$table-items-bg-one: var(--table_items_bg_one);
$table-items-bg-two: var(--table_items_bg_two);
$table-item-txt: var(--table_item_text);
$page-header-txt: var(--page_header_text);
$default-button-txt: var(--default_button_text);
$danger-button-bg: var(--danger_button_bg);
$save-button-bg: var(--save_button_bg);
$cancel-button-bg: var(--cancel_button_bg);


// Newly Defined Variables for Applying Theme Colors for Subscriber WebApp
$primary-color-subscriber: var(--basic_theme_subscriber);
$default-text-subscriber: var(--default_text_subscriber);
$primary-btn-color-subscriber: var(--primary_button_bg_subscriber);
$navbar-bg-subscriber: var(--navbar_subscriber);
$navbar-txt-subscriber: var(--navbar_text_subscriber);
$table-header-subscriber: var(--table_header_subscriber);
$table-header-txt-subscriber: var(--table_header_text_subscriber);
$table-items-bg-one-subscriber: var(--table_items_bg_one_subscriber);
$table-items-bg-two-subscriber: var(--table_items_bg_two_subscriber);
$table-item-txt-subscriber: var(--table_item_text_subscriber);
$page-header-txt-subscriber: var(--page_header_text_subscriber);
$default-button-txt-subscriber: var(--default_button_text_subscriber);
$danger-button-bg-subscriber: var(--danger_button_bg_subscriber);
$save-button-bg-subscriber: var(--save_button_bg_subscriber);
$cancel-button-bg-subscriber: var(--cancel_button_bg_subscriber);

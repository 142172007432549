@import 'src/styles/variables';

.LocationIcon {
  position: absolute;
  right: 5px;
  top: 8px;
  stroke: $disabled-bg-color; }

.form {
  margin-top: 20px;
  padding: 0 20px; }

.map {
  border: 2px solid #fa9050;
  min-height: 300px;
  min-width: 900px;
  margin-top: 100px; }

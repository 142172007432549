@import 'src/styles/variables';

.title {
  color: $default-text-subscriber !important;
  font-size: 16px !important; }

.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: -2px !important; } } } }

.fieldsetVisit {
  & > div {
    width: 95%;
    @media screen and ( max-width: 991px ) {
      width: 100%;
      padding-right: 10px; } }
  &-right-label {
    & label {
      & > div {
        margin-left: -2px !important; } } } }


.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }

.calInput {
  display: none !important; }
.button {
  border-radius: 8px;
  min-width: 140px;
  margin-right: 5rem !important;
  height: 40px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  color: $default-button-txt-subscriber;
  background: $primary-btn-color-subscriber;
  opacity: 0.9;
  &:hover {
    opacity: 1; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg-subscriber;
    opacity: 0.45;
    &:hover {
      opacity: 0.9; }
    &:active {
      transform: scale(0.95); } } }



.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }

.form {
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-left: 37px; }

.fieldset {
  & button {}
  &-right-label {
    & label {
      & > div {
        margin-left: 10px; } } } }

.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 85%; } } } }

.fieldset-double-step3 {
   & > div {
    & > div {
      & > div:first-child {
        width: 85%; } } } }

.fieldset-valPr {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } }
  & label {
    & > div {
      margin-left: -2px !important; } } }

.fieldset-vehicle {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } }
  & label {
    & > div {
      margin-left: -2px !important; } } }

@media (min-width: 767px) and (max-width: 991px) {
  .fieldset-double-step3 {
   & > div {
    & > div {
      & > div:first-child {
        width: 100%; } } } } }

@media  (max-width: 766px) {
  .fieldset-double-step3 {
    margin-left: -10px;
    & > div {
     & > div {
       & > div:first-child {
         width: 95%; } } } } }


@media (min-width: 767px) and (max-width: 991px) {
  .fieldset-valPr {
    & > div {
      & > div {
        & > div:first-child {
          width: 85%; } } } }
  .fieldset-vehicle {
    & > div {
      & > div {
        & > div:first-child {
          width: 99%; } } } } }


@media (min-width: 767px) and (max-width: 991px) {
  .scrollableContainer {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    border: 1px solid #000000 !important;
    overflow-y: auto !important;
    height: auto  !important;
    min-width: 95.5% !important;
    margin-bottom: 50px !important;
    box-shadow: none !important;
    margin-left: -10px !important;
    margin-top: 10px !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    border: 1px solid #a9aaaa !important; } }




.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.commuterFormInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da; }

.formLabel {
  & > div {
    margin-left: -10px;
    color: #000000;
    font-weight: 100;
    font-size: 13px;
    text-align: left; } }

.body {
  padding: 10px 40px 20px;
  font-size: 13px;
  color: rgba($general-text-color, 0.7); }
.boldText {
  color: 'grey-dark';
  font-weight: bold; }
.numberLabel {
  width: 20px;
  text-align: end; }
.greyButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: rgba($general-text-color, 0.4) !important; }
.orangeButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $orange !important; }
.primaryButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $primary-btn-color-subscriber !important; }

.dragDropLabel {
  background: $default-text-subscriber;
  border-radius: 8px; }



.locationbtn {
  background-color: white !important;
  color: $primary-color-subscriber !important;
  font-weight: bolder !important;
  margin-top: -22px;
  width: fit-content !important;
  text-decoration: underline; }


.fetching {
  background-color: white !important;
  color: $primary-color-subscriber !important;
  font-weight: bolder !important;
  margin-left: 65%;
  margin-top: -22px;
  text-decoration: underline; }

.parkingLotBox {
  width: 48% !important;
  border: 1px solid #ccc !important;
  margin: 0.2rem !important;
  padding: 0.3rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #a9aaaa !important;
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }

.validParkingLotBox {
  width: 48% !important;
  border: 1px solid #ccc !important;
  margin: 0.2rem !important;
  padding: 0.3rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #38c415ef !important;
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }


.invalidParkingLotBox {
  width: 48% !important;
  border: 1px solid #ccc !important;
  margin: 0.2rem !important;
  padding: 0.3rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #f10b0bef !important;
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }



.parkingLotCell {
  display: flex !important;
  font-size: 13px !important;
  & input {
    margin-right: 0.6rem !important;
    position: relative !important;
    margin-bottom: 43px !important; }
  & span {
    flex-grow: 2 !important; }

  .uniqueCheckbox {
    margin-top: 0.2rem !important;
    font: inherit;
    color: currentColor;
    min-width: 1em !important;
    height: 1em !important;
    border: 1px solid $general-text-color !important;
    border-radius: 0.19em !important;
    transform: translateY(-0.075em); }

  .uniqueCheckbox + .form-control {
    margin-top: 1em; }

  .uniqueCheckbox::before {
    content: "";
    width: 0.55em !important;
    height: 0.55em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color-subscriber !important;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  .uniqueCheckbox:checked::before {
    transform: scale(1); } }





.scrollableContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  border: 1px solid #000000 !important;
  overflow-y: auto !important;
  height: auto  !important;
  width: 94.3% !important;
  margin-bottom: 50px !important;
  box-shadow: none !important;
  margin-left: -19px;
  margin-top: 10px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #a9aaaa !important; }



/* Add this media query to ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .parkingLotBox {
    width: 100% !important; }
  .validParkingLotBox {
     width: 100% !important; } }


.alertMsg {
  color: red !important;
  font-size: 13px !important;
  margin-top: -40px !important;
  margin-left: -2% !important;
  width: 92% !important; }


.notify {
  color: green !important;
  font-size: 13px !important;
  margin-top: -40px !important;
  margin-left: -2% !important; }

.validParkingLotsScrollable {
  overflow-y: auto;
  max-height: 130px; /* Set the desired max height */;
  width: 90% !important;
  margin-top: 10px !important;
  margin-bottom: 50px;
  box-shadow: none;
  background: #e9ecef;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.validParkingLotCell {
  display: flex;
  align-items: center;
  font-size: 12px;
  & input {
    margin-right: 0.6rem;
    position: relative;
    margin-bottom: 43px; }
  & span {
    flex-grow: 2;
    text-justify: distribute !important; } }
.validParkingLotBox {
  width: 48%; /* Adjust as needed for spacing between boxes */;
  margin: 0.2rem;
  padding: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: auto; }

.okBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -60px;
  min-width: 40px;
  margin-left: 83.2% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: red; }
  border: 0.1px solid rgba($primary-btn-color-subscriber, 0.8) !important;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.okBtnErrorStyle {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -10.3%;
  min-width: 40px;
  margin-left: 86.4% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: red; }
  border: 0.1px solid rgba($primary-btn-color-subscriber, 0.8) !important;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }


.plateInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  max-width: 45rem !important; }

@media (min-width: 768px) and (max-width: 991px) {
  .okBtn {
    margin-left: 86.3% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .okBtnErrorStyle {
    margin-top: -11.4% !important; }
  .plateInput {
    width: 100% !important; } }



@media (min-width: 576px) and (max-width: 767px) {
  .okBtn {
    margin-left: 86.3% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .okBtnErrorStyle {
    margin-top: -12% !important; }
  .plateInput {
    width: 100% !important; }
  .form {
    padding: 0 !important;
    padding-bottom: 15px !important;
    margin: 0 !important;
    margin-left: -25px !important;
    min-width: 125%  !important; } }

@media (max-width: 766px) {
  .fieldset-valPr {
    & > div {
      & > div {
        & > div:first-child {
          width: 75% !important; } } } } }

@media (max-width: 575px) {
  .okBtn {
    margin-left: 86.3% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .okBtnErrorStyle {
    margin-top: -12.1% !important; }
  .plateInput {
    width: 105% !important; } }


.detailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-left: -1.5%; }

.customLabel {
  font-weight: 1000 !important; }

.ownerDetailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-left: -6%; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px !important;
  min-width: 350px !important;
  font-weight: 1000px;
  margin-top: 10%; }


@media ( max-width: 991px ) {
  .idProofSection {
    min-width: 97% !important; } }



.boldText1 {
  color: grey-dark !important;
  font-weight: 700;
  font-size: 15px; }

.dragDropLabel2 {
  border-radius: 8px;
  margin-left: 1px;
  color: #000000;
  opacity: 0.6;
  font-weight: 100;
  font-size: 13px;
  text-align: left; }

.idProofSection {
  display: flex !important;
  justify-content: space-between !important;
  width: 92.5% !important; }

.idProofLabel {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  text-align:  left !important; }
.idProofField {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important; }




.formatsText {
  color: black;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }

.formatsErrorText {
  color: red;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }


.boldText2 {
  color: 'black';
  font-weight: 500 !important;
  font-size: 13px; }


.modalTitle {
  font-weight: 600 !important;
  color: $default-text-subscriber !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  margin-left: -3.6rem; }


.transitionContainer {
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out, overflow 0.5s ease-in-out; }

.fadeOut {
  opacity: 0;
  height: 0;
  overflow: hidden; }

.fadeIn {
  opacity: 1;
  height: auto;
  overflow: visibl; }

.fetchBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 83.3% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: -61px;
  position: relative; }

.fetchBtnErrorStyle {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 83.3% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: -101px;
  position: relative; }

@media (min-width: 768px) and (max-width: 991px) {
  .fetchBtn {
    margin-left: 86.3% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .fetchBtnErrorStyle {
    margin-left: -14% !important;
    margin-top: 48px; } }

@media (min-width: 576px) and (max-width: 767px) {
  .fetchBtn {
    margin-left: 86.3% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .fetchBtnErrorStyle {
    margin-left: -14.2% !important;
    margin-top: 48px; } }

@media (max-width: 575px) {
  .fetchBtn {
    margin-left: 86.3% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .fetchBtnErrorStyle {
    margin-top: 7.2% !important;
    margin-left: -14.2% !important; } }

.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: inherit;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }

.confirmBtn {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: bold;
  background-color: $primary-btn-color-subscriber !important;
  color: white !important; }

.formDropdownLabel {
  margin-left: -14% !important;
  color: #413e3e;
  font-weight: 100;
  font-size: 13px; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-align: left !important; }


.noteLabel {
  margin-left: -0.5rem;
  color: #000000;
  font-weight: 500;
  font-size: 13px; }

.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 10%;
  margin-right: 100px; }




.cardPreview {
  flex: 1;
  margin-right: 120px;
  margin-top: 65px; }

.cardInputs {
  flex: 1;
  margin-left: -2.2rem;
  width: 19.2rem;
  max-width: 100% !important;
  margin-top: -8%; }

.cardLabels {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: justify !important; }

.cardInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 40px;
  width: 100%;
  max-width: 100%;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 13px;
  &:focus {
    border: 1px solid #046dd6; }
  ::-ms-input-placeholder, :-ms-input-placeholder, ::placeholder {
    color: #00f !important; } }


.expiryDropdown {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  max-width: 19rem !important;
  width: 19rem !important;
  font-size: 13px; }

.expiryDropdownLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #000000;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  justify-content: center !important;
  text-align: justify !important; }

.cardLabels2 {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #5e5858;
  font-weight: 100;
  font-size: 13px;
  margin-top: 5px !important;
  justify-content: center !important;
  text-align: justify !important; }

.commuterLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  justify-content: left !important;
  text-align: left !important; }

.collapsable {
  margin-left: 10px;
  min-width: 500px !important; }


@media (min-width: 767px) and (max-width: 991px) {
  .headerClass {
    margin-left: -1px !important;
    min-width: 720px !important; }
  .collapsable {
    margin-left: -55px !important;
    min-width: 720px !important; } }


@media (min-width: 726px) and (max-width: 766px) {
  .headerClass {
    margin-left: -1px !important;
    min-width: 710px !important; }

  .collapsable {
    margin-left: -65px !important;
    min-width: 710px !important; } }

@media (min-width: 719px) and (max-width: 726px) {
  .headerClass {
    margin-left: -1px !important;
    min-width: 695px !important;
    max-width: 695px !important; }

  .collapsable {
    margin-left: -63px !important;
    min-width: 695px !important;
    max-width: 695px !important; } }


@media (max-width: 718px) {
  .headerClass {
    margin-left: -1px !important;
    min-width: 650px !important;
    max-width: 650px !important; }

  .collapsable {
    margin-left: -45px !important;
    min-width: 650px !important;
    max-width: 650px !important; } }

.commuterTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 15px !important;
  text-align: center !important;
  margin-right: 5.3rem !important; }


.addVehicleBtn {
  background-color: transparent !important;
  color: $primary-color-subscriber  !important;
  font-weight: 650 !important;
  margin-top: 1px;
  min-width: 40px;
  margin-left: 0.7% !important;
  height: 40px;
  font-size: 13px;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border-radius: 8px !important; }


.formatsPresenceErrorText {
  color: #FB745B !important;
  font-size: 13px !important;
  margin-top: 10px !important; }


.checkboxText {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 4em;
  margin-left: -1.5rem !important; }





.secondaryCheck {
  margin-left: 1px !important; }


.autorenewFields {
  margin-left: -6px !important;
  margin-top: 1rem !important;
  border: none  !important; }


.saveData {
  margin-left: 4rem !important;
  margin-top: 20px !important; }

.savecheckboxText {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1 !important;
  margin-top: 1rem !important;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 3em;
  margin-left: -1.5rem !important; }


.saveCheckText {
  margin-top: 0.15rem !important;
  margin-left: -4rem !important; }

.applyCBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -2rem !important;
  min-width: 18% !important;
  max-width: 18% !important;
  height: 35px;
  margin-left: 40% !important;
  line-height: 1.1 !important;
  font-size: 14px;
  position: absolute !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: red; }
  border: 0.1px solid $primary-color-subscriber !important;
  border-radius: 6px !important; }

.dateCsv {
  margin-top: 1.2rem !important; }

.couponValidation {
  font-size: 12px !important;
  color: #FB745B !important;
  opacity: 0.9 !important;
  margin-left: 72% !important; }

.checkbox {
  margin-left: -1.7rem !important; }

.couponApplied {
  font-size: 12px !important;
  color: green !important;
  margin-left: 72% !important;
  opacity: 0.9 !important;
  margin-bottom: -2rem !important; }

.discount-color {
  color: $red; }


.spacesSection {
  margin-left: 0.1rem !important;
  font-family: Roboto, sans-serif;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  padding: 10px 20px 0 10px !important;
  font-size: 13px  !important;
  border-radius: 5px !important; }

.totalSpacesText {
  text-align: left !important; }

.countNumber {
  font-size: 13px;
  font-weight: bold !important;
  color: $primary-color-subscriber !important;
  margin-left: 1rem; }

.totalBookedSpacesText {
  font-size: 13px !important;
  text-align: right !important; }

.totalNumberVehiclesText {
  font-size: 13px !important;
  text-align: right !important; }

.infoSection {
  border: 1px solid rgba($general-text-color, 0.3) !important;
  min-width: 58% !important;
  border-radius: 8px;
  text-align: left !important;
  max-height: 3rem !important;
  font-size: 13px  !important; }

@media (max-width: 991px) {
  .infoSection {
    border: 1px solid rgba($general-text-color, 0.3) !important;
    min-width: 70% !important;
    border-radius: 8px;
    margin-left: -2%;
    text-align: left !important;
    max-height: 3rem !important;
    font-size: 13px  !important; } }



.addBtn {
  background-color: #f5610cd2;
  min-width: 4rem !important;
  margin-top: 0.5rem !important;
  border-radius: 8px;
  font-weight: 500 !important;
  padding: 15px !important; }



.addIcon {
  height: 1rem !important;
  margin-top: -0.25rem;
  margin-left: -0.5rem; }


.currentLoc {
  display: flex !important;
  justify-content: flex-end  !important;
  width: 93.5% !important; }


@media (max-width: 991px) {
  .currentLoc {
    width: 95% !important; } }

@media (max-width: 766px) {
  .currentLoc {
    width: 92% !important; }
  .couponValidation {
    margin-left: 75.5% !important; }
  .couponApplied {
    margin-left: 75.5% !important; } }


.ccNumField {
  font-size: 13px !important; }

.placeholders {
  font-family: Roboto, sans-serif !important;
  font-size: 13px; }


::placeholder {
  color: $general-text-color !important;
  opacity: 0.5 !important; }

.autorenewLabell {
  font-size: 13px !important; }

.checkboxTextRenew {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1; }


.renewCol {
  display: flex !important;
  justify-content: space-between !important;
  padding-left: 0 !important;
  margin-left: 9px !important;
  margin-right: 10px !important; }
.renewLabelCol {
  display: flex !important;
  justify-content: flex-start !important;
  margin-top: 3px;
  margin-left: 10px;
  min-width: 500px; }

.renewCheckCol {
  display: flex !important;
  justify-content: left !important;
  margin: 0 !important;

  .uniqueCheckbox {
    margin-top: 0.2rem !important;
    font: inherit;
    color: currentColor;
    width: 0.9em !important;
    height: 0.9em !important;
    border: 1px solid $general-text-color !important;
    border-radius: 0.19em !important;
    transform: translateY(-0.075em);
    margin-left: 1.2px; }

  .uniqueCheckbox + .form-control {
    margin-top: 1em; }

  .uniqueCheckbox::before {
    content: "";
    width: 0.55em !important;
    height: 0.55em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color-subscriber !important;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  .uniqueCheckbox:checked::before {
    transform: scale(1); } }

.applyCol {
  display: flex !important;
  justify-content: space-between !important;
  padding-left: 0 !important;
  margin-left: 390px !important;
  margin-right: 10px !important; }
.couponLabelCol {
   display: flex !important;
   min-width: 90px !important;
   margin-top: -1.5rem !important; }


.couponInputCol {
  display: flex !important;
  justify-content: flex-start !important; }

.autoRenewRow {
  display: flex !important;
  width: 90% !important;
  margin-top: 10px; }

.applyLabel {
  width: 85px !important;
  color: #535252;
  font-weight: 100;
  font-size: 13px; }

.couponInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 35px;
  margin-left: -8% !important;
  width: 210px !important;
  outline: none;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 14px;
  margin-top: -2rem !important;
  &:focus {
    border: 1px solid #046dd6; } }



@media (min-width: 767px) and (max-width: 991px) {
  .fieldset-double {
    & > div {
      & > div {
        & > div:first-child {
          width: 92% !important; } } } }

  .couponValidation {
    margin-left: 78.5% !important; }
  .couponApplied {
    margin-left: 78.5% !important; }


  .form {
    width: 90%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-left: 30px !important; }

  .modalTitle {
    font-weight: 600 !important;
    color: #242E42 !important;
    opacity: 0.8 !important;
    font-size: 16px !important;
    margin-left: -2rem; }

  .couponInput {
    margin-left: 2% !important; }
  .applyCBtn {
    margin-left: 50% !important; }


  .form {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-left: 30px !important; } }

@media (max-width: 766px) {
  .fieldset-double {
    & > div {
      & > div {
        & > div:first-child {
          width: 88%; } } } }

  .form {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
    padding-bottom: 20px; }

  .modalTitle {
    font-weight: 600 !important;
    color: #242E42 !important;
    opacity: 0.8 !important;
    font-size: 16px !important;
    margin-left: -4rem; }

  .couponInput {
    margin-left: -5% !important;
    width: 170px !important; }

  .applyCol {
    display: flex !important;
    justify-content: space-between !important;
    padding-left: 0 !important;
    margin-top: -2px;
    margin-left: 340px !important;
    margin-right: 10px !important; }
  .cardInputs {
    flex: 1;
    margin-left: -3.2rem;
    width: 15rem;
    max-width: 100% !important;
    margin-top: -8%; }

  .expiryDropdown {
    width: 15rem !important;
    max-width: 15rem !important; }
  .renewCol {
    margin-left: 20px !important; }
  .scrollableContainer {
    width: 93% !important; }
  .applyCBtn {
    margin-left: 40% !important; } }







@media (width: 767px) {
  .fieldset-double {
    & > div {
      & > div {
        & > div:first-child {
          width: 95%; } } } }

  .form {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-left: -28px !important; }

  .modalTitle {
    font-weight: 600 !important;
    color: #242E42 !important;
    opacity: 0.8 !important;
    font-size: 16px !important;
    margin-left: -6.6rem; }

  .couponInput {
    margin-left: 6% !important; }

  .applyCol {
    display: flex !important;
    justify-content: space-between !important;
    padding-left: 0 !important;
    margin-top: -3px !important;
    margin-left: 345px !important;
    margin-right: 10px !important; }
  .applyCBtn {
    margin-left: 55% !important; }
  .cardInputs {
    flex: 1;
    margin-left: -3.2rem;
    width: 15rem;
    max-width: 100% !important;
    margin-top: -8%; }

  .expiryDropdown {
    height: 40px;
    border-radius: 8px !important;
    box-shadow: none !important;
    text-align: left !important;
    width: 18rem !important;
    font-size: 13px;
    &:hover {
        border: none !important; }
    &:active {
      border: none !important; } }
  .renewCol {
    margin-left: 20px !important; } }

.prevBtn {
  margin-left: -2.5rem !important; }

.gpayBtn {
  margin-left: -2rem !important;
  margin-top: 20px;
  margin-bottom: 20px; }
.formSingleInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
 }  // max-width: "10%" !important

.deleteIdProofButton {
  position: absolute;
  top: 1px;
  right: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba($red, 0.6);
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  min-width: 35px !important; }

.removeIcon {
  color: red;
  height: 15px; }

.fetchBtn1 {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 80% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: -60px;
  position: relative; }

.fetchBtnErrorStyle1 {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 85.3% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: -101px;
  position: relative; }

.customLabelvehicle {
 }  // padding-left: 0px !important


@import 'src/styles/variables';

.transactionModal {
  font-family: 'Roboto', sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center !important;
  color: $general-text-color;
  border-radius: 8px !important; }
.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }



.button {
  border-radius: 8px;
  min-width: 120px;
  height: 40px;
  font-size: 14px;
  background: $cancel-button-bg-subscriber;
  color: $default-button-txt-subscriber;
  opacity: 0.45;
  margin-left: 10px;
  &:hover {
    background: $cancel-button-bg-subscriber;
    opacity: 0.8;
    transition: 0.1; } }

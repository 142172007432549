@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 16px !important; }

.form {
  width: 100%;
  padding: 30px 20px 20px 40px; }

.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  & button {
    height: 40px;
    border: unset !important; }
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }

.inputLabel {
  & > div {
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }

.inputPhoneLabel {
  text-align: left;
  color:  !important;
  font-size: 13px !important;
  margin-left: -23.1rem !important;
  margin-top: 0.7rem !important;
  text-align: justify !important; }


.primary-color {
  color: $primary-color-subscriber; }

.button {
  border-radius: 8px;
  min-width: 100px;
  height: 40px;
  font-size: 14px;
  margin-right: 13px;
  background-color: $primary-btn-color-subscriber;
  color: $default-button-txt-subscriber !important;
  font-weight: 500;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6); } }
.iconInput {
  padding-left: 28px !important; }

.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }

.phoneInput {
  width: 80% !important;
  margin-top: -2.5rem !important;
  margin-left: 13.3rem !important; }

.phoneInputClass {
  width: 17.49rem !important;
  height: 2.5rem !important; }

.dropdown {
  font-size: 13px !important;
  max-width: 740% !important;
  margin: 0 !important;
  margin-top: 3px !important;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  text-align: justify !important; }

.search {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;

  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 8px !important;
  line-height: 25px !important;
  outline: none !important; }

.buttonC {
  border: 1px solid #CACACA;
  border-radius: 10px; }

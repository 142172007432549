@import 'src/styles/variables';

.addPicture {
  cursor: pointer;
  background: rgba($primary-bg-color, 0.7);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center; }

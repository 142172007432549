@import 'src/styles/variables';

.title {
  color: $default-text-subscriber !important;
  font-size: 16px !important; }

.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }

.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 100px;
  height: 40px;
  font-size: 14px;
  margin-right: 10px;
  background-color: $primary-btn-color-subscriber;
  color: $default-button-txt-subscriber;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg-subscriber !important;
    opacity: 0.45; } }


.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  height: 40px;
  font-size: 14px;
  margin-left: 1px;
  background-color: $cancel-button-bg-subscriber;
  color: $default-button-txt-subscriber;
  opacity: 0.45; }

.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }


.form {
  width: 100%;
  padding: 20px 10px 20px 30px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & > div {
    margin-left: 10px; } }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-align: left !important;
  &:hover {
    border-color: none !important; }
  &:active {
    border-color: none !important; } }

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: -10px !important;
  padding-top: 0 !important;
  align-items: flex-start !important; }

.tag {
  cursor: pointer;
  background: $primary-color-subscriber;
  transition: background 0.3s;
  color: $default-button-txt-subscriber;
  border-radius: 8px;
  opacity: 1;
  &:hover {
    background: $primary-color-subscriber;
    border: 1px solid $primary-color-subscriber; } }
.expiryDropdown {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  font-size: 13px; }


.expiryDropdownLabel {
  margin-bottom: 4px;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  justify-content: left !important;
  text-align: left !important; }

.cardLabels2 {
  margin-bottom: 4px;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  justify-content: left !important;
  text-align: left !important; }

.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 4%; }


.cardPreview {
  flex: 1;
  margin-right: 50px;
  margin-top: -10px;
  margin-left: 3.5rem;
  width: 100px !important; }

.cardInputs2 {
  flex: 1;
  margin-left: 4rem;
  margin-right: 1rem;
  margin-top: -2.5rem;
  width: 17.5rem;
  max-width: 100% !important;
  max-height: 28rem;
  overflow-y: auto  !important;
  overflow: hidden; }


.cardInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 40px;
  width: 100%;
  max-width: 100%;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 13px;
  &:focus {
    border: 1px solid $primary-color-subscriber; }
  ::-ms-input-placeholder, :-ms-input-placeholder, ::placeholder {
    color: $primary-color !important; } }

.cvcLabel {
  display: block;
  color: #7a7878;
  font-weight: 100;
  margin-top: -10px;
  font-size: 13px;
  justify-content: left !important;
  text-align: left !important; }


.gpayBtn {
  margin-top: -15px;
  margin-right: 10px; }

.cardLabels {
  display: block;
  margin-bottom: 0.4rem !important;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: left !important;
  text-align: left !important; }


.expirySection {
  display: flex !important;
  align-items: center !important;
  padding-top: 15px; }


.content {
  text-align: center;
  font-size: 14px; }
.body {
  font-family: Roboto, sans-serif !important; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px; }

.successTitle {
  color: green;
  font-size: 16px;
  text-align: center !important;
  font-weight: 500 !important; }

.failedStatus {
  color: red;
  font-size: 16px;
  text-align: center !important;
  font-weight: 500 !important; }

@import 'src/styles/variables';

.verificationTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-family: Roboto, sans-serif !important;
  color: #242E42; }

.verificationSubtitle {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-family: Roboto, sans-serif !important;
  color: #242E42; }

.button {
  color: #FFFFFF !important;
  background: #3A9CED !important;
  &:hover {
    background: 1px solid rgba(#3A9CED, 0.5) !important; } }

.disabledBg {
  background: rgba(#242E42, 0.7) !important;
  opacity: 0.45 !important;
  color: #FFFFFF !important; }

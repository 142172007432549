@import 'src/styles/variables';

.tabs {
  width: fit-content; }
.form {
  width: 35%; }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  font-size: 13px;
  height: 48px !important; }
.mainContent {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }
.submitBtn {
  border-radius: 8px;
  height: 48px;
  font-size: 14px;
  color: $default-button-txt;
  background: $save-button-bg; }
.header {
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  color: rgba($general-text-color, 0.45); }
.eye-icon {
  fill: $primary-color; }
.credentials {
  align-self: start;
  padding: 60px 0 0 50px;
  font-size: 15px;
  width: 100%;
  & dd, dt {
    color: rgba($general-text-color, 0.9);
    margin-bottom: 16px; }
  & dt {
    float: left;
    margin-left: 10px;
    margin-right: 20px; }
  & p {
    color: $general-text-color;
    font-size: 18px;
    font-weight: 600; } }

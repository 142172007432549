@import 'src/styles/variables';

.title {
  font-size: 16px !important;
  text-align: center; }
.form {
  padding: 20px 20px 20px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.input1 {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  width: 104% !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel1 {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.input2 {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  width: 104% !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel2 {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  max-width: 165px !important; }
.cancelButton {
  background-color: rgba($general-text-color, 0.4) !important;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px; }
.pending-color {
  color: rgba($general-text-color, 0.45); }
.cancelled-color {
  color: $red; }
.approved-color {
  color: $green; }
.under_review-color {
  color: $orange; }
.rejected-color {
  color: $red; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-left: 20px; }
.imgThumb {
  border-radius: 8px !important;
  width: 230px;
  height: 140px;
  object-fit: contain;
  background: #dddddd;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.image {
  pointer-events: none;
  border: 1px solid;
  background: #fff;
  &:hover {
    border: 1px solid $primary-color;
    outline: 0; } }
.dragDropLabel {
  border-radius: 8px !important;
  max-width: 79.5% !important;
  font-weight: 1000px;
  height: 120px !important;
  position: relative !important;
  border: 1px solid #ced4da !important;
  margin-top: -2rem !important;
  font-size: 13px !important;
  margin-bottom: 1.5rem !important; }
.uploadField {
  margin-left: 148px; }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.toggleIcon {
  margin-left: 14px !important;
  margin-top: -23px !important; }
.toggleLable {
  text-align: left;
  color: #242E42 !important;
  font-size: 13px !important; }
.toggleStatus {
  font-weight: inherit;
  margin-left: 80px !important;
  margin-top: -23px !important; }
.img {
  height: 90px !important;
  width: 160px !important;
  border: none !important;
  margin-top: -0.6rem !important;
  margin-right: 18rem !important;
  margin-left: -0.4rem !important;
  border-radius: 5px !important; }

.fileName {
  font-size: 13px !important;
  margin-left: 3rem !important; }

.uploadText {
  margin-top: -5.8rem !important;
  font-size: 13px !important;
  margin-left: 10rem !important; }
.cloudIcon {
  margin-top: -12rem !important;
  margin-left: 9rem !important;
  height: 30px !important; }
.background {
  &-danger {
    color: red  !important; }
  &-primary {
    color: blue  !important; } }

.iconInput {
  width: 75% !important;
  margin-bottom: -2rem !important;
  margin-top: -0.2rem !important; }

.checkDetailsLink {
  font-size: 13px !important;
  margin-left: 20rem !important;
  margin-top: 3.5rem !important;
  color: #3A9CED !important; }

.deleteField {
  height: 7.5rem !important;
  border: 1px solid #ced4da !important;
  margin-top: -2rem !important;
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-left: hidden !important;
  width: 2rem !important;
  padding-top: 7px !important; }

.dragDropLabel1 {
  border-radius: 8px !important;
  max-width: 77.5% !important;
  font-weight: 1000px;
  height: 120px !important;
  position: relative !important;
  border: 1px solid #ced4da !important;
  margin-top: -2rem !important;
  font-size: 13px !important;
  margin-bottom: 1.5rem !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-right: white !important; }

.uploadContainer {
  display: flex;
  margin-left: -2.5%;
  width: 34rem;
  margin-top: 0.5rem; }

.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.fieldset {
  padding: 0 40px 0 0 !important;
  &-dateStroke {
    width: 15px;
    border: 1px solid #c9c9c9 !important;
    top: 3.5rem;
    left: 17.8rem; } }
.checkboxText {
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: 1.6rem !important;
  padding-right: 1rem !important; }
.customCheckStyle {
  appearance: none;
  margin-top: 0.1rem !important;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 1rem !important;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

.checkOuter {
  margin: 10px !important;
  margin-left: 0px !important; }

.accountFieldset {
  & > div {
    & > div {
       margin-left: 5px !important; } } }

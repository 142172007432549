@import 'src/styles/variables';
.container {
  margin: auto;
  font-family: Roboto, sans-serif !important;
  background-color: #f2f3f6 !important; }

.listDataCard {
  padding: 1.5rem !important; }

.dataCardContainer {
  padding: 7.5px !important; }

.frameContainer {
  overflow: hidden !important;
  background-color: transparent !important; }

.toolBarCard {
  background-color: rgba(233, 235, 240, 0.6) !important; }

.borderPrimary {
  padding: 40px 30px 20px 30px !important;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 8px !important;
  margin: 7px !important;
  height: 96% !important;
  box-shadow: 0px 1px 2px 0px #606C801A; }

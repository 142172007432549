@import 'src/styles/variables';

.title {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }
.form {
  padding: 10px 50px 20px; }
.fieldset {
  width: 50%;
  padding: 0 16px;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.fieldset-double {
  margin: 0px !important; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.category {
  font-size: 15px; }
.input {
  box-shadow: none !important;
  border: 1px solid $primary-color-subscriber !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid $primary-color-subscriber !important; }
  & button {
    height: 40px;
    border: unset; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.primary-color {
  color: $primary-color-subscriber; }
.cancelled-color {
  color: rgba($general-text-color, 0.45); }
.success-color {
  color: $green; }
.revoked-color {
  color: $red; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  color: $default-button-txt-subscriber;
  background: $primary-btn-color-subscriber;
  &-sm {
    min-width: 120px; }
  &-bg-orange {
    background: $orange; }
  &-bg-secondary {
    background: $cancel-button-bg-subscriber;
    opacity: 0.45; } }

.subdetailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 13.6px !important; }

.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 10%; }




.cardPreview {
  flex: 1;
  margin-right: 50px;
  margin-top: -1rem;
  margin-left: 4.6rem;
  width: 100px !important; }

.cardInputs {
  flex: 1;
  margin-left: 4.9rem;
  margin-top: 1%;
  width: 18rem;
  max-width: 100% !important;
  max-height: 28rem;
  overflow-y: auto  !important;
  overflow: hidden; }

.cardInputs2 {
  flex: 1;
  margin-left: 4.9rem;
  margin-right: 1rem;
  margin-top: 1%;
  width: 18rem;
  max-width: 100% !important;
  max-height: 28rem;
  overflow-y: auto  !important;
  overflow: hidden; }



.cardLabels {
  display: block;
  margin-bottom: 0.4rem !important;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: left !important;
  text-align: left !important; }

.cardInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 40px;
  width: 100%;
  max-width: 100%;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 13px;
  &:focus {
    border: 1px solid #046dd6; }
  ::-ms-input-placeholder, :-ms-input-placeholder, ::placeholder {
    color: #00f !important; } }

.expiryDropdown {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  width: 18rem !important;
  font-size: 13px;
  &:hover {
      border: none !important; }
  &:active {
    border: none !important; } }

.expiryDropdownLabel {
  margin-bottom: 4px;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  justify-content: left !important;
  text-align: left !important; }

.cardLabels2 {
  margin-bottom: 4px;
  font-weight: 100;
  font-size: 13px;
  justify-content: left !important;
  text-align: left !important; }

.cvcLabel {
  display: block;
  color: #7a7878;
  font-weight: 100;
  margin-top: -10px;
  font-size: 13px;
  justify-content: left !important;
  text-align: left !important; }



.gpayBtn {
  margin-left: -0.9rem;
  margin-top: 1rem; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-align: left !important;
  &:hover {
      border-color: none !important; }
  &:active {
    border-color: none !important; } }

.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  margin-left: 1.6rem; }

.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: inherit;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }

.confirmBtn {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 10px 20px;
  background-color: $primary-color-subscriber !important;
  color: white; }

.expiryDropdownInnerModal {
  height: 40px;
  border-radius: 8px !important;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
  text-align: left !important;
  max-width: 19.5rem !important;
  width: 18rem;
  font-size: 13px; }

.expiryDropdownInnerModal2 {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  max-width: 19.5rem !important;
  width: 17.05rem;
  font-size: 13px; }

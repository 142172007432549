@import 'src/styles/variables';

.form {
  width: 100%;
  padding-right: 20px; }
.fieldset {
  padding: 0 40px 0 0 !important; }

.fieldset2 {
  margin-left: -16rem !important; }

.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.formEntryTimeInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 46% !important; }

.formExitTimeInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 46% !important;
  margin-left: -9.5rem !important; }




.formLabel {
  & div {
    margin-left: 20px; } }
.subSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  padding: 20px 0;
  width: 100%; }
.disputeButton {
  font-size: 16px;
  background: $primary-color;
  border-radius: 8px;
  color: $default-button-txt; }
.btnFilter {
  margin-right: 20px;
  min-width: 43px;
  font-size: 16px;
  color: $default-button-txt;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color;
    fill: $general-text-color; } }
.icon {
  border-block-color: tr;
  & path {
    fill: white;
    stroke: white; }
  &-fade {
    opacity: 0.85; } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  color: $default-button-txt;
  background: $save-button-bg; }
.background {
  &-primary {
    background: $primary-color !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }
.playWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($general-text-color, 0.45); }
.imgThumb {
  border-radius: 8px !important;
  width: 250px;
  height: 150px;
  object-fit: contain; }
.image {
  pointer-events: none; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }


.entryL {
  font-size: 13px !important;
  margin-left: 48.2% !important;
  margin-top: -4.5rem !important;
  color: rgba($general-text-color, 0.5); }

.exitL {
  font-size: 13px !important;
  margin-left: 58.2% !important;
  margin-top: -4.5rem !important;
  color: rgba($general-text-color, 0.5); }


.checkboxText {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 3em; }


.customCheckBox {
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

.secondaryCheck {
  margin-left: 22rem !important; }


.refundFields {
  margin-left: 34.5% !important;
  max-width: 50% !important;
  width: 30% !important;
  margin-top: -110px !important;
  border: 1px solid #ced4da !important;
  height: 4.7rem !important;
  border-radius: 8px !important;
  background: white !important; }


.saveData {
  margin-left: 4rem !important;
  margin-top: 20px !important; }

.savecheckboxText {
  color: rgba($general-text-color, 0.9);
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.5 !important;
  margin-top: 1.5rem !important;
  display: grid;
  grid-template-columns: 1em auto;
  align-items: center !important;
  gap: 3em;
  margin-left: 1% !important; }



.saveCheckText {
  margin-top: -0.1rem !important;
  margin-left: -1.8rem !important;
  display: flex !important; }
.cost {
  border-radius: 8px !important;
  width: 75% !important;
  height: 2rem !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  margin-top: 1rem !important;
  margin-right: 2rem !important;
  background: #ced4da !important;
  font-size: 13px !important;
  color: black !important;
  padding: 10px; }

.amount {
  border-radius: 8px !important;
  height: 2rem !important;
  width: 75% !important;
  box-shadow: none !important;
  border: 2px solid #ced4da !important;
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  outline: none !important;
  font-size: 13px !important;
  padding-left: 0.5rem !important;
  padding: 10px; }


.costL {
  font-size: 13px !important;
  color: rgba($general-text-color, 0.8);
  margin-left: 1.5rem !important;
  margin-top: 0.4rem !important; }

.refundL {
  font-size: 13px !important;
  color: rgba($general-text-color, 0.7);
  margin-left: 1.5rem !important;
  margin-top: 0.4rem !important;
  padding-bottom: 5px; }



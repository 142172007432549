@import 'src/styles/variables';

.form {
  margin-top: 20px;
  padding: 0 20px;
  background-color: #E8E8E8;
  border-radius: 15px;
  width: 110%; }

.heading {
  font-weight: 600 !important;
  color: #25272b94 !important;
  opacity: 0.6 !important;
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 7px;
  font-family: Roboto, sans-serif !important; }


.icon {
  margin-right: 8px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: inline-block;
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.3); }
  &-orange {
    background: $orange; }
  &-success {
    background: $green; }
  &-default {
    background: rgba($general-text-color, 0.7); } }


.formInput {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }

.formLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important;
  font-family: Roboto, sans-serif !important; }


.button {
  border-radius: 8px;
  font-size: 15px;
  font-weight: 100;
  font-family: Roboto, sans-serif !important;
  padding: 20px;
  width: 150px;
  background: $primary-btn-color;
  color: $default-button-txt;
  &-disabled {
    background: $cancel-button-bg;
    opacity: 0.7; } }


.btn {
  min-width: fit-content;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  transition: background .5s;
  color: $default-button-txt;
  background: $primary-btn-color;
  & svg {
    fill: white; }
  &-primary {
    background: $primary-btn-color; }
  &-disabled {
    background: rgba($general-text-color, 0.6);
    &:hover {
      background: $danger-color; } } }

.main {
  background-color: #E8E8E8;
  border-radius: 8px;
  &.header {
    font-weight: 500 !important;
    color: #25272b94 !important;
    opacity: 0.6 !important;
    font-size: 15px;
    margin-bottom: 5px; } }

.fields {
  display: grid; }

.fieldset {
  width: 100%;
  padding: 0 16px;
  position: relative;
  &-double {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr; }
  &-double1 {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr; }
  &-stroke {
    width: 15px !important;
    height: 1px !important;
    right: 2rem;
    top: 20px;
    border: 1.5px solid rgba($general-text-color, 0.65); }
  &-stroke2 {
    width: 15px;
    height: 1px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    right: 2rem;
    top: 20px; } }
.title {
  color: $page-header-txt;
  font-size: 16px;
  justify-content: center;
  text-align: justify; }


.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }

.createAtFields {
  display: flex;
  margin-right: -1.4rem;
  margin-left: -1.8rem; }

.updatedAtFields {
  display: flex;
  margin-right: -1rem;
  margin-left: -2.2rem; }


.doubleLabel {
  display: inline-block;
  padding-top: 15px;
  font-size: 13px !important;
  margin-left: 18px; }

.doubleLabel2 {
  display: inline-block;
  padding-top: 15px;
  font-size: 13px !important;
  margin-left: 14px; }


.fields2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }


@media (min-width: 992px) and  ( max-width: 1600px  ) {
  .createAtFields {
    margin-left: -1.1rem !important;
    margin-right: -0.6rem !important; }

  .updatedAtFields {
    margin-left: -1.1rem !important;
    margin-right: -0.6rem !important; }

  .fieldset {
    &-stroke {
      right: 1.5rem  !important; }
    &-stroke2 {
      right: 1.5rem  !important; } }


  .doubleLabel {
    padding-top: 1px !important;
    width: 10px  !important;
    line-height: 1.5 !important; }

  .doubleLabel2 {
    padding-top: 1px !important;
    width: 10px  !important;
    line-height: 1.5 !important; } }

@media  ( max-width: 991px ) {
  .fieldset {
    &-stroke {
      display: none !important; }
    &-stroke2 {
      display: none !important; }
    &-double1 {
      position: relative;
      display: table-row;
      margin-top: -10px;
      margin-left: -22.6rem; } }

  .createAtFields {
    margin-left: 1rem !important;
    margin-right: -10rem !important; }
  .updatedAtFields {
    margin-left: 8.3rem !important;
    margin-top: -2.5rem; }

  .doubleLabel {
    padding-top: 1px !important;
    width: 10px  !important;
    line-height: 1.5 !important; }
  .doubleLabel2 {
    padding-top: 80px !important;
    width: 10px  !important;
    line-height: 1.5 !important; }
  .fields2 {
    display: flex; } }


@media  ( max-width: 1460px ) {
  .fieldset {
    &-stroke {
      bottom: 60%; } } }






@import 'src/styles/variables';

.stream {
    width: 100%;
    margin: 25px;
    min-height: 300px; }

.live {
    background-color: #DE856C;
    color: $primary-text-color;
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 15px;
    padding: 0% 2%; }
.timestamp {
    color: $primary-text-color;
    position: absolute;
    bottom: 40px;
    left: 40px;
    font-size: 15px;
    padding: 0% 2%;
    & p {
        margin: 0; } }
.pencil {
    right: 24px; }

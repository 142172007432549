@import 'src/styles/variables';

.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $cancel-button-bg !important;
  color: $default-button-txt;
  opacity: 0.4; }
.submitButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $red !important; }
.content {
  text-align: center;
  font-size: 14px; }
.body {
  padding: 10px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px; }

@import 'src/styles/variables';
.modalContent {
  padding: 10px;
  font-family: Roboto, sans-serif !important; }

.modalTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px; }

.modalItem {
  margin-bottom: 20px;
  margin-top: 30px !important;
  display: flex;
  flex-wrap: wrap; } // Wrap modal items to next line if necessary

.modalLabel {
  font-weight: 500 !important;
  color: $default-text-subscriber;
  opacity: 0.6;
  margin-right: 10px;
  margin-left: 5px;
  width: 130px; } // Fixed width for modal labels

.modalLabelRight {
  font-weight: 500 !important;
  color: $default-text-subscriber;
  opacity: 0.6;
  margin-right: 10px;
  margin-left: 50px;
  width: 70px; } // Fixed width for modal labels

@media (max-width: 540px) {
  .modalLabelRight {
    margin-right: 10px;
    margin-left: 5px; } }

@media (max-width: 382px) {
  .modalLabelRight {
    margin-right: 10px;
    margin-left: 5px;
    width: 130px;
    margin-top: 25px; }

  .modalValueRight {
    margin-top: 25px; } }

@media (min-width: 412px) and (max-width: 475px) {
  .modalLabelRight {
    margin-right: 0px;
    margin-left: 12px;
    width: 80px; }
  .modalLabel {
    font-weight: 500 !important;
    color: $default-text-subscriber;
    opacity: 0.6;
    margin-right: 1px;
    margin-left: 5px;
    width: 99px; } // Fixed width for modal labels
  .modalValueRight {
    margin-top: 1px; }
  .modalValue {
    margin-left: 2px; } }

@media (min-width: 383px) and (max-width: 412px) {
  .modalLabelRight {
    margin-right: 0px;
    margin-left: 10px;
    width: 65px; }
  .modalLabel {
    font-weight: 500 !important;
    color: $default-text-subscriber;
    opacity: 0.6;
    margin-right: -1px;
    margin-left: 5px;
    width: 100px; } // Fixed width for modal labels
  .modalValueRight {
    margin-top: 1px; } }

@media (max-width: 300px) {
  .modalLabelRight {
    margin-right: 10px;
    margin-left: 5px;
    width: 100px;
    margin-top: 25px; }
  .modalLabel {
    font-weight: 500 !important;
    color: $default-text-subscriber;
    opacity: 0.6;
    margin-right: -1px;
    margin-left: 5px;
    width: 100px; } // Fixed width for modal labels

  .modalValueRight {
    margin-top: 25px; } }




.modalValue {
  font-weight: 500;
  flex: 1; } // Allow modal values to take remaining space

.modalValueRight {
  font-weight: 500;
  flex: 1; } // Allow modal values to take remaining space

.modalValue.green {
  color: green; }

.modalValue.yellow {
  color: yellow; }

.modalValue.red {
  color: red; }


.addressLine {
  color: $default-text-subscriber;
  opacity: 0.6; }

.data {
  margin-top: -15px; }

.status {
  &-success {
    color: green; }
  &-inProgress {
    color: $orange; }
  &-failed {
    color: red; } }

@import 'src/styles/variables';
.stream {
    width: 85%;
    height: 360px;
    background-color: #495259;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 25px auto; }

.connecting {
    display: flex;
    justify-content: center;
    color: $primary-text-color;
    margin-top: 10px; }

@import 'src/styles/variables';

.title {
  font-weight: 500 !important;
  color: rgba($general-text-color, 0.8);
  font-size: 16px !important; }
.main {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  &.header {
    font-size: 15px;
    font-weight: 500; } }
.fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
.fieldset {
  width: 90%;
  padding: 0 16px;
  position: relative;
  &-double {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr; }
  &-stroke {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    left: 59%;
    bottom: 60%; } }
.doubleLabel {
  display: inline-block;
  padding-top: 15px;
  font-size: 13px; }
.checkboxText {
  font-size: 13px;
  position: absolute;
  left: 42%;
  bottom: 51%; }
.checkbox {
  margin-top: 14px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; }
  & button {
    height: 40px;
    border: unset; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.rightDate {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  margin-left: 22px;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; }
  & button {
    height: 40px;
    border: unset; } }
.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }
.button {
  border-radius: 8px;
  font-size: 15px;
  &-disabled {
    background: rgba($general-text-color, 0.6); } }
.btn {
  min-width: fit-content;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  color: $default-button-txt;
  transition: background .5s;
  & svg {
    fill: white; }
  &-primary {
    background: $primary-btn-color; }
  &-disabled {
    background: rgba($general-text-color, 0.6);
    &:hover {
      background: $danger-color; } } }

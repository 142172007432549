@import 'src/styles/variables';

.default {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 300px;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }
.heading {
  background: $primary-color-subscriber;
  font-size: 1.6rem;
  font-weight: 600; }
.button {
  background: $primary-btn-color-subscriber;
  font-size: 1.1rem;
  font-weight: 600;
  min-width: unset;
  padding: 15px;
  color: $default-button-txt-subscriber !important;
  border-radius: 10px; }
.collapse {
  width: 85%;
  margin: 10px auto;
  & > div:first-child {
    color: $general-text-color !important;
    background: white !important;
    box-shadow: unset !important; } }
.map {
  border: 2px solid $primary-color-subscriber;
  min-height: 300px; }
.defaultIconColor {
  & g {
    fill: $primary-color-subscriber; } }

.title {
  font-size: 24.9px !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  color: rgba($default-text-subscriber, 0.5) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif;
  margin-top: 25px; }

@import 'src/styles/variables';
.toolBar {
  min-height: 70px;
  margin-bottom: 5px; }
.title {
  & > span {
    font-size: 18px;
    color: $page-header-txt; } }
.btnRefresh {
  margin-left: 20px;
  min-width: 115px;
  height: 40px;
  color: $default-button-txt;
  background: $primary-btn-color; }
.inputDate {
  margin-left: 10px;
  min-width: 190px; }

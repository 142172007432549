@import 'src/styles/variables';

.form {
  width: 100%;
  padding: 15px 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.subSection {
  background: rgba(#3c5f85, 0.1);
  border-radius: 8px;
  padding: 20px 0;
  width: 100%; }
.disputeButton {
  font-size: 16px;
  background: $primary-color;
  border-radius: 8px; }
.btnFilter {
  margin-right: 20px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color;
    fill: $general-text-color; } }
.icon {
  border-block-color: tr;
  & path {
    fill: white;
    stroke: white; }
  &-fade {
    opacity: 0.85; } }
.btn {
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  font-weight: 500 !important;
  min-width: fit-content;
  height: 40px !important;
  border-radius: 8px; }
.background {
  &-primary {
    background: $primary-color !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }
.playWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($general-text-color, 0.45); }
.imgThumb {
  border-radius: 8px !important;
  width: 250px;
  height: 150px;
  object-fit: contain; }
.image {
  pointer-events: none; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }

.collapse {
  margin: auto !important; }

.cStationHeader {
  font-weight: 550 !important;
  color: #464a52 !important;
  opacity: 0.5 !important;
  font-size: 14px !important;
  display: flex !important;
  margin-top: 1.5rem !important;
  justify-content: center !important;
  text-align: center !important; }

.required {
  color: $primary-color;
  margin-left: 5px !important; }

.tabs {
  margin-bottom: 20px;
  height: fit-content !important;
  width: 400px !important;
  & li {
    flex-grow: 1;
    text-align: center !important; } }

@media (max-width: 1250px) {
  .tabs {
    width: 350px !important; } }

@media (max-width: 100px) {
  .tabs {
    width: 200px !important; } }

@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-weight: 400 !important;
  font-size: 18px !important !important;
  margin-right: 20px;
  font-family: 'Roboto', sans-serif !important; }

.default {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 10px;
  position: relative;
  padding-left: 40px !important; }

.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }

.calInput {
  display: none !important; }
.button {
  border-radius: 8px;
  min-width: 140px;
  margin-right: 5rem !important;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: $default-button-txt-subscriber !important;
  background: $primary-btn-color-subscriber;
  &:hover {
    opacity: 0.9; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg-subscriber;
    opacity: 0.45;
    &:hover {
      opacity: 0.9; }
    &:active {
      transform: scale(0.95); } } }



.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }

.form {
  padding: 5px;
  padding-bottom: 20px !important;
  width: 104% !important; }

.fieldset {
  & button {}
  &-right-label {
    & label {
      & > div {
        margin-left: 10px; } } } }

.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }

.fieldset-triple {
  & > div {
    & > div {
      & > div:first-child {
        width: 263%; } } } }

.fieldset-db {
  & > div {
    & > div {
      & > div:first-child {
        width: 85%;
        margin-left: 17px; } } } }

.fieldset-db2 {
  & > div {
    & > div {
      & > div:first-child {
        width: 65%;
        margin-left: 58px !important; } } } }
.formInput1 {
  border-radius: 8px !important;
  box-shadow: none !important; }

.formLabel1 {
  & > div {
    margin-left: 10px;
    color: #000000;
    font-weight: 100;
    font-size: 13px;
    text-align: start; } }


.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da; }



.formLabel {
  & > div {
    margin-left: -10px;
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }

.formTimeLabel {
  & > div {
    margin-left: -10px;
    color: #000000 !important;
    font-weight: 660 !important;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    width: 30rem; } }
.body {
  padding: 10px 40px 20px;
  font-size: 13px;
  color: rgba($general-text-color, 0.7); }
.boldText {
  color: 'grey-dark';
  font-weight: bold; }
.numberLabel {
  width: 20px;
  text-align: end; }
.greyButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: rgba($general-text-color, 0.4) !important; }
.orangeButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $orange !important; }
.primaryButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $primary-btn-color !important; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }



.locationbtn {
  background-color: white !important;
  color: $primary-color-subscriber !important;
  font-weight: bolder !important;
  margin-left: 72%;
  margin-top: -22px;
  text-decoration: underline; }

.fetching {
  background-color: white !important;
  color: $primary-color-subscriber !important;
  font-weight: bolder !important;
  margin-left: 65%;
  margin-top: -22px;
  text-decoration: underline; }





.parkingLotCell {
  display: flex;
  font-size: 13px;
  position: relative; }

.checkboxContainer {
  margin-right: 0.6rem;
  position: relative;
  margin-left: -10px;
  margin-bottom: 10px; }

.contentContainer {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.scrollableContainer {
  margin-left: -1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #000000;
  overflow-y: auto;
  max-height: 10.6rem;
  min-width: 100%;
  margin-bottom: 50px;
  box-shadow: none;
  margin-right: 2rem;
  margin-top: 0.5rem;
  border-radius: 8px;
  padding: 4px; }

.parkingLotBox {
  width: 48%;
  border: 1px solid #ccc;
  margin: 0.2rem;
  padding: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: auto;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #a9aaaa;
  &:hover {
    background-color: #f0f0f0; }
  &.selectedParkingLot {
    background-color: #b3d9ff; } }

.boxText {
  text-align: left;
  font-size: 13px;
  width: 100%; }

.spacesTxt {
  font-size: 13px;
  background: #9bffa3;
  width: 3rem;
  height: 1.4rem;
  margin-top: -5px;
  margin-right: -1.25rem;
  border-radius: 7px;
  border: 0.5px solid #ccc;
  display: flex;
  justify-content: center;
  align-self: flex-start; }

@media (min-width: 576px) and (max-width: 768px) {
  .spacesTxt {
    margin-left: 3.7rem; } }

@media (max-width: 575px) {
  .spacesTxt {
    margin-left: 8.4rem; } }

.addrTxt {
  font-size: 13px !important;
  margin-left: 0rem !important; }
/* Add this media query to ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .parkingLotBox {
    width: 100% !important; } }



.alertMsg {
  color: $danger-color !important;
  font-size: 12px !important;
  margin-top: -2.5rem !important;
  margin-bottom: 3rem !important;
  margin-left: 35% !important; }

.dateAlertMsg {
  color: $danger-color !important;
  font-size: 12px !important;
  margin-top: -15px !important;
  margin-left: -9.5% !important;
  text-align: center !important; }

.dateAlertMsgEs {
  color: $danger-color !important;
  font-size: 12px !important;
  margin-top: -15px !important;
  margin-left: -6% !important;
  text-align: center !important; }
.dateAlertMsgHi {
  color: $danger-color !important;
  font-size: 12px !important;
  margin-top: -15px !important;
  margin-left: -15% !important;
  text-align: center !important; }

@media (max-width: 991px) {
  .dateAlertMsg {
    text-align: left !important;
    margin-left: 0.5% !important; }

  .dateAlertMsgEs {
    text-align: left !important;
    margin-left: 0.5% !important; }

  .dateAlertMsgHi {
    text-align: left !important;
    margin-left: 0.5% !important; } }



.notify {
  color: green !important;
  font-size: 13px !important;
  margin-top: -40px !important;
  margin-left: -2% !important; }




.customCheckBox {
  appearance: none;
  margin-top: 0.2rem !important;
  font: inherit;
  color: currentColor;
  min-width: 1em !important;
  height: 1em !important;
  border: 1px solid $general-text-color !important;
  border-radius: 0.19em !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: "";
    width: 0.55em !important;
    height: 0.55em !important;
    transform: scale(0);
    transition: 200ms transform ease-in-out;
    background-color: $primary-color-subscriber;
    border: none;
    color: $primary-color-subscriber;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  &:checked::before {
    transform: scale(1); } }






/* Add this media query to ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .parkingLotBox {
    width: 100% !important; }
  .validParkingLotBox {
     width: 100% !important; } }




.notify {
  color: green !important;
  font-size: 13px !important;
  margin-top: -40px !important;
  margin-left: -2% !important; }



.okBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -60px;
  min-width: 40px;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border: 0.1px solid $primary-btn-color-subscriber !important;
  border-radius: 8px !important; }

.okBtnErrorStyle {
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -10.7%;
  min-width: 40px;
  margin-left: 86.4% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border: 0.1px solid #24acc4 !important;
  border-radius: 8px !important; }
.detailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-left: -1.5%; }

.customLabel {
  font-weight: 1000 !important; }

.ownerDetailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-left: -6%; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px !important;
  margin-left: 115% !important;
  max-width: 350px !important;
  font-weight: 1000px;
  margin-top: -15%;
  position: relative !important; }


.boldText1 {
  color: 'grey-dark';
  font-weight: 700;
  font-size: 15px; }

.dragDropLabel2 {
  border-radius: 8px;
  max-width: 300px;
  margin-right: 40%;
  margin-top: 8%; }

.formatsText {
  color: black;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }

.formatsErrorText {
  color: red;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }


.boldText2 {
  color: 'black';
  font-weight: 500;
  font-size: 13px; }


.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  margin-left: -3.6rem; }


.transitionContainer {
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out, overflow 0.5s ease-in-out; }

.fadeOut {
  opacity: 0;
  height: 0;
  overflow: hidden; }

.fadeIn {
  opacity: 1;
  height: auto;
  overflow: visibl; }

.fetchBtn {
  background-color: $primary-btn-color !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.3% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-top: -61px;
  position: relative; }

.fetchBtnErrorStyle {
  background-color: $primary-btn-color !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-top: -81px;
  position: relative; }

.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: inherit;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }

.confirmBtn {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: bold;
  background-color: green; }

.formDropdownLabel {
  margin-left: -14% !important;
  color: #413e3e;
  font-weight: 100;
  font-size: 13px; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-align: left !important; }


.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 10%; }




.cardPreview {
  flex: 1;
  margin-right: 50px;
  margin-top: 65px;
  margin-left: -6px; }

.cardInputs {
  flex: 1;
  margin-left: 20px; /* Adjust spacing between card preview and inputs */;
  margin-right: 140px;
  margin-top: -5%; }


.cardLabels {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: justify !important; }

.cardInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 40px;
  width: 135%;
  max-width: 135%;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 14px;
  &:focus {
    border: 1px solid #046dd6; } }

.expiryDropdown {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  width: 325% !important;
  max-width: 325%;
  font-size: 14px; }

.expiryDropdownLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #000000;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  justify-content: center !important;
  text-align: justify !important; }

.cardLabels2 {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #5e5858;
  font-weight: 100;
  font-size: 13px;
  margin-top: 5px !important;
  justify-content: center !important;
  text-align: justify !important; }

.commuterLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: justify !important; }



.commuterTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 15px !important;
  text-align: center !important;
  margin-right: 5.3rem !important; }


.addVehicleBtn {
  background-color: transparent !important;
  color: $primary-color-subscriber  !important;
  font-weight: 650 !important;
  margin-top: 1px;
  min-width: 40px;
  margin-left: 0.7% !important;
  height: 40px;
  font-size: 13px;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }

  border-radius: 8px !important; }


.formatsPresenceErrorText {
  color: red !important;
  font-size: 13px !important;
  margin-top: 10px !important; }


.checkboxText {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 4em;
  margin-left: -1.5rem !important; }





.secondaryCheck {
  margin-left: 22rem !important; }


.autorenewFields {
  margin-left: -11rem !important;
  margin-top: 1rem !important;
  border: none  !important; }


.saveData {
  margin-left: 4rem !important;
  margin-top: 20px !important; }

.savecheckboxText {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1 !important;
  margin-top: 1rem !important;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 3em;
  margin-left: -1.5rem !important; }


.saveCheckText {
  margin-top: 0.15rem !important;
  margin-left: -5rem !important; }


.couponInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 35px;
  width: 32% !important;
  max-width: 35%;
  outline: none;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 14px;
  margin-top: -2rem !important;
  margin-left: 12.5rem !important;
  &:focus {
    border: 1px solid #046dd6; } }


.applyLabel {
  margin-left: 5.5rem !important;
  display: block;
  margin-bottom: 4px;
  color: #535252;
  font-weight: 100;
  font-size: 13px;
  margin-top: -0.2rem !important;
  justify-content: center !important;
  text-align: justify !important; }


.applyCBtn {
  background-color: $primary-color-subscriber !important;
  color: #ffffff !important;
  font-weight: 350 !important;
  margin-top: -2.44rem !important;
  width: 14% !important;
  min-width: 10% !important;
  margin-left: 16rem !important;
  height: 38.5px;
  font-size: 14px;
  position: relative !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border: 0.1px solid #24acc4 !important;
  border-radius: 6px !important; }

.dateCsv {
  margin-top: 1.2rem !important; }

.couponValidation {
  font-size: 13px !important;
  color: red !important;
  margin-left: 5.5rem !important; }

.checkbox {
  margin-left: -1.7rem !important; }

.couponApplied {
  font-size: 13px !important;
  color: green !important;
  margin-left: 4.2rem !important;
  margin-bottom: -2rem !important; }

.discount-color {
  color: $red; }


.noteLabel {
  margin-left: -0.5rem;
  color: #000000 !important;
  font-weight: 660 !important;
  font-size: 13px;
  font-family: Roboto, sans-serif; }

.subNoteLabel {
  margin-left: -0.1rem;
  color: rgba($general-text-color,0.5);
  font-weight: 600 !important;
  font-size: 13px; }

.timenoteLabel {
  margin-left: 8px !important;
  margin-top: 0.6rem !important;
  display: flex !important;
  justify-content: flex-start !important;
  text-align: left !important;
  padding: 0 !important;
  color: #000000 !important;
  font-weight: 660 !important;
  font-size: 13px;
  font-family: Roboto, sans-serif; }


.subdetailsLabel {
  font-weight: 500 !important;
  color: $general-text-color !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-bottom: 0.8rem !important;
  display: flex !important;
  text-align: left !important; }

.formDateInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  outline: none !important;
  min-width: 262% !important;
  width: 226% !important;
  margin-left: 36%  !important;
  padding: .75rem !important;
  & path {
    fill: $primary-color-subscriber; } }


@media (min-width: 768px) and (max-width: 991px) {
  .formDateInput {
    width: 380% !important;
    margin-left: 0 !important; } }

@media (max-width: 575px) {
  .formDateInput {
    width: 352% !important;
    margin-left: 0 !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .formDateInput {
    width: 330% !important;
    margin-left: 0 !important; }

  .form {
    width: 125% !important;
    margin-left: -50px !important; } }

.formDateInput1 {
  border-radius: 8px !important;
  box-shadow: none !important;
  outline: none !important;
  min-width: 225% !important;
  padding: .75rem !important; }

.formLabel2 {
  margin-left: 0.9rem !important;
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  text-align: start; }

.spacesNote {
  padding: 0.2rem !important;
  margin-left: 0rem !important;
  background: #ceffde;
  height: 2rem !important;
  font-size: 13px !important;
  border: 1px solid #b1b1b1  !important;
  width: 95% !important;
  border-radius: 5px !important; }

.spacesSection {
  margin-left: 0.05rem !important;
  margin-bottom: 2rem !important;
  margin-top: -1.5rem !important;
  padding: 10px 20px 0 10px !important;
  font-size: 13px !important;
  border: 1px solid #000000  !important;
  width: 95% !important;
  border-radius: 5px !important; }

.totalSpacesText {
  text-align: left !important; }

.countNumber {
  font-size: 13px;
  font-weight: bold !important;
  color: $primary-color-subscriber !important;
  margin-left: 1rem; }

.totalBookedSpacesText {
  font-size: 13px !important;
  text-align: right !important; }

.totalNumberVehiclesText {
  font-size: 13px !important;
  text-align: right !important; }


.calendarIcon {
  path {
    fill: $primary-color-subscriber !important; } }

@import 'src/styles/variables';

.content {
  font-size: 14px;
  line-height: 19.5px;
  text-align: center;
  padding-top: 14px;
  padding: 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.button {
  border-radius: 8px;
  min-width: 100px;
  font-size: 15px;
  &-bg-light {
    background-color: rgba($general-text-color, 0.45) !important; }
  &-bg-danger {
    background-color: $red !important; }
  &-bg-primary {
    background-color: $primary-color !important; } }

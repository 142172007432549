@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 16px !important; }

.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }

.calInput {
  display: none !important; }
.button {
  border-radius: 8px;
  min-width: 140px;
  margin-right: 5rem !important;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: $default-button-txt-subscriber !important;
  background: $primary-btn-color-subscriber;
  &:hover {
    opacity: 0.9; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg-subscriber;
    opacity: 0.45;
    &:hover {
      opacity: 0.7; }
    &:active {
      transform: scale(0.95); } } }



.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color-subscriber; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }

.fieldset {
  & button {}
  &-right-label {
    & label {
      & > div {
        margin-left: 10px; } } } }

.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 85%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da; }



.formLabel {
  & > div {
    margin-left: -10px;
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }

.body {
  padding: 10px 40px 20px;
  font-size: 13px;
  color: rgba($general-text-color, 0.7); }
.boldText {
  color: 'grey-dark';
  font-weight: bold; }
.numberLabel {
  width: 20px;
  text-align: end; }
.greyButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: rgba($general-text-color, 0.4) !important;
  color: $default-button-txt-subscriber !important; }

.orangeButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $orange !important;
  color: $default-button-txt-subscriber !important; }

.primaryButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $primary-btn-color-subscriber !important;
  color: $default-button-txt-subscriber !important; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }



.locationbtn {
  background-color: white !important;
  color: $primary-color-subscriber !important;
  font-weight: bolder !important;
  margin-left: 72%;
  margin-top: -22px;
  text-decoration: underline; }

.fetching {
  background-color: white !important;
  color: $primary-color-subscriber !important;
  font-weight: bolder !important;
  margin-left: 65%;
  margin-top: -22px;
  text-decoration: underline; }

.parkingLotBox {
  width: 48% !important;
  border: 0.2px solid #ced4da !important;
  margin: 0.2rem !important;
  padding: 0.2rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: 2.4rem !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  background-color: rgba($disabled-bg-color, 0.3);
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }

.validParkingLotBox {
  width: 48% !important;
  border: 1px solid #ccc !important;
  margin: 0.2rem !important;
  padding: 0.3rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #38c415ef !important;
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }


.invalidParkingLotBox {
  width: 48% !important;
  border: 1px solid #ccc !important;
  margin: 0.2rem !important;
  padding: 0.3rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #f10b0bef !important;
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }



.parkingLotCell {
  display: flex !important;
  font-size: 13px !important;
  & input {
    margin-right: 0.6rem !important;
    position: relative !important;
    margin-bottom: 43px !important; }
  & span {
    flex-grow: 2 !important; } }





.scrollableContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  border: 1px solid #000000 !important;
  height: auto  !important;
  width: 94.5% !important;
  margin-bottom: 50px !important;
  box-shadow: none !important;
  margin-left: -24px !important;
  margin-top: 10px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: none !important; }



/* Add this media query to ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .parkingLotBox {
    width: 100% !important;
    margin-top: 10px !important; }
  .validParkingLotBox {
    width: 100% !important; }
  .scrollableContainer {
    width: 92.5% !important; } }

.alertMsg {
  color: red !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 3% !important;
  margin-top: 0.3rem !important; }


.notify {
  color: green !important;
  font-size: 13px !important;
  margin-top: -40px !important;
  margin-left: -2% !important; }

.validParkingLotsScrollable {
  overflow-y: auto;
  max-height: 130px; /* Set the desired max height */;
  width: 90% !important;
  margin-top: 10px !important;
  margin-bottom: 50px;
  box-shadow: none;
  background: #e9ecef;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.validParkingLotCell {
  display: flex;
  align-items: center;
  font-size: 12px;
  & input {
    margin-right: 0.6rem;
    position: relative;
    margin-bottom: 43px; }
  & span {
    flex-grow: 2;
    text-justify: distribute !important; } }
.validParkingLotBox {
  width: 48%; /* Adjust as needed for spacing between boxes */;
  margin: 0.2rem;
  padding: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: auto; }

.okBtn {
  background-color: $primary-btn-color !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -60px;
  min-width: 40px;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border: 0.1px solid #24acc4 !important;
  border-radius: 8px !important; }

.okBtnErrorStyle {
  background-color: $primary-btn-color !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  margin-top: -10.7%;
  min-width: 40px;
  margin-left: 86.4% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border: 0.1px solid #24acc4 !important;
  border-radius: 8px !important; }
.detailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-left: -1.5%; }

.customLabel {
  font-weight: 1000 !important; }

.ownerDetailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-left: -6%; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px !important;
  margin-left: 115% !important;
  max-width: 350px !important;
  font-weight: 1000px;
  margin-top: -15%;
  position: relative !important; }


.boldText1 {
  color: 'grey-dark';
  font-weight: 700;
  font-size: 15px; }

.dragDropLabel2 {
  border-radius: 8px;
  max-width: 300px;
  margin-right: 40%;
  margin-top: 8%; }

.formatsText {
  color: black;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }

.formatsErrorText {
  color: red;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }


.boldText2 {
  color: 'black';
  font-weight: 500;
  font-size: 13px; }


.modalTitle {
  font-weight: 500 !important;
  color: #000000 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  margin-left: -4.5rem;
  margin-top: -2.5rem !important; }


.transitionContainer {
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out, overflow 0.5s ease-in-out; }

.fadeOut {
  opacity: 0;
  height: 0;
  overflow: hidden; }

.fadeIn {
  opacity: 1;
  height: auto;
  overflow: visibl; }

.fetchBtn {
  background-color: $primary-btn-color !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.3% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-top: -61px;
  position: relative; }

.fetchBtnErrorStyle {
  background-color: $primary-btn-color !important;
  color: $default-button-txt-subscriber !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-top: -81px;
  position: relative; }

.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: inherit;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }

.confirmBtn {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: bold; }

.formDropdownLabel {
  margin-left: -14% !important;
  color: #413e3e;
  font-weight: 100;
  font-size: 13px; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-align: left !important; }


.noteLabel {
  margin-left: -0.5rem;
  color: #000000;
  font-weight: 500;
  font-size: 13px; }
.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 4%; }

.cardPreview {
  flex: 1;
  margin-right: 50px;
  margin-top: -1rem;
  margin-left: 4.6rem;
  width: 100px !important; }

.cardPreviewInnerModal {
  flex: 1;
  margin-right: 50px;
  margin-top: -1rem;
  margin-left: 3.5rem;
  width: 100px !important; }

.cardInputs {
  flex: 1;
  margin-left: 3.7rem;
  margin-top: 1%;
  width: 18rem;
  max-width: 100% !important;
  max-height: 28rem;
  overflow-y: auto  !important;
  overflow: hidden; }

.cardInputs2 {
  flex: 1;
  margin-left: 4.9rem;
  margin-right: 1rem;
  margin-top: 1%;
  width: 18rem;
  max-width: 100% !important;
  max-height: 28rem;
  overflow-y: auto  !important;
  overflow: hidden; }


.cardLabels {
  display: block;
  margin-bottom: 0.4rem !important;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: left !important;
  text-align: left !important; }

.cardInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 40px;
  width: 100%;
  max-width: 100%;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 13px;
  &:focus {
    border: 1px solid #046dd6; }
  ::-ms-input-placeholder, :-ms-input-placeholder, ::placeholder {
    color: #00f !important; } }


.expiryDropdown {
  height: 40px;
  border-radius: 8px !important;
  border: 1px solid #ced4da;
  box-shadow: none !important;
  text-align: left !important;
  max-width: 19.5rem !important;
  width: 18rem !important;
  font-size: 13px; }


.expiryDropdownInnerModal {
  height: 40px;
  border-radius: 8px !important;
  border: 1px solid #ced4da;
  box-shadow: none !important;
  text-align: left !important;
  max-width: 19.5rem !important;
  width: 18rem;
  font-size: 13px; }

.expiryDropdownInnerModal2 {
  height: 40px;
  border-radius: 8px !important;
  border: 1px solid #ced4da;
  box-shadow: none !important;
  text-align: left !important;
  max-width: 19.5rem !important;
  width: 17.05rem;
  font-size: 13px; }


.expiryDropdownLabel {
  margin-bottom: 4px;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  justify-content: left !important;
  text-align: left !important; }

.cardLabels2 {
  margin-bottom: 4px;
  font-weight: 100;
  font-size: 13px;
  justify-content: left !important;
  text-align: left !important; }

.cvcLabel {
  display: block;
  color: #7a7878;
  font-weight: 100;
  margin-top: -10px;
  font-size: 13px;
  justify-content: left !important;
  text-align: left !important; }

.commuterLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: justify !important; }



.commuterTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 15px !important;
  text-align: center !important;
  margin-right: 5.3rem !important; }


.addVehicleBtn {
  background-color: transparent !important;
  color: $primary-color-subscriber !important;
  font-weight: 650 !important;
  margin-top: 1px;
  min-width: 40px;
  margin-left: 0.7% !important;
  height: 40px;
  font-size: 13px;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }

  border-radius: 8px !important; }


.formatsPresenceErrorText {
  color: red !important;
  font-size: 13px !important;
  margin-top: 10px !important; }


.checkboxText {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 4em;
  margin-left: -1.5rem !important; }



.customCheckBoxStyle {
  appearance: none;
  margin-top: 0.55rem !important;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid rgba(204, 205, 211, 0.95);
  border-radius: 8px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 1rem !important;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important; }
  &:checked {
    opacity: 1;
    background-color: $primary-color-subscriber !important; } }

.secondaryCheck {
  margin-left: 22rem !important; }


.autorenewFields {
  margin-left: -11rem !important;
  margin-top: 1rem !important;
  border: none  !important; }


.saveData {
  margin-left: 4rem !important;
  margin-top: 20px !important; }

.savecheckboxText {
  color: #4e4c4c;
  border: none !important;
  font-weight: 100 !important;
  font-size: 13px;
  line-height: 1 !important;
  margin-top: 1rem !important;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 3em;
  margin-left: -1.5rem !important; }


.saveCheckText {
  margin-top: 0.15rem !important;
  margin-left: -5rem !important; }


.couponInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 35px;
  width: 32% !important;
  max-width: 35%;
  outline: none;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 13px;
  margin-top: -2rem !important;
  margin-left: 12.5rem !important;
  &:focus {
    border: 1px solid #046dd6; } }


.applyLabel {
  margin-left: 5.5rem !important;
  display: block;
  margin-bottom: 4px;
  color: #535252;
  font-weight: 100;
  font-size: 13px;
  margin-top: -0.2rem !important;
  justify-content: center !important;
  text-align: justify !important; }


.applyCBtn {
  background-color: $primary-color-subscriber !important;
  color: #ffffff !important;
  font-weight: 350 !important;
  margin-top: -2.2rem !important;
  width: 10% !important;
  min-width: 10% !important;
  margin-left: 20.8rem !important;
  height: 35px;
  font-size: 14px;
  position: relative !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border: 0.1px solid #24acc4 !important;
  border-radius: 6px !important; }

.dateCsv {
  margin-top: 1.2rem !important; }


.checkbox {
  margin-left: -1.7rem !important; }


.discount-color {
  color: $red; }


.lotName {
  margin-left: 0.5rem !important;
  margin-top: 0.20rem !important;
  font-size: 14px !important; }

.gpayBtn {
  margin-right: 12.3rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

.gpayBtnInnerModal {
  margin-right: 10.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

.spacesNote {
  padding: 0.5rem !important;
  margin-left: 1.5rem !important;
  margin-top: 0.5rem !important;
  background: #ceffde;
  height: 3.3rem !important;
  font-size: 14px !important;
  border: 1px solid #b1b1b1  !important;
  width: 90% !important;
  border-radius: 5px !important;
  text-align: center !important; }


.totalSpacesText {
  text-align: center !important;
  margin-top: -1rem;
  line-height: 1.2; }


.prevButtons {
  border-radius: 8px;
  min-width: 120px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  color: $default-button-txt-subscriber !important;
  background: $primary-btn-color-subscriber;
  &:hover {
    background-color: #1388f5; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45);
    &:hover {
      background-color: #000000; }
    &:active {
      transform: scale(0.95); } } }

.downloadIcon {
  opacity: 2 !important;
  height: 1.5rem;
  color: white !important;
  margin-left: -0.4rem;
  margin-top: -0.2rem;
  & path {
    stroke: white;
    fill: white !important; }
  &:hover {
    opacity: 1 !important; } }


.download {
  font-size: 14px !important;
  margin-left: 0.2rem; }


.previewSection {
  margin-top: 1.5rem;
  border: 1px solid rgba($general-text-color, 0.5);
  margin-left: 2.9rem;
  width: 81%; }

.lotName1 {
  margin-left: 0.5rem !important;
  margin-top: 0.3rem !important;
  font-size: 14px !important; }

.alertMsg1 {
  color: red !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 3% !important;
  margin-top: 0.3rem !important; }


.ccNumField {
  font-size: 13px !important; }

.placeholders {
  font-family: Roboto, sans-serif !important;
  font-size: 13px; }


::placeholder {
  color: $general-text-color !important;
  opacity: 0.5 !important; }

@media (min-width: 575px) and (max-width: 767px) {
  .form {
    width: 100%;
    padding: 20px 0 20px 0 !important; } }

.lotRequiredMsg {
  color: #FB745B !important;
  font-size: 12px !important;
  opacity: 0.9 !important;
  margin-left: 53px; }
.lotLabelSection {
  display: flex !important;
  justify-content: space-between !important; }

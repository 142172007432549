@import 'src/styles/variables';
.toolbar {
  display: flex;
  height: 40px;
  padding: 0;
  margin-bottom: 15px; }
.backIcon {
  font-size: 16px;
  margin: 0 15px 0 0; }
.backTab {
  color: $general-text-color !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  display: flex;
  align-items: center; }
.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em; }
.tab {
  height: 100%;
  padding: 10px 16px;
  opacity: 0.65; }
.activeTab {
  background: rgba($general-text-color, 0.1); }

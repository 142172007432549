@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important; }
.tableActive {
  margin-top: 15px; }
.dangerColor {
  color: $danger-color-deep; }
.autoApprovalLink {
  padding: 15px 20px;
  font-size: 20px;
  background: $primary-color;
  color: white !important;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
  right: 0;
  text-decoration: none !important; }
.form {
  width: 100%;
  padding: 0 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.autoApprovalButton {
  font-size: 16px;
  background: $primary-color;
  border-radius: 8px; }
.btnFilter {
  margin-right: 20px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.badge {
  font-size: 12px !important;
  border-radius: 4px;
  height: 20px;
  max-width: 22px;
  min-width: 22px;
  &-primary-background {
    background: $primary-color !important; }
  &-orange-background {
    background: $orange !important; }
  & div:first-child {
    padding: 0 6px; } }
.icon {
  width: 20px;
  height: 20px;
  background: rgba($general-text-color, 0.45);
  transition: background 0.35s;
  border-radius: 50%;
  &-primary {
    background: $primary-color; }
  &-success {
    background: $green; }
  &-danger {
    background: $red; }
  &-alert {
    background: $orange; }
  &-eye {
    background: unset;
    & svg {
      transition: fill 0.35s;
      width: 27px;
      height: auto;
      fill: rgba($general-text-color, 0.45); } }
  &-eye-active {
    & svg {
      fill: $primary-color; } } }
.rejectEyeIcon {
  margin-left: 8px;
  width: 27px;
  height: auto;
  fill: $red; }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important; }
.background {
  &-primary {
    background: $primary-color !important; }
  &-orange {
    background: $orange !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }

.form {
  width: 100%;
  padding: 10px 0 20px 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 10px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formInputTo {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 2rem !important; }
.formInputAmount {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 9rem !important; }
.formLabel {
  & div {
    margin-left: 0px; } }

.fieldset {
  padding: 0 0 0 33px !important;
  &-dateStroke {
    width: 14px !important;
    height: 1px !important;
    border: 1.5px solid rgba($general-text-color, 0.65);
    left: 17px;
    margin-top: 1.3rem;
    justify-content: center !important; }
  &-dateStroke1 {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 5rem;
    left: -20px; }
  &-dateStroke2 {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 2.3rem;
    right: 13rem; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; }
  &-firstField {
    margin-right: 6rem !important; } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  &-secondary {
    background: rgba($general-text-color, 0.45); }
  &-refresh {
    min-width: unset;
    width: fit-content;
    font-size: 16px; } }


.fieldset2 {
  padding: 0 0 0 33px !important;
  &-dateStroke {
    width: 14px !important;
    height: 1px !important;
    border: 1.5px solid rgba($general-text-color, 0.65);
    left: 17px;
    margin-top: 1.3rem;
    justify-content: center !important; }
  &-dateStroke1 {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 5rem;
    left: -20px; }
  &-dateStroke2 {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 2.3rem;
    right: 13rem; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; }
  &-firstField {
    margin-right: 6rem !important; } }


@media (max-width: 991px) {
  .fieldset2 {
    top: 2.5rem !important; }
  .fieldset {
    &-dateStroke {
      width: 15px !important;
      height: 1px !important;
      border: 1.5px solid rgba($general-text-color, 0.65);
      left: 17px;
      top: 2.5rem !important;
      justify-content: center !important; } }
  .formLabel {
    min-width: 120% !important; }
  .form {
    padding: 0 20px 0 0 !important; } }

.timeTxt {
  color: $primary-color;
  font-weight: 600; }

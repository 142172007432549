@import 'src/styles/variables';

// .Card
//   border: none !important
//   .CardHeader
//     display: flex
//     justify-content: space-between
//     align-items: center
//     height: 50px
//     border: none !important
//     border-radius: 0 !important
//     background: $grey-dark !important
//     color: white !important
//     font-size: 16px
//     font-weight: 300
//     .addButton
//       background-color: white
//       padding: 9px
//       margin-right: 20px
//       border-radius: 3px

.Card {
  max-width: 700px !important /* Set your preferred fixed width */;
  // margin: 1rem
  margin-left: -0.5rem !important;
  border: none !important;
  .CardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border: none !important;
    border-radius: 0 !important;
    background: #ffffff !important /* Adjust the color as needed */;
    color: black !important;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    min-width: 600px !important;
    box-shadow: none !important;
    .addButton {
      background-color: white;
      padding: 9px;
      margin-right: 20px;
      border-radius: 3px;
      border: none !important; } }

  .CardBody {
    padding: 10px;
    border: none !important; } }

.formGroupLabel {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }

@import 'src/styles/variables';

.title {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }
.form {
  padding: 10px 50px 20px; }
.fieldset {
  width: 50%;
  padding: 0 16px;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }

.lotfieldset {
  width: 100% !important;
  padding: 0 16px;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.category {
  font-size: 15px; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; }
  & button {
    height: 40px;
    border: unset; } }
.input1 {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  height: 40px !important;
  font-size: 13px !important;
  min-width: 27.3rem;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.lotInput {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  width: 107% !important;
  margin-left: -26px;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; }
  & button {
    height: 40px;
    border: unset; } }

@media (max-width: 991px) {
  .lotInput {
    width: 100% !important;
    margin-left: 1px; } }

.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.primary-color {
  color: $primary-color; }
.cancelled-color {
  color: rgba($general-text-color, 0.45); }
.success-color {
  color: $green; }
.revoked-color {
  color: $red; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 150px;
  background: $primary-btn-color;
  color: $default-button-txt;
  height: 40px;
  font-size: 14px;
  &-sm {
    min-width: 120px; }
  &-bg-orange {
    background: $orange; }
  &-bg-secondary {
    background: $cancel-button-bg;
    opacity: 0.6; } }
.badge {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
  font-size: 12px !important;
  &-primary-background {
    background: $primary-color !important; }
  &-secondary-background {
    background: rgba($general-text-color, 0.45) !important; }
  &-orange-background {
    background: $orange !important; } }
.tabs {
  margin-bottom: 20px;
  & li {
    flex-grow: 1;
    width: 50%;
    opacity: unset; } }
.tabBadge {
  display: inline-block;
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 20px;
  background: $orange; }
.input1 {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  height: 40px !important;
  font-size: 13px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }

@import 'src/styles/variables';

.calenderIcon {
  pointer-events: none;
  background: white;
  & path {
    fill: $primary-color; } }
.input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100%;
  text-align: justify !important; }

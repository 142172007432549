@import 'src/styles/variables';
.toolBar {
  min-height: 70px;
  margin-bottom: 5px; }
.title {
  & > span {
    font-size: 18px; } }
.btnRefresh {
  width: 121px;
  height: 40px;
  gap: 0px;
  opacity: 0px;
  border-radius: 8px !important;
  color: $default-button-txt;
  background: $primary-btn-color;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: left; }

.inputDate {
  margin-left: 10px;
  min-width: 190px; }

.btnCalender {
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  background: #E9EBF0;
  border-radius: 8px !important;
  margin-left: 20px !important;
  &:hover {
    background: #242E42CC !important;
    stroke: white !important;
    fill: white !important; } }

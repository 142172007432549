@import 'src/styles/variables';

.appContainer {
  width: 100%;
  font-family: Roboto, sans-serif !important; }

.header {
  font-size: 30px;
  font-weight: bold !important;
  color: rgba($general-text-color, 0.6);
  font-family: Roboto, sans-serif !important; }

.customTabs {
  padding: 20px;
  background-color: rgba($general-text-color, 0.03);
  border-radius: 5px;
  margin: 10px;
  &:active {
    color: green; }

  .ant-tabs-bar {
    border: none; }

  .ant-tabs-tab {
    font-size: 16px;
    color: #333;
    border: none;
    margin: 0;
    padding: 10px 20px;
    background-color: #e0e0e0;
    border-radius: 5px 5px 0 0; }

  .ant-tabs-tab-active {
    font-weight: bold;
    color: #5bdf0f;
    background-color: #fff; } }
.inputFields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px; }

.inputField {
  width: 20%;
  margin-bottom: 10px;
  height: 40px;
  margin-left: 10px; }

.placesAutocompleteInput {
  width: 150%;
  margin-bottom: 10px;
  box-sizing: border-box;
  height: 40px;
  margin-left: -55% !important; }


.borderContainer {
  border: 1px solid #ccc;
  margin: 1px;
  padding: 1px;
  width: 100%; }

.borderContainerInner {
  height: 56vh;
  width: 100% !important;
  align-items: center;
  justify-content: center;
  font-size: 20px; }

@media  ( max-width: 480px ) {
  .borderContainerInner {
    height: 100vh; } }
@media  ( max-width: 400px ) {
  .borderContainerInner {
    height: 130vh !important; } }

@media  ( width: 1024px ) and (height: 600px) {
  .borderContainerInner {
    height: 42vh !important; }
  .borderContainer {
    height: 42vh  !important; } }

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center; }

.listCard {
  width: 100%;
  margin: 10px 0; }

.squareWithCount {
  width: 90px;
  height: 90px;
  background-color: #f17d4f;
  color: #fff;
  border-radius: 9px;
  font-size: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif !important;
  &-bg-secondary {
    background-color: $primary-color  !important; } }



.rightContent {
  text-align: right; }

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px !important; }

.listItemDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2% !important;
  font-family: Roboto, sans-serif !important; }


.listItemDetails2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20% !important;
  margin-top: 1.25rem;
  margin-right: 2% !important; }

.listItemDetails3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15% !important; }

.listItemDetails4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  margin-left: 0.2rem; }

.itemTitle {
  font-weight: bold;
  text-align: left !important; }

.itemDescription {
  margin-top: 5px; }

.additionalDetails {
  margin-top: 5px;
  color: #777; }

.detailsButton {
  margin-top: 10px; }

.nameCont {
  color: black;
  margin-left: -10%; }


.rightContent {
  text-align: right;
  margin-right: 10px; }





.placesAutocompleteDropdown {
  position: absolute;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: 57% !important;
  box-sizing: border-box;
  border-top: none;
  margin-left: 12px; }

.placesAutocompleteItem {
  padding: 10px;
  cursor: pointer;
  border: 0.5px solid #f0f0f0;
  &:focus {
    background-color: red !important; } }

.placesAutocompleteItem:hover {
  background-color: #f5f5f5; }

.listItemDetails6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15% !important;
  width: 205%; }

.line {
  width: 100% !important;
  border-top: 1px solid #ccc;
  margin-top: 15px;
  margin-bottom: 10px; }

.additionalDetails2 {
  margin-top: 5px;
  color: #777;
  text-align: left !important; }


.nameCont2 {
  text-align: right;
  margin-right: 10px; }

.listItemDetails7 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: center !important; }


.additionalDetails3 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #777;
  text-align: left !important; }

.perHourEl {
  margin-top: -8px; }


.buttonCol {
  margin-top: 1px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  align-self: flex-end; }

.button {
  background-color: $primary-color !important;
  font-family: Roboto, sans-serif !important; }


.contentStyle {
  margin: 0;
  height: 160px;
  color: grey;
  line-height: 160px;
  text-align: center;
  background: #0000;
  font-size: 22px;
  font-family: sans-serif; }


.table {
  margin-top: 2rem !important;
  width: 70% !important;
  font-size: 15px;
  border-radius: 8px;
  border: none !important;
  & td {
    padding: 10px !important;
    border: none; }
  & th {
    padding: 20px !important;
    border: 1px solid rgba($general-text-color, 0.2) !important;
    background: #495057 !important;
    color: white !important;
    & > span {
      border-radius: 8px;
      padding: 10px 15px;
      color: white !important;
      opacity: 0.9; } }
  & tbody > tr:nth-of-type(even) {
    background-color: rgba($athens-grey, 0.5); } }



.detailedRecordContainer {
  display: flex;
  flex-direction: column;
  padding: 20px; }

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }

.squareContainerLeft {
  width: 70px;
  height: 70px;
  background-color: #f77811;
  border-radius: 5px; }
.textContainer {
  justify-content: left !important;
  text-align: left !important;
  align-items: left !important;
  width: 70% !important;
  margin-left: 1rem; }

.squareContainerRight {
  width: 300px;
  height: auto;
  background-color: #ebebebd3;
  border-radius: 5px;
  border: 1px solid rgba($general-text-color, 0.3); }


.bottomRow {
  display: flex !important;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px; }

.bottomRowGasoline {
  display: flex !important;
  justify-content: left !important;
  text-align: left !important;
  margin-bottom: 20px;
  margin-left: 0.5rem; }

.mapContainer {
  background-color: #dfdfdf !important;
  border: 1px solid $primary-color;
  height: 100%;
  width: 100%; }


.parkingSpaces {
  margin: 1rem; }

.parkingSpacesCount {
  margin: 6%; }

@media  ( max-width: 785px  ) {
  .parkingSpacesCount {
    margin-top: 1rem;
    margin-left: 0%; } }


.redTxt {
  color: red; }

.greenTxt {
  color: green; }

.squareWithCountDetails {
  width: 90px;
  height: 90px;
  background-color: #f17d4f;
  color: #fff;
  border-radius: 9px;
  font-size: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif !important;
  &-bg-secondary {
    background-color: $primary-color  !important; } }

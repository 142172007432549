@import 'src/styles/variables';

.title {
  font-size: 18px !important; }
.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & > div {
    margin-left: 10px;
    text-align: left; } }
.button {
  border-radius: 8px;
  min-width: 100px;
  background: $save-button-bg;
  color: $default-button-txt;
  &-bg-secondary {
    background-color: $cancel-button-bg !important;
    opacity: 0.6; }
  &-bg-submit {
    background-color: $primary-btn-color !important; } }
.iconInput {
  padding-left: 28px !important; }
.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }


.locationbtn {
  background-color: white !important;
  color: $primary-color !important;
  font-weight: bolder !important;
  margin-left: 72%;
  margin-bottom: -40px;
  text-decoration: underline; }


.fetchBtn {
  background-color: $primary-btn-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  position: absolute;
  margin-left: 173% !important;
  margin-top: -18%; }


.fetchLotsBtn {
  background-color: $primary-btn-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  position: absolute;
  margin-left: 73% !important;
  margin-top: -18% !important; }

.checkBox {
  margin-top: -6% !important;
  margin-bottom: 10px;
  margin-left: -10px; }

.checkboxText {
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: 1.6rem !important;
  padding-right: 0.85rem !important; }

.checkboxDescription {
  font-size: 13px !important;
  margin-left: 0.5rem !important; }

.checkboxDescription1 {
  font-size: 13px !important;
  margin-left: 0.2rem !important; }

.toolTip {
  width: 25px !important;
  height: 20px !important;
  margin-left: 0.5rem !important; }

.customCheckStyle {
  appearance: none;
  margin-top: 0.2rem !important;
  font: inherit;
  color: currentColor;
  width: 1em !important;
  height: 1em !important;
  border: 1px solid #ced4da;
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

.checkboxDescription2 {
  font-size: 13px !important;
  margin-left: 0.5rem !important;
  opacity: 0.6 !important; }

.checkboxText1 {
  font-size: 13px !important;
  margin-left: 1.6rem !important;
  padding-right: 0.85rem !important;
  opacity: 0.6 !important; }

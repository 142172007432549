@import 'src/styles/variables';

.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: rgba($general-text-color, 0.4) !important; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px; }
.content {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  text-align: left; }
.body {
  padding: 10px 40px 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  text-align: center; }

@import 'src/styles/variables';

.section {
  background: rgba($general-text-color, 0.05);
  padding: 20px; }

.calenderIcon {
  background: white;
  & path {
    fill: $primary-color !important; } }

.calenderInput {
  height: 40px;
  font-size: 14px !important;
  width: 100%; }

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  border: 2px dashed #1890ff;
  border-radius: 50%;
  font-size: 24px;
  color: #1890ff;
  cursor: pointer;
  transition: all 0.3s ease; }

.uploadText {
  margin-top: 8px; }

.radioBtns {
  width: 40%; }

.formItem {
   padding: 10px;
   padding-bottom: 2px; }

.phoneInput {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0 !important;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 8px !important;
  line-height: 25px !important;
  height: 39px !important;
  width: 100% !important;
  outline: none !important; }

.dropdown {
  font-size: 13px !important;
  max-width: 740% !important;
  margin: 0 !important;
  margin-top: 3px !important;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  text-align: justify !important; }

.search {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 8px !important;
  line-height: 25px !important;
  outline: none !important; }


.button {
  border-radius: 8px;
  min-width: 140px;
  width: fit-content !important;
  background: $save-button-bg;
  color: $default-button-txt;
  font-weight: 500 !important;
  font-size: 15px;
  &-bg-secondary {
    background-color: $cancel-button-bg !important;
    opacity: 0.6; }
  &-bg-submit {
    background-color: $primary-btn-color !important; } }

.addNewButton {
  border-radius: 8px;
  min-width:  100%;
  height: 40px;
  background: $save-button-bg;
  color: $default-button-txt;
  font-weight: 500 !important;
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 25px !important;
  &-bg-secondary {
    background-color: $cancel-button-bg !important;
    opacity: 0.6; }
  &-bg-submit {
    background-color: $primary-btn-color !important; } }

.editBtn {
  max-width: 40px !important;
  min-width:  40px !important;
  margin-right: 20px !important;
  background-color: transparent !important; }
.moveUpBtn {
  max-width: 40px !important;
  min-width:  40px !important;
  background-color: transparent !important;
  & path {
    fill: $general-text-color !important; } }

.header {
  font-size: 20px;
  color: $general-text-color !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700 !important; }

.tabSection {
  display: flex;
  justify-content: space-between !important; }

.tabs {
  border-radius: 10px !important;
  @media (max-width: 1550px) {
    height: 60px !important; }
  @media (max-width: 982px) {
    height: 70px !important; } }

.formInput {
  width: 100%;
  height: 40px;
  margin-top: 20px; }

.defaultTag {
  color: #3A9CED !important;
  background-color: #e2f9ff !important;
  border: 1px solid #3A9CED;
  width: 60px;
  text-align: center;
  margin-right: 15px; }

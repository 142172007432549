@import 'src/styles/variables';

.container {
  height: 300px;
  width: 97% !important;
  justify-content: center !important;
  margin-left: 1.4rem !important;
  border-radius: 10px !important;

  & > div {
    position: relative !important; } }

.title {
  font-weight: 500 !important;
  color: #464a52 !important;
  opacity: 0.6 !important;
  font-size: 16px !important;
  margin-left: 0.5rem !important; }



.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: $general-text-color;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 15%;
  margin-left: 77% !important; }
.toggle {
  width: 55px;
  height: 30px;
  background: $grey-dark;
  border-radius: 20px;
  & > div {
    left: calc(25px - 100%);
    & > span:first-child {
      &::after {
        width: 20px;
        height: 20px;
        background-color: white; } } } }
.toggleOn {
  background: $general-text-color;
  & > div {
    left: 0; } }

.toggleGraphLabel {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $general-text-color !important;
  opacity: 0.3; }




.toggleListLabel {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $general-text-color !important;
  opacity: 0.3;
  margin-right: 1rem !important; }

.background {
  &-primary {
    opacity: 0.9; } }


.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  font-size: 13px !important;
  & > div {
    left: calc(23px - 100%);
    color: white !important; } }

.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: 2px; } }


.LocationIcon {
  position: absolute;
  right: 10px;
  top: 8px;
  stroke: $primary-color;
  fill: $primary-color;
  & line {
    stroke: $primary-color;
    fill: $primary-color; }
  & path {
    stroke: $primary-color;
    fill: $primary-color; } }


.ListIcon {
  position: absolute;
  stroke: $primary-color !important;
  left: -20px !important;
  height: 15px !important;
  margin-bottom: 0.3px !important;
  border-radius: 3px !important;
  & line {
    stroke: black; }
  & path {
    stroke: black; } }




.MapIcon {
  position: absolute;
  stroke: $primary-color !important;
  left: -30px !important;
  height: 15px !important;
  width: 30px !important;
  margin-bottom: 0.3px !important;
  border-radius: 3px !important;
  & line {
    stroke: black; }
  & path {
    stroke: black; } }

.iconInput {
  padding-left: 30px !important; }


@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important;
  &-active {
    margin-top: 15px; } }
.form {
  width: 100%;
  margin-left: -0.5rem !important;
  & > label:first-child {
    color: blue !important; } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.formLabel {
  color: #000000 !important;
  & div {
    margin-left: 30px;
    line-height: 1.2 !important; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em; }
.btnFilter {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); }
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.5px solid rgba($general-text-color, 0.6); }
  &-success {
    background: $green; }
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.1); } }


.fieldset {
  padding: 0 50px 0 0 !important;

  width: 100% !important;
  &-dateStroke {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 20px;
    left: -20px; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; } }

.button {
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  min-width: fit-content;
  border-radius: 8px; }

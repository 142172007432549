@import 'src/styles/variables';

.body {
  padding: 10px 40px 20px;
  font-size: 13px;
  color: rgba($general-text-color, 0.7); }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 500; }
.numberLabel {
  width: 20px;
  text-align: end; }
.greyButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: rgba($general-text-color, 0.4) !important;
  color: $default-button-txt; }
.orangeButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $orange !important;
  color: $default-button-txt; }
.primaryButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  color: $default-button-txt;
  background-color: $primary-btn-color !important; }
.title {
  color: $general-text-color !important;
  font-size: 16px !important; }
.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }

.saveButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  color: $default-button-txt;
  background-color: $save-button-bg !important; }

@import 'src/styles/variables';

.title {
  font-family: 'Roboto', sans-serif;
  color: $general-text-color !important;
  font-size: 16px !important; }
.subTitle {
  font-family: 'Roboto', sans-serif;
  color: $general-text-color !important;
  font-size: 13px !important; }
.form {
  padding: 10px 30px 20px; }
.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45); } }

.button {
  border-radius: 8px;
  min-width: 100px;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; } }
.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 100%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  background-color: white !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  box-shadow: none !important;
  &:focus {
    border: 1px solid rgba(216, 219, 234, 0.95) !important; }
  &:hover {
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.formLabel {
  margin-left: -12px;
  color: #242E42;
  font-weight: 100;
  font-size: 13px !important;
  margin-top: 0.6rem;
  opacity: 0.6; }
.error {
  margin-left: -30px;
  margin-top: 4px;
  margin-bottom: -10px;
  color: #FB745B;
  font-size: 12px; }

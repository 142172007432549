@import 'src/styles/variables';

.card {
  background: #ffffff  !important;
  height: 200px;
  margin: 1rem !important;
  border-radius: 8px !important;
  line-height: 1.2 !important;
  &:hover {
    border: 1px solid $primary-color-subscriber !important; }
  &:focus {
    border: 1px solid $primary-color-subscriber !important;
    outline-offset: 0px;
    outline: none; } }

.cardCol {
  width: 100% !important;
  max-width: 22rem !important; }


@media screen and ( max-width: 703px  ) {
  .cardCol {
    max-width: 21rem !important; } }


.cardbodyicon {
  text-align: center !important;
  font-size: 20px !important;
  margin-top: 25px !important; }

.cardbody {
  text-align: center !important;
  font-size: 20px !important;
  margin-bottom: 25px !important;
  color: grey !important; }

.icon {
  width: 50px !important;
  height: 50px !important;
  margin-top: 10px; }

.helpText {
  font-size: 35px !important;
  font-weight: bold !important;
  color: grey !important; }


@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important;
  &-active {
    margin-top: 15px; } }
.activeSettingTab {
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1) !important; }
.activeSettingIcon {
  & path {
    stroke: rgba(36, 46, 66, 0.5); } }
.icon {
  margin-right: 8px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: inline-block;
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.3); }
  &-orange {
    background: $orange; }
  &-success {
    background: $green; }
  &-default {
    background: rgba($general-text-color, 0.7); }
  &-eye {
    background: unset;
    & svg {
      transition: fill 0.35s;
      width: 27px;
      height: auto;
      fill: rgba($general-text-color, 0.45); } }
  &-eye-active {
    & svg {
      fill: $primary-color; } } }

.button {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 100;
  background: $primary-btn-color;
  font-family: 'Roboto', sans-serif;
  width: 170px;
  color: $default-button-txt;
  margin-top: 2px !important; }

.eyeIcon {
  margin-left: 8px;
  width: 27px;
  height: auto;
  fill: $primary-color; }

.title {
  color: $page-header-txt; }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.checkboxDescription {
  margin-top: 0.3rem !important;
  font-size: 14px !important;
  margin-left: 0.5rem !important; }

.checkboxDescription1 {
  margin-top: 0.3rem !important;
  font-size: 14px !important;
  margin-left: 0.2rem !important; }
.citationSettingTab {
  min-width: fit-content;
  padding: 10px !important; }
.activeCitationSettingTab {
  padding-right: 10px !important;
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1) !important; }

.checkBox {
  margin-top: -6% !important;
  margin-bottom: 10px;
  margin-left: -10px; }

.checkboxText {
  opacity: 0.6 !important;
  font-size: 13px !important;
  margin-left: 1.6rem !important;
  padding-right: 1rem !important; }

// .checkboxDescription
//   font-size: 13px !important
//   margin-left: 0.5rem !important

// .checkboxDescription1
//   font-size: 13px !important
//   margin-left: 0.2rem !important

.toolTip {
  width: 25px !important;
  height: 20px !important;
  margin-left: 0.5rem !important; }

.customCheckStyle {
  appearance: none;
  margin-top: 0.2rem  !important;
  font: inherit;
  color: currentColor;
  min-width: 1em !important;
  height: 1em !important;
  border: 1px solid #b2b6b9 !important;
  border-radius: 0.19em !important;
  transform: translateY(-0.075em);

  &::before {
    content: "";
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  &:checked::before {
    transform: scale(1); } }

.checkboxRow {
  display: flex !important;
  margin-left: 0px !important;
  margin-top: 1rem;
  width: 100%; }

.citationBtn {
  min-width: fit-content !important;
  @media screen and ( max-width: 950px ) {
    min-width: 45px !important;
    max-width: 45px !important; } }


@import 'src/styles/variables';

.cardRow {
  margin-top: 20px;
  font-size: 12px !important; }

.newPage {
  padding: 20px;
  font-family: Roboto, sans-serif;
  background: #f8f9fa !important;
  border: none !important; }

.customCard {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; }

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px !important; }


.leftContent {
  display: flex;
  align-items: center; }

.cardImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  margin-top: 2rem; }

.cardTitle {
  font-size: 17px;
  font-weight: bold; }

.cardText {
  font-size: 14px !important;
  color: #555;
  margin-bottom: 8px;
  margin-top: -0.2rem !important; }

.recordSection {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px !important; }

.recordItem {
  display: flex;
  flex-direction: column;
  margin-right: 16px; }

.recordLabel {
  font-size: 14px;
  color: #555;
  margin-bottom: 4px; }

.recordValue {
  font-size: 14px;
  color: #333; }




.rightContent {
  text-align: right; }

.cardTextRight {
  font-size: 14px;
  color: #555;
  margin-bottom: 4rem;
  margin-right: 16px;
  display: flex;
  justify-content: flex-end; }

.LocationIcon {
  position: relative;
  height: 20px;
  width: 20px;
  bottom: 2px;
  stroke: $disabled-bg-color; }

.title {
  font-size: 25px !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  color: rgba($general-text-color, 0.7) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif; }


.status {
  color: $green;
  font-size: 14px;
  margin-top: 1rem;
  margin-left: 0.3rem; }

.imageCol {
  border-right: 1px solid $disabled-bg-color; }

.paid {
  color: $green;
  font-size: 14px;
  margin-bottom: 5rem;
  margin-right: 16px;
  display: flex;
  justify-content: flex-end; }

.unpaid {
  color: $red;
  font-size: 14px;
  margin-bottom: 5rem;
  margin-right: 16px;
  display: flex;
  justify-content: flex-end; }


.btnFilter {
  margin-left: 15px;
  margin-right: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }


.filters {
  background: white !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 50px;
  padding: 0 !important;
  width: 100%; }

.collapse {
  margin-top: -1rem !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }


.searchButton {
  min-width: 18rem;
  width: 18rem;
  border-radius: 8px; }


.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }

.tabs {
  width: 100% !important;
  margin-left: -0.8rem !important; }

.noCP {
  text-align: center !important;
  font-size: 14px !important;
  color: $general-text-color;
  align-items: center !important;
  display: flex;
  justify-content: center;
  margin-top: 10%; }

@import 'src/styles/variables';

.heading {
  font-size: 1.6rem;
  font-weight: 600 !important;
  color: white !important; }

.backButton {
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 50;
  min-width: unset;
  height: 40px;
  padding: 10px;
  color: black !important; }

.map {
  border: 2px solid #fa9050;
  min-height: 300px; }
.title {
  font-size: 18px !important;
  color: #fa9050; }
.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 75%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }

.app {
  margin: 0 auto;
  width: 50%; }

.default {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500px;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  display: flex;
  flex-direction: column; }

.form {
  width: 15%;
  min-width: 300px; }
.btn {
  font-size: 15px;
  height: 40px;
  font-weight: 100;
  color: white !important;
  border-radius: 8px;
  &-process {
    background: $primary-btn-color-subscriber !important;
    width: 100px;
    font-weight: bold;
    margin-right: 2.45rem;
    margin-top: 1.4rem; } }

.formInput {
  font-size: 14px;
  text-align: center;
  border-radius: 8px !important;
  box-shadow: #ee6211  !important;
  border: 1px solid #b7c6d6 !important;
  margin-left: -10%;
  margin-top: -7%;
  width: 20px; }

.playWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($general-text-color, 0.45); }
.imgThumb {
  border-radius: 8px !important;
  width: 250px;
  height: 150px;
  object-fit: contain; }
.image {
  pointer-events: none; }
.text {
  width: 50%;
  min-width: 400px;
  color: rgba($general-text-color, 0.8);
  & em {
    font-style: normal;
    color: $red;
    text-style: normal; } }


.button {
  background: $primary-btn-color-subscriber;
  font-size: 1.2rem;
  font-weight: bolder;
  font-family: 'Times New Roman',Times,  sans-serif;
  width: 250px;
  height: 50px;
  padding: 25px;
  color: white !important;
  border-radius: 70%;
  border-radius: 0%;
  margin-top: 0.1px;
  margin-left: 80%;
  color: white; }

.title {
  color: $general-text-color !important;
  font-size: 18px !important;
  margin-top: 20px;
  font-weight: bold;
  opacity: 0.8; }

.section {
  background: rgba($primary-color-subscriber, 0.3);
  border-radius: 8px;
  min-height: 60px;
  padding: 20px 0px;
  width: 35%;
  margin-left: 33%;
  margin-top: 1%; }

@media (max-width: 768px) {
  .container {}
  grid-template-columns: repeat(2, 1fr); }


.content {
  font-size: 14px;
  line-height: 19.5px;
  text-align: center; }

@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 16px !important; }
.form {
  padding: 10px 30px 20px; }
.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }
.button {
  border-radius: 8px;
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  background: $primary-btn-color;
  color: $default-button-txt;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg;
    opacity: 0.6; } }

.button {
  border-radius: 8px;
  min-width: 100px;
  color: $default-button-txt;
  background: $primary-btn-color;
  &-bg-secondary {
    background: $cancel-button-bg !important;
    opacity: 0.6; } }
.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }
.fieldset {
  & button {
    border: unset !important;
    height: 40px; } }
.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 85%;
        margin-right: 0.5rem !important; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important; }

.formLabel {
  & > div {
    margin-left: 10px;
    color: #000000;
    font-weight: 100;
    font-size: 13px;
    text-align: start; } }

.formGroupLabel {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 13px !important; }

.trashIcon {
  cursor: pointer;
  margin-top: -10rem;
  margin-left: 70%;
  &:hover {
    opacity: 1;
    path {
      stroke: red; } } }

.trashIconDisabled {
  cursor: pointer;
  margin-top: -10rem;
  margin-left: 70%; }

.addButton {
  background: $primary-btn-color;
  width: 80%;
  font-size: 14px;
  font-weight: 500 !important;
  border-radius: 8px;
  height: 40px; }

.formDateInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  outline: none !important;
  min-width: 270% !important;
  // width: 225% !important
  padding: .75rem !important;

  &-md {
    border-radius: 8px !important;
    box-shadow: none !important;
    outline: none !important;
    min-width: 269% !important;
    width: 225% !important;
    padding: .75rem !important;
    @media (min-width: 768px) and (max-width: 991px) {
      min-width: 360% !important; }
    @media (min-width: 576px) and (max-width: 767px) {
      min-width: 280% !important; }
    @media(max-width: 576px) {
      min-width: 360% !important; } } }


@media (min-width: 768px) and (max-width: 991px) {
  .formDateInput {
    width: 360% !important;
    margin-left: 1%; } }

@media (max-width: 575px) {
  .formDateInput {
    width: 360% !important;
    margin-left: 1%; } }

@media (min-width: 576px) and (max-width: 767px) {
  .formDateInput {
    min-width: 280% !important;
    margin-left: 1%; } }



.subdetailsLabel {
  font-weight: 500 !important;
  color: $general-text-color !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-bottom: 0.8rem !important;
  width: 25% !important;
  text-align: start !important;
  margin-left: 0.2rem !important; }


.parkingLotBox {
  width: 48% !important;
  border: 1px solid #ccc !important;
  margin: 0.2rem !important;
  padding: 0.3rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #a9aaaa !important;
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }

.validParkingLotBox {
  width: 48% !important;
  border: 1px solid #ccc !important;
  margin: 0.2rem !important;
  padding: 0.3rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #38c415ef !important;
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }


.invalidParkingLotBox {
  width: 48% !important;
  border: 1px solid #ccc !important;
  margin: 0.2rem !important;
  padding: 0.3rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #f10b0bef !important;
  &:hover {
    background-color: #f0f0f0 !important; }
  &.selectedParkingLot {
    background-color: #b3d9ff !important; } }



.parkingLotCell {
  display: flex !important;
  font-size: 13px !important;
  & input {
    margin-right: 0.6rem !important;
    position: relative !important;
    margin-bottom: 43px !important; }
  & span {
    flex-grow: 2 !important; } }





.scrollableContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  border: 1px solid #000000 !important;
  overflow-y: auto !important;
  max-height: 10.3rem; /* Set the desired max height */;
  width: 94.5% !important;
  margin-bottom: 50px !important;
  box-shadow: none !important;
  margin-right: 2rem !important;
  margin-top: 0.5rem !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #a9aaaa !important; }


.boxText {
  text-align: left !important;
  font-size: 13px !important; }

.spacesTxt {
  font-size: 13px !important;
  margin-top: -9% !important;
  margin-bottom: -0.5% !important;
  margin-left: 12rem !important;
  display: flex;
  justify-content: center !important;
  background: #9bffa3 !important;
  width: 3rem !important;
  height: 1.5rem !important;
  border-radius: 5px !important;
  border: 1px solid #cdd4d4 !important; }



/* Add this media query to ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .parkingLotBox {
    width: 100% !important; } }



.alertMsg {
  color: red !important;
  font-size: 13px !important;
  margin-top: -40px !important;
  margin-left: -2% !important; }


.notify {
  color: green !important;
  font-size: 13px !important;
  margin-top: -40px !important;
  margin-left: -2% !important; }


.customStyle {
  appearance: none;
  margin-top: 0.2rem !important;
  font: inherit;
  color: currentColor;
  min-width: 1em !important;
  height: 1em !important;
  border: 1px solid $general-text-color !important;
  border-radius: 0.19em !important;
  transform: translateY(-0.075em);

  &::before {
    content: "";
    width: 0.55em !important;
    height: 0.55em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color !important;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  &:checked::before {
    transform: scale(1); } }

.formLabel2 {
  margin-left: 0.9rem !important;
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  text-align: start; }

.dropDownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-align: left !important; }

.dropDownInputError {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100% !important;
  text-align: left !important; }

.spacesSection {
  margin-left: 1.1rem !important;
  margin-bottom: 1.8rem !important;
  padding: 10px 20px 0 10px !important;
  font-size: 13px !important;
  border: 1px solid #000000  !important;
  width: 90% !important;
  border-radius: 5px !important; }

.totalSpacesText {
  text-align: left !important; }

.countNumber {
  font-size: 13px;
  font-weight: bold !important;
  color: $primary-color !important;
  margin-left: 1rem; }

.totalBookedSpacesText {
  font-size: 13px !important;
  text-align: right !important; }

.totalNumberVehiclesText {
  font-size: 13px !important;
  text-align: right !important; }

.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: inherit;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }

.confirmBtn {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: bold; }

.alertMsg {
  color: $danger-color !important;
  font-size: 13px !important;
  margin-top: -2.5rem !important;
  margin-bottom: 3rem !important;
  margin-left: 1.5% !important; }

.customCheckBox {
  appearance: none;
  margin-top: 0.1rem !important;
  font: inherit;
  color: currentColor;
  min-width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid rgba(216, 219, 234, 0.95);
  border-radius: 4px !important;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: "" !important;
    width: 0.6em !important;
    height: 0.6em !important;
    transform: scale(0) !important;
    transition: 120ms transform ease-in-out !important;
    background-color: white !important;
    border: none !important;
    transform-origin: bottom left !important;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%) !important; }
  &:checked::before {
    transform: scale(1) !important; }
  &:checked {
    background: $primary-color !important;
    border: none !important; } }

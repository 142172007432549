@import 'src/styles/variables';

.fieldset {
  &-title {
    font-size: 14px;
    color: rgba($general-text-color, 0.65); }
  & > button {
    border: unset !important; }
  & > input {
    box-shadow: none !important;
    border: none !important; }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    & > div {
      width: 43%;
      margin-right: 8px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  text-align: center;
  font-size: 13px;
  & > button {
    border: unset !important;
    box-shadow: none !important; }
  & > input {
    font-size: 13px; } }
.label {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  & > span {
    margin-left: 10px; } }
.trashIcon {
  cursor: pointer;
  margin-bottom: 24px;
  &:hover {
    opcaity: 1;
    path {
      stroke: red; } } }

@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important;
  &-active {
    margin-top: 15px; } }
.form {
  width: 100%;
  padding: 15px 25px 15px 0px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.formInput2 {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  &:focus {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; }
  &:hover {
    box-shadow: none;
    border: 1px solid #D8DBEA !important; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em; }
.btnFilter {
  margin-bottom: 10px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  position: absolute;
  border-radius: 8px; }
.btnClear {
  margin-top: 2px;
  min-width: 43px;
  font-size: 13px;
  background-color: transparent;
  border-radius: 8px;
  color: $general-text-color; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }

.eyeIcon {
  margin-left: 8px;
  width: 27px;
  height: auto;
  fill: $primary-color; }

.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  &-secondary {
    background: rgba($general-text-color, 0.45); }
  &-refresh {
    min-width: unset;
    width: fit-content;
    font-size: 16px; } }
.actions {
  & > div {
    & > span {
      transition: display 0.35s; } } }
.background {
  &-primary {
    background: $primary-color; }
  &-secondary {
    background: rgba($general-text-color, 0.7); }
  &-danger {
    background: $red; }
  &-ternary {
    background: $orange; } }
.subSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  padding: 20px 0;
  width: 100%; }

.fieldset {
  padding: 0 40px 0 0 !important;
  &-dateStroke {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 20px;
    left: -20px; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; } }
.createButton {
  margin-right: 3.5rem !important; }

.tab {
  width: fit-content; }

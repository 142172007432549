@import 'src/styles/variables';

.title {
  font-size: 16px !important; }
.form {
  padding: 10px 20px 20px 30px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.button {
  border-radius: 8px;
  min-width: 100px;
  color: $default-button-txt;
  background: $save-button-bg;
  &-bg-secondary {
    background-color: $cancel-button-bg !important;
    opacity: 0.6; } }

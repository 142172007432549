@import 'src/styles/variables';

.cancelButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: rgba($general-text-color, 0.4) !important; }
.submitButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  background-color: $primary-btn-color !important; }
.content {
  text-align: left;
  font-size: 14px; }
.body {
  padding: 10px 40px 20px; }
.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }
.bulletContent {
  display: flex;
  line-height: 2;
  margin-left: 18px; }

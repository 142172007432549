@import 'src/styles/variables';

.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: left !important;
  align-content: left !important;
  margin-left: 3rem !important;


  h1 {
    color: red; }


  .tag-container {
    min-width: 60%;
    max-width: 90%;
    display: flex;
    flex-wrap: wrap;
    min-height: 30px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;

    .tag {
      display: flex;
      align-items: center;
      padding: 5px;
      border: 1px solid gray;
      height: 25px;
      margin: 2px 5px 2px 0px;
      color: $general-text-color;
      font-size: 12px;
      border-radius: 10px;
      span {
        margin-left: 5px;
        cursor: pointer;
        & path {
          stroke: red; } } }

    input {
      border: none;
      flex: 1;
      outline: none;
      padding: 5;
      font-size: 14px; } } }

.invalidTagContainer {
  border: 1px solid red !important; }

.validTagContainer {
  border: 1px solid #1fbd14 !important; }

.removeicon {
  height: 10px !important;
  width: 15px !important;
  margin-top: -0.1rem !important; }

.error {
  color: red;
  margin: 0;
  font-size: 12px;
  margin-right: 3rem; }

.title {
  color: $general-text-color !important;
  font-size: 16px !important;
  margin-right: 2rem !important; }


.subText {
  font-size: 14px !important;
  margin-right: 2.5rem !important;
  margin-top: 0.7rem !important; }

.subText1 {
  font-size: 12px !important;
  text-align: left !important;
  font-weight: 500 !important;
  margin-top: 1rem !important; }


.note {
  font-size: 12px !important;
  text-align: left !important;
  font-weight: 500 !important;
  margin-top: 0.3rem !important;
  margin-left: 0.3rem !important;
  color: rgba($general-text-color, 0.7); }


.button {
  border-radius: 8px;
  min-width: 140px;
  margin-right: 3.5rem !important;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  &:hover {
      background-color: #2ee421; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45);
    &:hover {
      background-color: #000000; }
    &:active {
      transform: scale(0.95); } } }


.remove {
  color: red !important; }

.line {
  width: 90%;
  margin-left: 0rem; }

.numInput {
  height: 35px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: center !important;
  width: 45% !important;
  max-width: 50%;
  font-size: 14px;
  margin-right: 13rem !important; }



.postBtn {
  background-color: #2ee421;
  font-weight: bold;
  margin-top: -7%;
  min-width: 110px;
  font-size: 14px;
  margin-left: 60% !important;
  height: 35px !important;
  border-radius: 8px !important;
  font-size: 14px;
  position: absolute !important;
  &:hover {
      background-color: #1388f5; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45);
    &:hover {
      background-color: #000000; }
    &:active {
      transform: scale(0.95); } } }


.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 85%; } } } }

.note2 {
  font-size: 12px !important;
  text-align: left !important;
  font-weight: 500;
  margin-top: 0.3rem !important;
  margin-left: 0.3rem !important;
  color: #000000; }

.note3 {
  font-size: 12px !important;
  text-align: left !important;
  font-weight: 500 !important;
  margin-top: 0.3rem !important;
  margin-left: 0.3rem !important;
  color: #000000; }

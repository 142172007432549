@import 'src/styles/variables';

.title {
  color: $general-text-color !important;
  font-size: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }


.fieldset {
  width: 50%;
  margin-left: -1rem;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }


@media (max-width: 991px) {
  .formInput {
    width: 90% !important; }
  .formDropdownInput {
    width: 90% !important; } }


.category {
  font-size: 15px; }

.dollarIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }
.dotIcon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 10px !important;
  height: 10px; }
.iconInput {
  padding-left: 28px !important; }

.calInput {
  display: none !important; }
.button {
  border-radius: 8px;
  min-width: 140px;
  margin-right: 40px;
  height: 40px;
  font-size: 14px;
  color: $default-button-txt;
  background: $primary-btn-color;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg;
    opacity: 0.6; } }


.iconInput {
  padding-left: 28px !important; }
.plus_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 15px !important;
  height: 15px;
  color: $primary-color; }

.form {
  width: 100%;
  padding: 20px 0 20px 40px; }

.fieldset {
  & button {}
  &-right-label {
    & label {
      & > div {
        margin-left: 10px; } } } }

.fieldset-double {
  & > div {
    & > div {
      & > div:first-child {
        width: 85%; } } } }
.fieldset-single {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }

.fieldset-valPr {
  & > div {
    & > div {
      & > div:first-child {
        width: 90%; } } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.formInputPlate {
  border-radius: 8px !important;
  box-shadow: none !important;
  margin-left: -35px !important;
  border: 1px solid #ced4da !important;
  max-width: 25rem !important; }
.formLabelPlate {
  & > div {
    margin-left: -26px;
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }
.formLabel {
  & > div {
    margin-left: -10px;
    color: #000000;
    font-weight: 100;
    font-size: 13px; } }

.body {
  padding: 10px 40px 20px;
  font-size: 13px;
  color: rgba($general-text-color, 0.7); }
.boldText {
  color: 'grey-dark';
  font-weight: bold; }
.numberLabel {
  width: 20px;
  text-align: end; }
.greyButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: rgba($general-text-color, 0.4) !important; }
.orangeButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $orange !important; }
.primaryButton {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 20px 40px;
  background-color: $primary-btn-color !important; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }



.locationbtn {
  background-color: white !important;
  color: $primary-color !important;
  font-weight: bolder !important;
  margin-left: 72%;
  margin-top: -22px;
  text-decoration: underline; }

.fetching {
  background-color: white !important;
  color: $primary-color !important;
  font-weight: bolder !important;
  margin-left: 65%;
  margin-top: -22px;
  text-decoration: underline; }

.parkingLotBox {
  width: 48%; /* Adjust as needed for spacing between boxes */;
  border: 1px solid #ccc;
  margin: 0.2rem;
  padding: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: auto;
  &:hover {
    background-color: #f0f0f0; }
  &.selectedParkingLot {
    background-color: #b3d9ff; /* Adjust as needed for selected background color */; } }



.parkingLotCell {
  display: flex;
  align-items: center;
  font-size: 13px;

  & input {
    margin-right: 0.6rem;
    position: relative;
    margin-bottom: 43px; }
  & span {
    flex-grow: 2;
    text-justify: distribute !important; } }



.scrollableContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #000000;
  overflow-y: auto;
  max-height: 150px; /* Set the desired max height */;
  width: 94.5% !important;
  margin-bottom: 50px;
  box-shadow: none;
  margin-left: -20px;
  margin-top: 10px; }



/* Add this media query to ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .parkingLotBox {
    width: 100%; /* Display one box per row on smaller screens */; }
  .validParkingLotBox {
     width: 100%; /* Display one box per row on smaller screens */; } }


.alertMsg {
  color: red;
  font-size: 13px;
  margin-top: -40px;
  margin-left: -2%; }


.notify {
  color: green;
  font-size: 13px;
  margin-top: -40px;
  margin-left: -2%; }

.validParkingLotsScrollable {
  overflow-y: auto;
  max-height: 130px; /* Set the desired max height */;
  width: 90% !important;
  margin-top: 10px !important;
  margin-bottom: 50px;
  box-shadow: none;
  background: #e9ecef;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }

.validParkingLotCell {
  display: flex;
  align-items: center;
  font-size: 12px;
  & input {
    margin-right: 0.6rem;
    position: relative;
    margin-bottom: 43px; }
  & span {
    flex-grow: 2;
    text-justify: distribute !important; } }
.validParkingLotBox {
  width: 48%; /* Adjust as needed for spacing between boxes */;
  margin: 0.2rem;
  padding: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: auto; }




.okBtnErrorStyle {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  margin-top: -9.5%;
  min-width: 40px;
  margin-left: 71.4% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; }
  border: 0.1px solid #24acc4 !important;
  border-radius: 8px !important; }

.detailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 14px !important;
  margin-left: -5% !important;
  margin-bottom: 5% !important; }


.customLabel {
  font-weight: 1000 !important; }

.ownerDetailsLabel {
  font-weight: 1000 !important;
  color: #616161 !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  margin-left: -6%; }

.dragDropLabel {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  margin-left: 120%;
  font-weight: 1000px;
  margin-top: -15%;
  position: relative; }


.boldText1 {
  color: 'grey-dark';
  font-weight: 700;
  font-size: 15px; }

.dragDropLabel2 {
  border-radius: 8px;
  max-width: 300px;
  margin-right: 40%;
  margin-top: 8%; }

.formatsText {
  color: black;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 20px; }


.boldText2 {
  color: 'black';
  font-weight: 500;
  font-size: 13px; }

.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }


.transitionContainer {
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out, overflow 0.5s ease-in-out; }

.fadeOut {
  opacity: 0;
  height: 0;
  overflow: hidden; }

.fadeIn {
  opacity: 1;
  height: auto;
  overflow: visibl; }

.fetchBtn {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-top: -60px;
  position: relative; }

.fetchBtnErrorStyle {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  min-width: 40px !important;
  margin-left: 86.5% !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-top: -72px;
  position: relative; }

.content {
  font-size: 14px;
  letter-spacing: inherit !important;
  line-height: 20px !important;
  padding-top: 15px !important;
  justify-content: center !important;
  text-align: justify !important; }


.confirmBtn {
  border-radius: 8px;
  min-width: 100px;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: bold; }


.editBtn {
  background-color: $general-text-color !important;
  color: #fffefe !important;
  font-weight: 350 !important;
  min-width: 53px;
  margin-left: 3% !important;
  height: 25px;
  font-size: 13px;
  position: absolute !important;
  border-radius: 20px !important;
  margin-top: -11px;
  position: relative; }

.formDropdownLabel {
  margin-left: -14% !important;
  color: #413e3e;
  font-weight: 100;
  font-size: 13px; }

.formDropdownInput {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  width: 100%; }

.noteLabel {
  margin-left: -0.5rem;
  color: #000000;
  font-weight: 500;
  font-size: 13px; }

.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 10%; }




.cardPreview {
  flex: 1;
  margin-right: 50px;
  margin-top: 65px;
  margin-left: -6px; }

.cardInputs {
  flex: 1;
  margin-left: 20px; /* Adjust spacing between card preview and inputs */;
  margin-right: 140px;
  margin-top: -5%; }


.cardLabels {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: justify !important; }

.cardInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da;
  height: 40px;
  width: 135%;
  max-width: 135%;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 14px;
  &:focus {
    border: 1px solid #046dd6; } }

.expiryDropdown {
  height: 40px;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-align: left !important;
  width: 325% !important;
  max-width: 325%;
  font-size: 14px; }

.expiryDropdownLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #000000;
  font-weight: 100;
  font-size: 13px;
  margin-top: -20px;
  justify-content: center !important;
  text-align: justify !important; }

.cardLabels2 {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #5e5858;
  font-weight: 100;
  font-size: 13px;
  margin-top: 5px !important;
  justify-content: center !important;
  text-align: justify !important; }


.commuterLabel {
  display: block;
  margin-bottom: 4px;
  margin-left: 0px;
  color: #7a7878;
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
  justify-content: center !important;
  text-align: justify !important; }



.commuterTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 15px !important;
  margin-left: 9rem !important; }


.addVehicleBtn {
  background-color: transparent !important;
  color: $primary-color  !important;
  font-weight: 650 !important;
  margin-top: 1px;
  min-width: 40px;
  margin-left: 0.5% !important;
  height: 40px;
  font-size: 14px;
  &-sm {
    min-width: 120px; }
  & bg-secondary {
    background: red; } }

.formGroupLabel {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }




.okBtn {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  margin-top: -60px;
  min-width: 40px;
  margin-left: 84.5% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: red; }
  border: 0.1px solid #24acc4 !important;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.okBtnErrorStyle {
  background-color: $primary-color !important;
  color: #000000 !important;
  font-weight: 350 !important;
  margin-top: -15.4%;
  min-width: 40px;
  margin-left: 84% !important;
  height: 40px;
  font-size: 14px;
  position: absolute !important;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: red; }
  border: 0.1px solid #24acc4 !important;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

@media (min-width: 768px) and (max-width: 991px) {
  .okBtn {
    margin-left: 80% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .okBtnErrorStyle {
    margin-left: 80% !important;
    margin-top: -15.4% !important; }
  .formInputPlate {
    width: 90% !important;
    margin-left: -1rem; } }



@media (min-width: 576px) and (max-width: 767px) {
  .okBtn {
    margin-left: 80% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .okBtnErrorStyle {
    margin-top: -15% !important; }
  .formInputPlate {
    width: 94% !important;
    margin-left: -1.1rem; }
  .formInput {
    width: 95.2% !important;
    margin-left: -0.4rem; }
  .formDropdownInput {
    width: 95.2% !important;
    margin-left: -0.3rem; } }

@media (max-width: 575px) {
  .okBtn {
    margin-left: 86.3% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .okBtnErrorStyle {
    margin-top: -15% !important; }
  .formInput {
    width: 96% !important;
    margin-left: -0.3rem; }
  .formDropdownInput {
    width: 96% !important;
    margin-left: -0.3rem; } }

.customLabelvehicle {
  padding-left: 30px !important; }

@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 220px) !important; }
.tableActive {
  margin-top: 15px; }
.resetButton {
  background-color: #242E42 !important;
  opacity: 0.6 !important;
  border-radius: 11px;
  min-width: 100px;
  font-weight: 600; }
.revokeButton {
  min-width: 50px;
  border-radius: 15px;
  padding: 12px; }
.searchButton {
  border-radius: 11px;
  min-width: 100px;
  margin-right: 10px;
  font-weight: 600; }
.form {
  width: 100%;
  padding: 15px 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }
.dangerBg {
  background: #dc3545; }
.disabledBg {
  background: rgba(#242E42, 0.6); }
.activeFilterTab {
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1); }
.activeFilterIcon {
  & path {
    fill: rgba($general-text-color, 0.5) !important; } }
.collapseSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }
.navButton {
  border-radius: 8px !important;
  background-color: $primary-color !important; }
.revokedText {
  width: 64px;
  text-align: center; }
.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); }
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.5px solid rgba($general-text-color, 0.6); }
  &-vehicle {
    height: auto;
    fill: rgba($general-text-color, 0.45) !important; } }
.actions {
  & > div {
    & > span {
      transition: display 0.35s; } } }
.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); }
  &-primary {
    background: $primary-color; }
  &-vehicles {
    background: $danger-color; } }
.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.btnFilter {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px;
  color: $default-button-txt; }
.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em;
  color: $page-header-txt !important; }
.fieldset {
  padding: 0 40px 0 0 !important;
  &-dateStroke {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 20px;
    left: -20px; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; } }

.button {
  color: $default-button-txt !important;
  path {
    stroke: $default-button-txt !important; } }
.btnSetting {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $primary-color;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.checkboxDescription {
  font-size: 16px !important;
  margin-left: 0.5rem !important; }
.checkboxDescription1 {
  font-size: 14px !important;
  margin-left: 0.5rem !important;
  margin-top: 0.25rem !important; }
.unpaidRow {
  margin-left: 2.1rem !important;
  margin-top: 1rem !important; }

@media  ( max-width: 991px ) {
  .rightColumn {
    margin-top: 40px !important; } }

@media  ( max-width: 1330px ) {
  .fieldset {
    &-dateClear {
      display: none !important; } } }

.customCheckStyle {
  appearance: none;
  margin-top: 0.2rem  !important;
  font: inherit;
  color: currentColor;
  width: 1.2em !important;
  height: 1.2em !important;
  border: 1px solid #b2b6b9 !important;
  border-radius: 0.19em !important;
  transform: translateY(-0.075em);

  &::before {
    content: "";
    width: 0.8em !important;
    height: 0.8em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  &:checked::before {
    transform: scale(1); } }

@import 'src/styles/variables';

.title {
  font-size: 20px !important;
  margin-left: 40px !important;
  text-align: left !important; }
.date {
  font-size: 15px !important;
  margin-left: 40px !important;
  text-align: left !important; }
.text {
  font-size: 15px !important;
  margin-left: 40px !important;
  text-align: left !important; }
.form {
  padding: 10px 20px 20px 30px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.button {
  border-radius: 8px;
  width: 20%;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6) !important; }
  &-bg-danger {
    background-color: #ea3223 !important; } }
.button1 {
  border-radius: 8px;
  width: 20%; }

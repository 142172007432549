@import 'src/styles/variables';

.borderPrimary {
  padding: 30px 25px 20px 25px !important;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  border-top: 2px solid $primary-color !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 0 !important; }

.cardTitle {
  margin-bottom: 22px !important; }
.title {
  font-size: 14px;
  font-weight: 300; }

.dateDropdown {
  border: 1px solid rgba($snuff, 0.27) !important;
  border-radius: 0 !important;
  & > button:hover {
    background-color: $primary-color;
    & > span {
      color: white; } } }

.currentValueRow {
  margin-bottom: 0px; }
.currentValue {
  font-size: 18px;
  word-break: break-word; }
.previousResultRow {
  margin-top: 6px;
  min-height: 16px; }
.moreRow {
  margin-top: 6px;
  & span {
    opacity: 1 !important; } }

.secondaryText {
  color: $general-text-color;
  opacity: .6;
  font-size: 13px;
  font-weight: 300; }
.less {
  color: $danger-color;
  font-size: 14px; }
.raise {
  color: green; }

.fontMd {
  font-size: $btn-font-size-md !important;
  font-size: 14px; }

@media (min-width: 1600px) {
  .borderPrimary {
    padding: 40px 30px 35px 30px !important; }
  .cardTitle {
    margin-bottom: 42px !important; }
  .currentValueRow {
    margin-bottom: 12px; }
  .moreRow {
    margin-top: 20px; } }

.container {
  margin: auto; }
.listDataCard {
  margin: -7.5px !important; }

.dataCardContainer {
  padding: 7.5px !important; }

.frameContainer {
  overflow: hidden !important;
  background-color: transparent !important; }

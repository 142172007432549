@import 'src/styles/variables';

.title {
  font-size: 16px !important;
  text-align: center; }
.form {
  padding: 0 20px 20px; }
.detailsLabel {
  font-weight: 500 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 14px !important; }
.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.inputLabel1 {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }
.cancelButton {
  background-color: rgba($general-text-color, 0.4) !important;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px; }
.submitButton {
  background: $primary-color;
  font-weight: inherit;
  font-size: 14px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: 35px;
  margin-left: 20px; }

@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important; }
.tableActive {
  margin-top: 15px; }
.dangerColor {
  color: $danger-color-deep; }

.form {
  width: 100%;
  padding: 0 10px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 10px;
  min-height: 50px; }

.btnFilter {
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }

.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important; }
.background {
  &-primary {
    background: $primary-color !important; }
  &-orange {
    background: $orange !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }

.btnSettings {
  background-color: $grey-dark;
  min-width: 43px;
  min-height: 40px;
  font-size: 16px;
  border-radius: 10px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.eyeIcon {
  margin-left: 8px;
  width: 27px;
  height: auto;
  fill: $primary-color; }

.badge {
  font-size: 12px !important;
  border-radius: 4px;
  height: 20px;
  max-width: 22px;
  min-width: 22px;
  &-primary-background {
    background: $primary-color !important; }
  &-orange-background {
    background: $orange !important; }
  & div:first-child {
    padding: 0 6px; } }
.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }

.actions {
  & > div {
    & > span {
      transition: display 0.75s;
      padding: 5px 10px;
      border-radius: 15px; }
    & > span:first-child {
      border-right: 1px solid rgba($general-text-color, 0.6); } } }
.background {
  &-primary {
    background: $primary-color !important; }
  &-orange {
    background: $orange !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }


.pauseButton {
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  min-width: fit-content;
  border-radius: 8px;
  background: $danger-button-bg;
  padding-right: 15px; }

.resumeButton {
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  min-width: fit-content;
  border-radius: 8px;
  background: $save-button-bg;
  padding-right: 15px; }

.loader {
  margin-left: 50% !important; }

.dotIcon {
  width: 8px;
  height: 8px;
  border-radius: 50%; }

.fieldset {
  padding: 0 40px 0 0 !important;
  &-dateStroke {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 20px;
    left: -20px; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; } }

.innerTab {
  width: max-content !important; }

.input {
  box-shadow: none !important;
  border: 1px solid rgba(216, 219, 234, 0.95) !important;
  border-radius: 8px !important;
  &:focus {
    box-shadow: none;
    border: 1px solid rgba(216, 219, 234, 0.95) !important; } }
.inputLabel {
  text-align: left;
  color: #242E42 !important;
  opacity: 0.6 !important;
  font-size: 13px !important; }

.polygonIcon {
  left: -13px;
  position: absolute !important; }

.barIcon {
  left: 15px !important;
  position: absolute !important;
  width: 13px !important;
  height: 14px !important;
  top: 12px !important; }

.backIcon {
  font-size: 16px;
  margin: 0 15px 0 0;
  align-content: center !important; }

.title {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em;
  color: $page-header-txt !important;
  align-content: center !important; }

.disable {
  cursor: not-allowed; }

@import 'src/styles/variables';

.spacesNote {
  padding: 0.5rem !important;
  margin-left: 1.5rem !important;
  margin-top: 0.5rem !important;
  background: #ceffde;
  height: 3.3rem !important;
  font-size: 14px !important;
  border: 1px solid #b1b1b1  !important;
  width: 90% !important;
  border-radius: 5px !important;
  text-align: center !important; }


.totalSpacesText {
  text-align: center !important;
  margin-top: -1rem;
  line-height: 1.2; }


.prevButtons {
  border-radius: 8px;
  min-width: 120px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  color: $default-button-txt-subscriber;
  background: $primary-btn-color-subscriber;
  &:hover {
    background-color: #1388f5; }
  &:active {
    transform: scale(0.95); }
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: rgba($general-text-color, 0.45);
    &:hover {
      background-color: #000000; }
    &:active {
      transform: scale(0.95); } } }

.downloadIcon {
  opacity: 2 !important;
  height: 1.5rem;
  color: white !important;
  margin-left: -0.4rem;
  margin-top: -0.2rem;
  & path {
    stroke: white;
    fill: white !important; }
  &:hover {
    opacity: 1 !important; } }


.download {
  font-size: 14px !important;
  margin-left: 0.2rem; }


.previewSection {
  margin: 1rem;
  border: 1px solid rgba($general-text-color, 0.5); }

.lotName1 {
  margin-left: 0.5rem !important;
  margin-top: 0.3rem !important;
  font-size: 14px !important; }

.alertMsg1 {
  color: red !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 3% !important;
  margin-top: 0.3rem !important; }


.ccNumField {
  font-size: 13px !important; }

.placeholders {
  font-family: Roboto, sans-serif !important;
  font-size: 13px; }


::placeholder {
  color: $general-text-color !important;
  opacity: 0.5 !important; }

@media (min-width: 575px) and (max-width: 767px) {
  .form {
    width: 100%;
    padding: 20px 0 20px 0 !important; } }

.lotRequiredMsg {
  color: #FB745B !important;
  font-size: 12px !important;
  opacity: 0.9 !important;
  margin-left: 53px; }
.lotLabelSection {
  display: flex !important;
  justify-content: space-between !important; }

.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important;
  text-align: center; }


@import 'src/styles/variables';

.appModal {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }


.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }

.backdropC {
  width: 100% !important;
  height: 100% !important; }

@media (max-width: 575px) {
  .modal {
    display: flex !important;
    justify-content: center !important; } }

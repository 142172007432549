@import 'src/styles/variables';


.container {
  padding: 15px;
  margin-top: -25px !important;
  font-family: Roboto, sans-serif !important;
  background-color: #fafbfd; }


.formContainer {
  padding: 20px;
  border-radius: 8px;
  overflow-y: auto !important;
  display: flex;
  flex-direction: column !important;
  max-height: calc(100vh - 29vh) !important;
  font-family: Roboto, sans-serif !important;
  scrollbar-width: thin; }

.walletsContainer {
  padding: 20px;
  border-radius: 8px;
  width: 100% !important; }

.leftSection {
  width: 100%; }

.formRow {
  display: flex;
  justify-content: space-between; }

.formItem {
  width: 47%;
  font-size: 13px !important; }

.formItem1 {
  width: 50%; }

.title {
  font-size: 25px;
  text-align: center;
  margin-bottom: 25px; }

.form {
  padding: 15px; }



.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 190px !important;
  min-height: 190px !important;
  background: rgba($general-text-color, 0.1);
  border: 1px solid rgba($general-text-color, 0.1);
  border-radius: 50%;
  font-size: 24px;
  color: #1890ff;
  cursor: pointer;
  transition: all 0.3s ease; }

.uploadText {
  margin-top: 8px; }

.profilePicture {
  width: 190px;
  height: 190px;
  border-radius: 50%; }

.upload2 {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: left;
  width: 100%;
  height: 40px;
  border: 1px ridge rgba($general-text-color,0.6);
  font-size: 24px;
  color: $primary-color-subscriber;
  cursor: pointer;
  transition: all 0.3s ease; }


.uploadButton {
  background-color: $primary-btn-color-subscriber;
  color: $default-button-txt-subscriber;
  height: 35px;
  margin-top: 1rem;
  border-radius: 7px;
  margin-left: 2rem;
  position: relative; }




.formInput {
  height: 40px;
  font-size: 13px !important;
  width: 100%; }

.formInputDropdown {
  height: 40px;
  font-size: 13px !important;
  min-width: 102% !important;
  margin-left: -12px;
  @media (min-width: 1201px) and (max-width: 1600px) {
    min-width: 103% !important;
    margin-left: -10px; }
  @media (max-width: 575px) {
    min-width: 101% !important;
    margin-left: -2px; } }


.phoneInput {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0 !important;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 8px !important;
  line-height: 25px !important;
  height: 39px !important;
  width: 100% !important;
  outline: none !important; }


.dropdown {
  font-size: 13px !important;
  max-width: 700% !important;
  margin: 0 !important;
  margin-top: 3px !important;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  text-align: justify !important; }

.search {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  background: #FFFFFF;
  border-radius: 8px !important;
  line-height: 25px !important;
  outline: none !important; }

.calenderIcon {
  background: white;
  & path {
    fill: $primary-color-subscriber; } }

.calenderInput {
  height: 40px;
  font-size: 14px !important;
  width: 100%; }


.uploadedFileContainer {
  position: relative;
  display: inline-block; }


.uploadedFileContainer:hover .deleteButton {
  opacity: 1;
  content: 'Remove'; }


.deleteButton {
  position: absolute;
  top: 0;
  right: -25px;
  opacity: 1;
  transition: opacity 0.3s ease;
  width: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }


.profilePicture:hover {
  opacity: 0.8; }

.removeIcon {
  color: red !important; }

.trashBtn {
  background: red; }

.trashIcon {
  height: 15px;
  width: 15px;
  stroke: white; }



.editBtn {
  background-color: $primary-btn-color-subscriber !important; }

.formRow2 {
  display: flex;
  justify-content: center !important; }


.formItem2 {
  font-size: 15px;
  margin-left: 1rem;
  display: flex;
  text-align: left; }

.previewBtns {
  display: flex;
  justify-content: flex-end !important;
  align-self: center;
  margin-left: 33rem !important; }




.formInput2 {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 48% !important; }


.requiredMark {
  border: none !important;
  background: transparent !important; }

.formLabel {
  color: rgba($general-text-color, 0.6) !important;
  font-weight: 100;
  font-size: 13px;
  text-align: justify !important; }

.formDlnLabel {
  color: rgba($general-text-color, 0.6) !important;
  padding: 10px;
  paddin-right: 40px !important; }


.labelCol {
  color: $general-text-color;
  padding: 10px;
  margin-bottom: 5px;
  padding-right: 40px !important;
  @media (min-width: 1200px) and (max-width: 1517px) {
    min-width: 130% !important; } }

@media (min-width: 1517px) and (max-width: 1700px) {
  .labelCol {
    min-width: 117% !important; } }
@media (min-width: 1016px) and (max-width: 1128px) {
  .labelCol {
    min-width: 117% !important; } }

@media (min-width: 1517px) and (max-width: 1700px) {
  .labelCol {
    min-width: 117% !important; } }
@media (min-width: 803px) and (max-width: 887px) {
  .labelCol {
    min-width: 130% !important; } }

@media (max-width: 802px) {
  .labelCol {
    min-width: 120% !important; } }

.file-upload-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-left: 7px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); } }

.file-info {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden; }

.file-name {
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.upload-button, .action-buttons {
  margin-left: 10px; }

.custom-modal {
  .ant-modal-content {
    border-radius: 10px;
    animation: modal-appear 0.3s forwards; } }

.modal-header {
  text-align: center;
  font-size: 15px;
  font-weight: 500 !important;
  color: $general-text-color; }

.modal-title {
  margin: 0; }
.modal-description {
  margin-bottom: 10px;
  margin-top: 20px; }
.modal-content {
  max-height: calc(100vh - 100px); // Adjust the height as needed
  overflow: hidden;
  margin-top: 25px !important;
  background: rgba(0, 0, 0, 0.02); }
.preview-container {
  display: flex;
  justify-content: center;
  align-items: center; }
.preview-image {
  max-width: 80%; // Adjust the size of the preview as needed
  max-height: 100%;
  height: 25vh;
  margin-bottom: 20px; }
.preview-pdf {
  max-width: 90%;
  max-height: 50%  !important;
  margin-bottom: 40px;
  margin-top: -10px; }

@keyframes modal-appear {
  from {
    transform: translateY(-50px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }


.idProofUploadStyle {
  width: 133% !important;
  border: none !important; }

.deleteIdProofButtonInModal {
  display: flex  !important;
  justify-content: flex-end !important; }

.deleteButton2 {
  display: flex;
  align-items: center;
  background-color: $danger-button-bg-subscriber;
  color: $default-button-txt-subscriber;
  min-width: 37px;
  margin: 10px; }

.trashIcon {
  width: 13px;
  height: 13px;
  color: white !important; }



.buttonContainer {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0 !important;
  display: flex !important;
  justify-content: center !important; }

.prevButton {
  margin-right: 20px;
  background: $cancel-button-bg-subscriber;
  opacity: 0.6 !important;
  color: $default-button-txt-subscriber !important;
  width: 90px;
  font-size: 14px;
  font-weight: 600 !important;
  border-radius: 8px;
  height: 35px;
  outline: none !important;
  border: none !important;
  &:hover {
    opacity: 0.7 !important; }
  &:active {
    transform: scale(0.95); } }

.confirmButton {
  background: $primary-btn-color-subscriber !important;
  font-size: 14px;
  color: $default-button-txt-subscriber !important;
  width: 90px;
  font-weight: 600 !important;
  border-radius: 8px;
  height: 35px;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  &:active {
    border: none !important;
    box-shadow: none !important;
    transform: scale(0.95); } }


.deleteModalContent {
  font-size: 14px;
  text-align: center !important; }

.delete-modal-title {
  font-size: 15px; }


.viewBtn {
  background-color: $primary-btn-color-subscriber !important;
  color: white !important;
  border: none !important;
  margin-right: 5px;
  outline: none !important;
  &:hover {
    background-color: #1890ff; } }

.deleteBtn {
  background-color: $danger-button-bg-subscriber !important;
  color: $default-button-txt-subscriber !important;
  min-width: 37px;
  border: none !important;
  outline: none !important;
  &:hover {
    background-color: $red; } }

.uploadBtn {
  background-color: $primary-btn-color-subscriber;
  color: white !important;
  min-width: 37px;
  border: none !important;
  outline: none !important;
  &:hover {
    background-color: #1890ff; } }


.editIcon {
  color: $primary-color-subscriber !important;
  height: 13px;
  width: 13px; }


.radioBtns {
  width: 45%;
  margin-top: 1rem;

  @media (min-width: 1200px) and (max-width: 1216px) {
    width: 50%; } }

.customRadio {
  // Your custom styles for radio button
  // For example:
  &.ant-radio-wrapper {
    .ant-radio {
      // Define your custom styles for the radio button here
      border-color: white;
      &.ant-radio-checked {
        .ant-radio-inner {
          // Custom styles when radio button is checked
          border-color: red !important; }
        &:focus-within {
          .ant-radio-inner {
            // Custom styles when radio button is focused and checked
            border-color: red !important; } } } } } }



.tooltip {
  width: 25px !important;
  height: 20px !important;
  margin-right: 7.8rem !important;
  margin-top: 1rem;

  @media (min-width: 1200px) and (max-width: 1216px) {
    margin-top: 2.3rem !important;
    margin-right: 8rem !important; } }
.saveBtnRow {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  margin-top: 30px !important; }
.saveBtn {
  color: white !important;
  background-color: $primary-btn-color-subscriber !important;
  outline: none !important;
  height: 40px !important; }


.collapse {
  width: 100% !important;
  margin-bottom: 20px !important; }

.panel {
  padding: 10px !important; }

@media (max-width: 767px) {
  .collapse {
    display: block !important; } }



.languageSelector {
  width: 85px;
  height: 40px;
  margin-left: 10px;
  outline: none !important;
  background: transparent !important;
  position: relative; }

.languageSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: -20px;
  margin-left: -20px; }

.languageLabel {
  color: rgba($general-text-color, 0.6) !important;
  font-weight: 100;
  font-size: 13px;
  text-align: left !important;
  margin-top: 10px; }

.collapseTitle {
  font-size: 25px !important;
  text-align: center;
  color: rgba($general-text-color, 0.5) !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif; }


@media (max-width: 1199px) {
  .collapseTitle {
    text-align: left !important;
    font-size: 16px !important;
    color: rgba($general-text-color, 0.9) !important;
    padding: 0 !important;
    margin: 0 !important; } }

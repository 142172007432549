@import 'src/styles/variables';

.borderPrimary {
  padding: 30px 25px 20px 25px !important;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 8px !important;
  margin: 7px !important;
  height: 97% !important;
  box-shadow: 0px 1px 2px 0px #606C801A; }

.cardTitle {
  font-size: 14px !important;
  font-weight: 550 !important;
  color: rgba(36, 46, 66, 0.85) !important;
  margin-top: -5px !important;
  padding-bottom: 1.5rem !important; }
.title {
  font-size: 16px;
  font-weight: 550;
  color: #242E42D9; }

.dateDropdown {
  border: 1px solid rgba($snuff, 0.27) !important;
  border-radius: 0 !important;
  & > button:hover {
    background-color: $primary-color;
    & > span {
      color: white; } } }

.currentValueRow {
  margin-bottom: 0px; }
.currentValue {
  font-size: 18px;
  word-break: break-word; }
.previousResultRow {
  margin-top: 6px;
  min-height: 16px; }
.moreRow {
  margin-top: 6px;
  & span {
    opacity: 1 !important; } }

.secondaryText {
  color: $general-text-color;
  opacity: .6;
  font-size: 13px;
  font-weight: 300; }
.less {
  color: $danger-color;
  font-size: 14px; }
.raise {
  color: green; }

.fontMd {
  font-size: $btn-font-size-md !important;
  font-size: 14px; }

.vl {
  border-left: 0.5px dashed #E9EBF0 !important;
  height: 20px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px; }

.vline {
  border-left: 0.5px dashed #E9EBF0 !important;
  height: 14rem;
  position: relative; }

.icon {
  width: 50px !important;
  height: 50px !important; }

.rightSide {
  margin-top: -20px;
  text-align: center !important;
  align-content: center !important; }

.number {
  font-size: 24px !important;
  font-weight: 550!important;
  color: rgba(36, 46, 66, 0.85) !important;
  line-height: 28.13px !important;
  margin-top: 10px;
  padding-bottom:  8px !important; }

.borderDown {
  border: 0.75px dashed #E9EBF0 !important;
  margin-top: 15px !important;
  margin-bottom: 10px !important; }

.data {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: center;
  color: #B6B6B6 !important;
  padding-bottom: 8px !important; }

.data1 {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  text-align: center; }

.belowLabel {
  margin-left: 23% !important;
  width: 20rem !important;
  align-items: center !important;
  margin-top: -12px !important; }

.className {
  border: 0px solid rgba(216, 219, 234, 0.27) !important;
  text-align: right !important;
  & > button {
    background-color: white !important;
    border: 1px solid rgba($snuff, 0.27);
    box-shadow: none !important;
    height: 40px;
    &:disabled {
      background-color: #e9ecef !important;
      opacity: 1; }
    & > span {
      opacity: 0.8 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }
  & > div {
    border: 1px solid rgba($snuff, 0.27) !important;
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 190px !important;
    left: -8px !important;
    & > button {
      padding: 12px 12px;
      & > span {
        display: block !important;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
    & > button:hover {
      background-color: $primary-color;
      & > span {
        color: white;
        opacity: 1; } } }
  &-sm {
    & > button {
      padding: 7.5px 12px;
      & > span {
        font-size: 12px; } }
    & > div > button > span {
      font-size: 12px; } }
  &-md {
    & > button {
      padding: 10px 14px;
      & > span {
        font-size: 13px; } }
    & > div > button > span {
      font-size: 13px; } } }

.lotContainer {
  margin-left: 35px !important;
  font-size: 14px !important; }

.lotContainer1 {
  color: #242E42D9 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 0.4rem !important; }

.lotContainer2 {
  color: #3A9CED !important;
  font-weight: 600 !important; }

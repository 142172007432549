@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 220px) !important; }
.revokeButton {
  min-width: 50px;
  border-radius: 10px; }
.btnFilter {
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px;
  color: $default-button-txt; }
.navButton {
  border-radius: 8px !important;
  background-color: $primary-btn-color !important; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }

.table {
  display: block;
  max-height: calc(100vh - 220px) !important; }
.tableActive {
  margin-top: 15px; }
.resetButton {
  background-color: #242E42 !important;
  opacity: 0.6 !important;
  border-radius: 11px;
  min-width: 100px;
  font-weight: 600; }
.revokeButton {
  min-width: 50px;
  border-radius: 15px;
  padding: 12px; }
.searchButton {
  border-radius: 11px;
  min-width: 100px;
  margin-right: 10px;
  font-weight: 600; }
.form {
  width: 100%;
  padding: 15px 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.dangerBg {
  background: #dc3545; }
.disabledBg {
  background: rgba(#242E42, 0.6); }
.activeFilterTab {
  color: rgba($general-text-color, 0.5) !important;
  background: rgba($general-text-color, 0.1); }
.activeFilterIcon {
  & path {
    fill: rgba($general-text-color, 0.5) !important; } }
.collapseSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px; }
.navButton {
  border-radius: 8px !important;
  background-color: $primary-btn-color !important; }
.revokedText {
  width: 64px;
  text-align: center; }
.icon {
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); } }
.actions {
  & > div {
    & > span {
      transition: display 0.35s; } } }
.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); }
  &-primary {
    background: $primary-color; } }

.badge {
  font-size: 12px !important;
  border-radius: 4px;
  height: 20px;
  max-width: 22px;
  min-width: 22px;
  &-primary-background {
    background: $primary-color !important; }
  &-orange-background {
    background: $orange !important; }
  & div:first-child {
    padding: 0 6px; } }


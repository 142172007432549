@import 'src/styles/variables';

.cancelButton {
  background: $cancel-button-bg-subscriber;
  opacity: 0.45;
  color: $default-button-txt-subscriber;
  margin-right: 30px;
  font-weight: inherit;
  font-size: 16px;
  min-width: 100px; }
.submitButton {
  background: $danger-button-bg-subscriber;
  color: $default-button-txt-subscriber;
  font-weight: inherit;
  font-size: 16px;
  min-width: 100px;
  letter-spacing: inherit; }

.content {
  font-size: 15px;
  letter-spacing: inherit !important;
  line-height: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  justify-content: center !important;
  text-align: justify !important;
  margin-left: 130px; }

.title {
  font-size: 18px !important; }

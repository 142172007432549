@import 'src/styles/variables';

.content {
  font-size: 14px;
  letter-spacing: inherit;
  line-height: inherit;
  text-align: left;
  padding-top: 15px; }

.boldText {
  color: rgba($general-text-color, 0.9);
  font-weight: 600; }

.button {
  border-radius: 8px;
  margin-left: 20px;
  min-width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  background-color: $red;
  color: $default-button-txt-subscriber !important;
  &-bg-secondary {
    background-color: rgba($general-text-color, 0.6); } }

@import 'src/styles/variables';

.borderPrimary {
  padding: 30px 30px 20px 30px !important;
  border-width: 0px !important;
  border: 1px solid rgba($snuff, 0.27) !important;
  box-shadow: 0 0 6px rgba($primary-color, 0.1);
  border-radius: 8px !important;
  margin: 7px !important;
  height: 96% !important;
  box-shadow: 0px 1px 2px 0px #606C801A; }

.cardTitle {
  margin-bottom: 10% !important;
  margin-top: -8px !important; }
.title {
  font-size: 16px;
  font-weight: 550;
  color: #242E42D9; }

.dateDropdown {
  border: 1px solid rgba($snuff, 0.27) !important;
  border-radius: 0 !important;
  & > button:hover {
    background-color: $primary-color;
    & > span {
      color: white; } } }

.currentValueRow {
  margin-bottom: 0px; }
.currentValue {
  font-size: 18px;
  word-break: break-word; }
.previousResultRow {
  margin-top: 6px;
  min-height: 16px; }
.moreRow {
  margin-top: 6px;
  & span {
    opacity: 1 !important; } }

.secondaryText {
  color: #242E42D9 !important;
  font-size: 14px !important;
  font-weight: 550 !important;
  margin-right: 0.4rem !important; }
.less {
  color: $danger-color;
  font-size: 14px; }
.raise {
  color: green; }

.fontMd {
  font-size: $btn-font-size-md !important;
  font-size: 14px; }

.app {
  width: 100% !important; }

.vl {
  border-left: 1px dashed #dee2e6 !important;
  height: 20px;
  position: relative;
  margin-left: 30px;
  margin-right: 30px; }

.vline {
  border-left: 1px dashed #dee2e6 !important;
  height: 200px;
  position: relative;
  top: -10px; }

.icon {
  width: 50px !important;
  height: 50px !important; }

.rightSide {
  align-content: center !important;
  text-align: center !important; }

.name {
  font-size: 14px !important;
  font-weight: 550 !important;
  margin-top: 10px;
  padding-bottom:  8px !important; }

.number {
  font-size: 24px !important;
  font-weight: 550 !important;
  margin-top: 10px;
  padding-bottom:  8px !important; }

.number2 {
  font-size: 24px !important;
  font-weight: 550 !important;
  margin-top: 10px;
  padding-bottom:  8px !important;
  color: #DADEE5 !important; }

.borderDown {
  border: 1px dashed #dee2e6 !important; }

.bottom {
  font-size: 15px !important;
  padding-bottom: 20px !important; }

.percentage {
  color: $primary-color;
  font-size: 12px !important;
  font-weight: 500 !important; }

.number1 {
  color: #B6B6B6 !important;
  font-size: 12px !important;
  font-weight: 500 !important; }

.dateDropdown {
  border: 1px solid rgba($snuff, 0.27) !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  & > button:hover {
    background-color: $primary-color;
    & > span {
      color: white; } }
  & > div {
    border: 1px solid rgba($snuff, 0.27) !important;
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 30px !important;
    & > button {
      padding: 12px 12px;
      & > span {
        display: block !important;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
    & > button:hover {
      background-color: $primary-color;
      & > span {
        color: white;
        opacity: 1; } } } }

.dateDropdown1 {
  height: 30px !important; }

  
  .field-types {
    border: 1px solid #d9d9d9;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .field-type {
    padding: 15px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 0.5px solid #3A9CED;
    border-radius: 8px;
    cursor: grab;
  }
  
  .field-type:hover {
    background-color: #bae7ff;
  }
  
  .form-section {
    border: 1px dashed #d9d9d9;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    max-height: 80vh;
    overflow-y: scroll;
    margin-top: 12px;
  }

  .application-form-section {
    border: 1px dashed #d9d9d9;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    max-height: 73.5vh;
    overflow-y: scroll;
    margin-top: 12px;
  }
  
  .ant-col-24.ant-form-item-label >label {
    display: block;
  }

  .ant-input-disabled, .ant-input[disabled] {
    color: #242E42;
    cursor: default;
  }

  .radio-options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Spacing between radio options */
  }
  
  .radio-option {
    display: flex;
    align-items: center;
    gap: 4px; /* Spacing between radio button and delete icon */
    margin-bottom: 8px; /* Spacing between rows */
  }
  
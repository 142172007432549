@import 'src/styles/variables';

.default {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500px;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color; }
.section {
  border-radius: 8px;
  padding: 20px 0;
  width: 100%;
  &-citation-details {
    min-height: 500px; } }
.form {
  width: 15%;
  min-width: 520px; }
.fieldset {
  width: 50%;
  &-right-label {
    & label {
      & > div {
        margin-left: auto; } } } }
.btn {
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  min-width: unset;
  padding: 15px 30px !important;
  color: white !important;
  border-radius: 8px;
  background: $primary-btn-color-subscriber;
  &-process {
    background: #3A9CED; }
  &-id-number {
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
    background: rgba($general-text-color, 0.8); }
  &-plate-number {
    padding: 20px;
    font-weight: 600;
    font-size: 16px;
    background: rgba($general-text-color, 0.8); }
  &-fine {
    padding: 20px;
    font-weight: 600;
    font-size: 16px;
    background: $red; }
  &-dispute {
    background: $red; }
  &-cancel {
    background: $grey-dark; }
  &-pay {
    margin-left: 27% !important;
    width: 130px !important; } }
.formInput {
  font-size: 14px;
  text-align: center;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formInputTime {
  font-size: 14px;
  text-align: center;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  width: 50% !important; }
.table {
  width: auto !important;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid rgba($general-text-color, 0.2) !important;
  & td {
    padding: 20px !important;
    border: 1px solid rgba($general-text-color, 0.2) !important; }
  & th {
    padding: 20px !important;
    border: 1px solid rgba($general-text-color, 0.2) !important;
    & > span {
      border-radius: 8px;
      padding: 10px 15px;
      color: $general-text-color;
      opacity: 0.7; } } }
.playWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($general-text-color, 0.45); }
.imgThumb {
  border-radius: 8px !important;
  width: 250px;
  height: 150px;
  object-fit: contain; }
.image {
  pointer-events: none; }
.text {
  width: 73%;
  min-width: 400px;
  color: rgba($general-text-color, 0.8);
  text-align: center !important;
  & em {
    font-style: normal;
    color: $red;
    text-style: normal; } }
.heading {
  background: #3A9CED;
  font-size: 1.6rem;
  font-weight: 600; }
.closeIcon {
  color: rgba($general-text-color, 0.6);
  cursor: pointer; }
.modalTitle {
  font-weight: 600 !important;
  color: #242E42 !important;
  opacity: 0.8 !important;
  font-size: 16px !important; }
.modal {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $general-text-color;
  border-radius: 8px !important; }

.downloadbutton {
  object-fit: contain;
  padding-left: 20px;
  padding-right: 20px; }

.name {
  font-size: 15px !important; }

.downloadIcon {
  margin-top: -83px;
  opacity: 0 !important;
  &:hover {
    opacity: 1 !important; } }

.documentIcon {
  margin-right: 3px !important; }

.namebox {
 }  // margin-top: -25px

.modalContent {
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.015em;
  text-align: left;
  color: $general-text-color;
  border-radius: 8px !important; }

.heightwidth {
  width: 97%;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 8px;
  &:focus {
    outline: none !important;
    border: 1px solid #ced4da; }
  &:placeholder {
    color: #fff; } }

.textTop {
  font-size: 1.5rem;
  font-weight: bold; }

.status {
  border-radius: 8px;
  padding: 10px 15px;
  background: #ffcc00;
  color: #242E42;
  font-weight: bold; }

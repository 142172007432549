@import 'src/styles/variables';

.table {
  display: block; }

.btn {
  height: 30px;
  width: 150px;
  padding: 4px;
  font-size: 12px !important;
  color: $default-button-txt;
  background: $primary-btn-color; }



@import 'src/styles/variables';

.signUpPage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 92.9vh;
  overflow: hidden;
  font-family: Roboto, sans-serif !important;
  background-image: url('./background.png');
  background-size: cover; }

@media (min-width: 564px ) and ( max-width: 692px  ) {
  .signUpPage {
    min-height: 109vh; } }



@media (width: 712px ) {
  .signUpPage {
    min-height: 93.7vh; } }


@media (max-height: 800px ) and (min-width: 1360px) and (max-width: 1370px) {
  .signUpPage {
    min-height: 91vh; }

  .formContainer {
    max-height: 659px !important;
    overflow: auto !important;
    margin: 20px !important; } }

@media (max-height: 780px ) and (min-width: 1360px) and (max-width: 1370px) {
  .signUpPage {
    min-height: 90vh; }

  .formContainer {
    max-height: 658px !important;
    overflow: auto !important;
    margin: 20px !important; } }

@media (max-height: 768px ) and (min-width: 1360px) and (max-width: 1370px) {
  .signUpPage {
    min-height: 70vh !important;
    max-height: 760px !important; }

  .formContainer {
    max-height: 658px !important;
    overflow: auto !important;
    margin: 20px !important; } }

@media (width: 540px ) and ( height: 720px  ) {
  .signUpPage {
    min-height: 119vh !important; }
  .formContainer {
    max-height: 800px !important;
    overflow: auto !important;
    margin: 20px; } }


@media (min-width: 530px ) and ( max-width: 563px  ) {
  .signUpPage {
    min-height: 140vh; } }

@media (min-width: 510px ) and ( max-width: 526px  ) {
  .signUpPage {
    min-height: 132vh; } }

@media (min-width: 480px ) and ( max-width: 509px  ) {
  .signUpPage {
    min-height: 140vh; } }

@media (min-width: 448px ) and ( max-width: 479px  ) {
  .signUpPage {
    min-height: 155vh; } }

@media (min-width: 430px ) and ( max-width: 447px  ) {
  .signUpPage {
    min-height: 159vh; } }

@media (width: 430px ) {
  .signUpPage {
    min-height: 161.5vh; } }

@media (min-width: 400px )  and (max-width: 429px) {
  .signUpPage {
    min-height: 160vh; } }

@media (width: 412px ) {
  .signUpPage {
    min-height: 161vh !important; } }

@media (width: 412px ) and (height: 732px) {
  .signUpPage {
    min-height: 159vh !important; } }

@media (width: 430px ) and (height: 932px) {
  .signUpPage {
    min-height: 154vh !important; } }

@media (width: 400px ) {
  .signUpPage {
    min-height: 174vh !important; } }

@media (width: 400px ) {
  .signUpPage {
    min-height: 174vh !important; } }

@media (min-width: 392px ) and (max-width: 399px) {
  .signUpPage {
    min-height: 168vh; }
  .formContainer {
    margin-bottom: 10px; } }

@media (min-width: 385px ) and (max-width: 392px) {
  .signUpPage {
    min-height: 170vh; } }

@media (min-width: 380px ) and (max-width: 385px) {
  .signUpPage {
    min-height: 170vh; } }

@media (min-width: 375px ) and (max-width: 379px) {
  .signUpPage {
    min-height: 184vh; } }


@media (min-width: 370px ) and (max-width: 379px) {
  .signUpPage {
    min-height: 175vh; } }

@media (min-width: 300px ) and (max-width: 369px) {
  .signUpPage {
    min-height: 180vh; } }

@media (min-width: 330px ) and (max-width: 363px) {
  .signUpPage {
    min-height: 182vh; }
  .formContainer {
    margin-bottom: 20px; } }

@media (width: 360px ) and (height: 740px) {
  .signUpPage {
    min-height: 183.5vh; } }


@media (width: 353px) and (height: 745px) {
  .signUpPage {
    min-height: 187.5vh; }
  .formContainer {
    margin-bottom: 20px; } }

@media (max-width: 329px) {
  .signUpPage {
    min-height: 237.5vh; } }

@media  ( width: 820px ) {
  .signUpPage {
    min-height: 94vh; } }
@media  ( width: 1024px ) and (height: 1366px) {
  .signUpPage {
    min-height: 94.8vh; } }

@media  ( width: 912px ) and (height: 1368px) {
  .signUpPage {
    min-height: 94.8vh; } }

@media  ( width: 853px ) and (height: 1280px) {
  .signUpPage {
    min-height: 94.5vh; } }

@media  ( width: 1280px ) and (height: 800px) {
  .formContainer {
    max-height: 700px;
    overflow: auto !important;
    margin: 5px !important; } }

@media  ( width: 320px ) and (height: 568px) {
  .formContainer {
    overflow: auto !important;
    margin-top: 100px !important; } }


.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  background-image: url('./background.png');
  background-size: cover;
  filter: blur(1px);
  z-index: -1; }

.formContainer {
  width: 100%;
  max-width: 650px;
  padding: 10px;
  border-radius: 8px;
  font-size: 13px !important;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-family: Roboto, sans-serif !important; }

.registerText {
  text-align: center;
  margin-top: 20px;  // Adjust margin for better visibility on small screens
  font-weight: bold;
  font-family: Roboto, sans-serif;
  color: $primary-color-subscriber; }


.steps {
  margin-bottom: 20px;
  padding: 20px; }

.form {
  padding: 20px;
  padding-top: 0 !important; }



.addButton {
  margin-bottom: 20px;
  margin-top: 20px;
  background: $primary-color-subscriber;
  font-size: 14px;
  width: fit-content !important;
  font-weight: 500 !important;
  border-radius: 8px;
  height: 40px;
  margin-left: 39%;
  box-shadow: none !important;
  border: none !important;

  &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important; }
  &::after {
    border: none !important;
    box-shadow: none !important;
    outline: none !important; } }

.table {
  margin-top: 20px; }

.buttonContainer {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0 !important; }

.prevButton {
  margin-right: 20px;
  background: rgba($general-text-color, 0.7);
  color: white !important;
  width: 120px;
  font-size: 14px;
  font-weight: 600 !important;
  border-radius: 8px;
  height: 40px;
  outline: none !important;
  &:hover {
    background: rgba($general-text-color, 0.7) !important;
    color: white !important; }
  &:active {
    transform: scale(0.95); } }

@media screen and (max-height: 600px) {
  .signUpPage {
    padding: 10px;
    align-items: flex-start; }  // Align items to the top for better visibility

  .formContainer {
    padding: 10px; }

  .registerText {
    font-size: 1.5rem;
    margin-top: 10px; }  // Adjust margin for better visibility on small screens

  .form {
    padding: 10px; }

  .buttonContainer {
    margin-top: 10px; } }


.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border: 2px dashed #1890ff;
  border-radius: 50%;
  font-size: 24px;
  color: #1890ff;
  cursor: pointer;
  transition: all 0.3s ease; }

.uploadText {
  margin-top: 8px; }

.profilePicture {
  width: 120px;
  height: 120px;
  border-radius: 50%; }



.formInput {
  height: 40px;
  font-size: 13px !important;
  width: 98%; }

.formInputNumber {
  height: 40px;
  font-size: 13px !important;
  width: 100% !important;
  display: flex;
  align-items: center !important; }

.button {
  background: $primary-color-subscriber;
  font-size: 14px;
  color: white !important;
  width: 120px;
  font-weight: 600 !important;
  border-radius: 8px;
  height: 40px;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  &:active {
    border: none !important;
    box-shadow: none !important;
    transform: scale(0.95); } }


.phoneInput {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0 !important;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 8px !important;
  line-height: 25px !important;
  height: 39px !important;
  max-width: 100% !important;
  outline: none !important; }

.dropdown {
  font-size: 13px !important;
  max-width: 740% !important;
  margin: 0 !important;
  margin-top: 3px !important;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  text-align: justify !important; }

.search {
  position: relative !important;
  font-size: 13px !important;
  letter-spacing: .01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 8px !important;
  line-height: 25px !important;
  outline: none !important; }

.instructions {
  margin-top: -1rem !important;
  margin-bottom: 2rem !important; }


.uploadedFileContainer {
  position: relative;
  display: inline-block; }


.uploadedFileContainer:hover .deleteButton {
  opacity: 1;
  content: 'Remove'; }


.deleteButton {
  position: absolute;
  top: 0;
  right: -30px;
  opacity: 1;
  transition: opacity 0.3s ease;
  width: 20px;
  text-align: center; }



.profilePicture:hover {
  opacity: 0.8; }

.removeIcon {
  color: red;
  height: 15px; }



.formRow {
  display: flex;
  margin-top: 30px; }

.formRow1 {
  display: inline-flex;
  justify-content: flex-start !important;
  margin-top: 30px; }
.formItem {
  width: 100%; }

.cloudIcon {
  color: $primary-color-subscriber !important; }

.deleteIdProofButton {
  position: absolute;
  top: 1px;
  right: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba($red, 0.6);
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s; }

.buttonEdit {
  background-color: #1f82da !important;
  margin-left: 5rem; }

.trashButton {
  background-color: #dc3545 !important; }


.fetchBtn {
  background-color: $primary-color-subscriber;
  height: 40px;
  border: none !important;
  box-shadow: none !important; }


.formInput2 {
  height: 40px; }

.radioBtns {
  width: 40%; }

.calenderIcon {
  background: white;
  & path {
    fill: $primary-color-subscriber; } }

.calenderInput {
  height: 40px;
  font-size: 14px !important;
  width: 100%; }

.requiredMark {
  border: none !important;
  margin-right: 1px !important;
  background-color: transparent !important; }


.formLabel {
  color: rgba($general-text-color, 0.7) !important;
  font-weight: 100;
  font-size: 13px;
  text-align: justify !important; }

.titleDivider {
  font-size: 19px !important;
  font-weight: bold !important;
  color: rgba($general-text-color, 0.9) !important; }

.regCardLabel {
  font-size: 13px;
  text-align: center !important;
  margin-top: -20px;
  margin-bottom: 25px;
  color: rgba($general-text-color, 0.7) !important; }

.phoneCol {
  text-align: left; }
.idCol {
  text-align: left; }
.expiryCol {
  text-align: left; }

@import "src/styles/variables";

.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto !important;
  font-family: Roboto, sans-serif !important;
  scrollbar-width: thin; }

.card {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0 !important;
  margin: 0 !important; }

.header {
  background: $primary-color-subscriber; }


.table {
  margin-bottom: 20px;
  width: 100% !important;
  padding: 0 !important;
  font-size: 13px !important;

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0; }
  .ant-table-container {
    border: 1px solid #f0f0f0;
    border-radius: 8px; } }

.loadMoreButton {
  display: block;
  margin: 20px auto 0;
  background-color: transparent;
  color: $primary-color-subscriber;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  outline: none !important;
  &:hover {
    text-decoration: underline !important;
    transform: scale(1.05); } }


@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ant-table-row {
  animation: fadeIn 0.5s ease-in-out; }

.balanceSection {
  display: flex;
  flex-direction: column;
  padding-left: 20px; }
.balanceLabel {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500; }
.topUpSection {
  display: flex !important;
  justify-content: flex-end !important;
  flex-direction: column;
  padding-right: 5px; }


.topUpBtn {
  font-size: 13px;
  width: 50px !important;
  height: 40px;
  margin-top: 10px;
  outline: none !important; }

.btnTxt {
  font-size: 13px; }

.balanceAmountSection {
  margin-bottom: 0px !important; }
.balanceTxt {
  font-size: 20px;
  margin-top: -10px;
  font-weight: 500 !important;
  color: white !important;
  letter-spacing: 1px; }

.topUpTxtSection {
  font-size: 13px;
  text-align: right !important; }

.topUpTxt {
  font-size: 14px;
  text-align: center !important;
  margin-top: 2px;
  margin-bottom: 5px; }

.topUpIcon {
  width: 45px;
  path {
    fill: $primary-color-subscriber !important; } }

.loadMore {
  font-size: 14px;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline !important; } }

.dateData {
  color: rgba($general-text-color, 0.7); }

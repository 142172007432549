@import 'src/styles/variables';

.card {
  width: 100%;
  height: auto !important;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 8px !important;
  margin-bottom: 0.8rem !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer; } }

.cardfilter {
  width: 100%;
  height: auto !important;
  padding: 10px;
  border-radius: 8px !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important; }

.cardContainer {
  padding: 10px; }


.cardText {
  margin-left: 60px; }


.viewButton {
  width: 5rem;
  height: 5rem !important;
  background-color: #3A9CED !important;
  outline: none !important;
  border-style: solid !important;
  color: #000000 !important;
  border-radius: 8px !important; }

.deleteButton {
  width: 5rem;
  height: 5rem !important;
  background-color: $danger-color !important;
  outline: none !important;
  border-style: solid !important;
  border-radius: 8px !important; }

.searchButton {
  margin-top: -48px !important;
  margin-left: 280px !important;
  width: 6%;
  height: 35px !important;
  background-color: $primary-color !important;
  outline: none !important;
  border-style: solid !important; }

.markallButton {
  background-color: $primary-color !important; }

.search {
  margin-top: 24px !important;
  margin-left: -40px !important; }

.searchField {
  height: 30px;
  box-shadow: none !important;
  background-color: rgba(36, 46, 66, 0.1) !important;
  border: 0.5px solid rgba(248, 249, 255, 0.95) !important;
  border-radius: 8px !important;
  margin-left: 42px;
  outline: none !important;
  padding-left: 10px !important;
  &:focus {
    border: 1px solid #046dd6 !important; } }

.form-control + .form-control {
  margin-top: 1em; }

.tab {
  width: fit-content; }

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none; }
.paginationItem {
  margin-right: 4px;
  &.active > .paginationLink {
    background-color: $primary-color;
    color: white; }
  &.disable {
    opacity: 0.7;
    & > .paginationLink {
      cursor: default; } } }
.paginationLink {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: $grey-med-dark;
  text-decoration: none !important;
  font-weight: 300;
  &:hover {
    color: $general-text-color; } }
.itemNext > a, .itemPrev > a {
  font-weight: bold !important; }


.customCheckStyle {
  appearance: none;
  margin-top: 0.2rem;
  font: inherit;
  color: currentColor;
  min-width: 1em !important;
  height: 1em !important;
  border: 0.1px solid $general-text-color !important;
  border-radius: 0.19em !important;
  transform: translateY(-0.075em);

  &::before {
    content: "";
    width: 0.8em !important;
    height: 0.8em !important;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color;
    border: none;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); }

  &:checked::before {
    transform: scale(1); } }

.sendIcon {
  cursor: pointer;
  margin-left: 45px;
  width: 30px;
  height: 30px;
  &:hover {
    opacity: 1;
    path {
      stroke: $primary-color; } } }

.editIcon {
  cursor: pointer;
  margin-left: 5px;
  width: 22px;
  height: 22px;
  &:hover {
    opacity: 1;
    path {
      stroke: $primary-color; } } }

.trashIcon {
  cursor: pointer;
  margin-left: 28px;
  width: 24px;
  height: 24px;
  &:hover {
    opacity: 1;
    path {
      stroke: red; } } }

.eyeIcon {
  cursor: pointer;
  margin-left: 24px;
  width: 35px;
  height: 30px;
  fill: rgba($general-text-color, 0.45);
  &:hover {
    opacity: 1; } }

.dateTime {
  max-width: 10rem !important;
  min-width: 16rem !important;
  margin-left: 1rem !important;
  margin-top: 0.5rem !important;
  margin-right: -2rem !important; }

@media (max-width: 1100px) {
  .dateTime {
    min-width: 0px !important; } }

.iconOne {
  max-width: 4rem !important;
  margin-top: 0.5rem !important;
  line-height: 1.4 !important; }

.iconTwo {
  max-width: 5rem !important;
  align-content: center !important;
  margin-right: 1rem !important; }

.text1 {
  font-size: 15px !important;
  padding-top: 1rem !important;
  line-height: 1.4  !important;
  margin-left: 0px !important;
  color: rgba($general-text-color, 0.9) !important; }

.text2 {
  font-size: 13px !important;
  margin-left: 0px !important;
  color: rgba($general-text-color, 0.7) !important; }

.checkOuter {
  max-width: 3rem !important;
  margin-top: 0.3rem !important; }

.readButton1 {
  text-align: right !important;
  margin-top: 3.5rem !important; }

.table {
  display: block;
  max-height: calc(100vh - 210px) !important;
  &-active {
    margin-top: 15px; } }
.form {
  width: 100%;
  padding: 15px 20px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  min-height: 100px; }
.title {
  color: #212529 !important;
  font-weight: 400 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem !important;
  margin-left: 9px;
  text-align: left; }

.btnFilter {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }
.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &-eye {
    width: 27px;
    height: auto;
    fill: rgba($general-text-color, 0.45); }
  &-details {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.5px solid rgba($general-text-color, 0.6); }
  &-success {
    background: $green; }
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.1); } }
.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  &-secondary {
    background: rgba($general-text-color, 0.45); }
  &-refresh {
    min-width: unset;
    width: fit-content;
    font-size: 16px; } }
.actions {
  & > div {
    & > span {
      transition: display 0.75s;
      padding: 5px 10px;
      border-radius: 15px; }
    & > span:first-child {
      border-right: 1px solid rgba($general-text-color, 0.6); } } }
.background {
  &-primary {
    background: $primary-color; }
  &-secondary {
    background: rgba($general-text-color, 0.7); } }
.subSection {
  background: rgba($general-text-color, 0.1);
  border-radius: 8px;
  padding: 20px 0;
  width: 100%; }
.form {
  width: 100%;
  padding-right: 20px; }
.fieldset {
  padding: 0 40px 0 0 !important;
  &-dateStroke {
    width: 15px;
    border: 1.5px solid rgba($general-text-color, 0.65);
    top: 20px;
    left: -20px; }
  &-dateClear {
    top: 15px;
    right: 27px;
    color: $primary-color;
    cursor: pointer; } }

.btnMessage {
  margin-left: 15px;
  min-width: 43px;
  font-size: 16px;
  background-color: transparent !important;
  border-radius: 8px; }

.tableContainer {
  padding-top: 0.5rem !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important; }

.scheduleName {
  font-size: 14px !important;
  color: red !important;
  opacity: 0.7; }

@media  ( max-width: 991px ) {
  .rightColumn {
    margin-top: 40px !important; } }

@media  ( max-width: 1330px ) {
  .fieldset {
    &-dateClear {
      display: none !important; } } }

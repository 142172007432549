@import 'src/styles/variables';

.table {
  display: block;
  max-height: calc(100vh - 210px) !important; }
.tableActive {
  margin-top: 15px; }
.dangerColor {
  color: $danger-color-deep; }

.form {
  width: 100%;
  padding: 0 10px !important;
  & > label:first-child {
    color: blue !important;
    & div {
      margin-left: 30px; } } }
.formInput {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important; }
.formLabel {
  & div {
    margin-left: 20px; } }
.filters {
  background: rgba($general-text-color, 0.1);
  border-radius: 10px;
  width: 100% !important; }

.filters1 {
  background: rgba($general-text-color, 0.1);
  border-radius: 10px;
  width: 100% !important; }


.btnFilter {
  min-width: 43px;
  font-size: 16px;
  background-color: $grey-dark;
  border-radius: 8px;
  color: $default-button-txt; }
.activeTab {
  background: rgba($general-text-color, 0.1);
  color: rgba($general-text-color, 0.6); }
.activeIcon {
  & line {
    stroke: $general-text-color; }
  & path {
    stroke: $general-text-color; } }

.toggle {
  width: 43px;
  height: 23px;
  background-color: rgba($general-text-color, 0.6);
  border-radius: 20px;
  & > div {
    left: calc(21px - 100%);
    & > span:first-child {
      &::after {
        width: 17px;
        height: 17px;
        background-color: white; } } } }
.toggleOn {
  background: $primary-color;
  & > div {
    left: 0; } }
.toggleHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: calc(23px - 100%); } }
.toggleOnHighlight {
  background-color: rgba($general-text-color, 0.5);
  & > div {
    left: -2px; } }
.actions {
  & > div {
    & > span {
      transition: display 0.75s;
      padding: 5px 10px;
      border-radius: 15px; }
    & > span:first-child {
      border-right: 1px solid rgba($general-text-color, 0.6); } } }

.btn {
  font-size: 14px;
  padding: 20px;
  border-radius: 8px !important;
  color: $default-button-txt;
  background: $primary-btn-color; }
.background {
  &-primary {
    background: $primary-color !important; }
  &-orange {
    background: $orange !important; }
  &-secondary {
    background: rgba($general-text-color, 0.45); } }

.btnSettings {
  background-color: $grey-dark;
  min-width: 43px;
  min-height: 40px;
  font-size: 16px;
  border-radius: 10px; }
.eyeIcon {
  margin-left: 8px;
  width: 27px;
  height: auto;
  fill: $primary-color; }

.descContainer {
  width: 90%;
  padding: 15px;
  margin: 20px;
  margin-left: 8%;
  position: absolute;
  border-radius: 8px !important;
  border: 1px solid #6c757d; }

.background {
  &-danger {
    background: $red; }
  &-secondary {
    background: rgba($general-text-color, 0.7); } }

.button {
  border-radius: 8px;
  min-width: 40px;
  min-height: 30px;
  height: 40px;
  font-size: 15px;
  margin-left: 15px;
  font-weight: bolder;
  color: $default-button-txt;
  background: $primary-btn-color;
  &-sm {
    min-width: 120px; }
  &-bg-secondary {
    background: $cancel-button-bg;
    opacity: 0.6; } }

.slidebtn {
  margin-right: 20px !important; }

.title {
  color: $page-header-txt; }

@import 'src/styles/variables';

.contentContainer {
  font-family: 'Roboto', sans-serif;
  top: 30px;
  position: relative;
  margin-left: 20px !important;
  margin-right: 20px !important;
  flex: 1;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: white; }


.drawer {
  background-color: white !important; }

.menu {
  border: none;
  padding-top: 50px !important;
  margin: 0;
  width: 100% !important;
  background-color: white !important; }

.menuItem {
  font-size: 16px;
  color: #242E42 !important;
  text-decoration-line: none !important;
  height: 48px !important;
  &:hover {
    color: $primary-color-subscriber !important; }
  &::selection {
    background-color: red !important; } }


.menuButton {
  background-color: $primary-btn-color-subscriber !important;
  border: none !important;
  position: absolute !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  top: 75px !important;
  z-index: 1 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  outline: none !important;
  transition: opacity 0.1s ease !important;
  color: #ffffff;
  &:hover {
    background-color: $primary-color-subscriber !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    transform: scale(1.1) !important; }

  &::before {
    content: 'Menu';
    position: absolute;
    top: 2px;
    left: 200%;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: $primary-color-subscriber;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease; }
  &:hover::before {
    opacity: 1; } }

.closeBtn {
  top: 75px !important;
  left: 13.5rem !important;
  width: 2rem !important;
  border-radius: 15px !important;
  position: absolute !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  z-index: 2 !important;
  transition: transform 0.3s ease !important;
  outline: none !important;
  color: $primary-btn-color-subscriber !important;
  &:hover {
    transform: scale(1.1) !important; } }

.closeBtn2 {
  top: 75px !important;
  left: 17.2rem !important;
  width: 2rem !important;
  border-radius: 15px !important;
  position: absolute !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  z-index: 2 !important;
  transition: transform 0.3s ease !important;
  outline: none !important;
  color: $primary-btn-color-subscriber !important;
  &:hover {
    transform: scale(1.1) !important; } }

.anchor {
  text-decoration: none !important;
  transition: all 0.01s ease !important;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 5px; }

.homeIcon {
  font-size: 16px !important; }

.linkTxt {
  margin-top: 1px; }

.vehicleIcon {
  height: 22px;
  margin-left: -2px; }
.vehicleMenuTxt {
  margin-top: 1px;
  margin-left: 2px; }

.clipBoardIcon {
  height: 20px;
  margin-left: 1px; }
.clipBoardBarIcon {
  height: 11px;
  margin-left: 1px; }
.citationTxt {
  margin-left: 10px; }

.transactionTxt {
  margin-left: -4px; }

.homeTxt {
  margin-left: 11px !important; }

.eventTxt {
  margin-left: -4px; }
